import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CandidateType } from '@renovars/common/recruit';

@Component({
  selector: 'facile-candidati',
  template: `
    <facile-architect *ngIf="candidateType === CandidateType.ARCHITECT"></facile-architect>
    <facile-company *ngIf="candidateType === CandidateType.COMPANY"></facile-company>
    <facile-worker *ngIf="candidateType === CandidateType.WORKER"></facile-worker>
    <facile-to-skill *ngIf="candidateType === CandidateType.TO_SKILL"></facile-to-skill>
  `,
})
export class CandidatiComponent implements OnInit {
  candidateType: CandidateType;
  CandidateType = CandidateType;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.data.subscribe((res) => {
      this.candidateType = res.candidateType as CandidateType;
    });
  }
}
