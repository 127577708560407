import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { IStatus } from '@renovars/common';
import {
  AllStatusCodes,
  CandidateType,
  HRArchitectOKCodes,
  ICandidate,
  getStatusOKCodes,
} from '@renovars/common/recruit';
import { MessageService } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { CandidatesServices } from '../../../../core/services/candidates.services';
import { ModalCandidatiStatoComponent } from '../../components/modal-candidati-stato/modal-candidati-stato.component';

@Component({
  selector: 'facile-candidati-details-anag-info',
  template: `
    <div class="content">
      <div class="row">
        <div class="col-12">
          <form [formGroup]="form" novalidate>
            <div
              *ngIf="
                candidateType === CandidateType.WORKER ||
                candidateType === CandidateType.ARCHITECT ||
                candidateType === CandidateType.TO_SKILL
              "
            >
              <facile-candidati-anag-info-form
                formControlName="candidato"
                [candidateType]="candidateType"
                [withImage]="false"
                [withAttach]="false"
              >
              </facile-candidati-anag-info-form>

              <div class="row mt-4">
                <div class="col-sm-12">
                  <div class="alert alert-primary" *ngFor="let attach of candidate?.attachments">
                    <a href="{{ environment.recruitNestURL + '/storage/' + attach }}" target="_blank">
                      <span aria-hidden="true" class="p-button-icon pi pi-download"></span
                      ><span aria-hidden="true" class="p-button-label"></span> &nbsp;&nbsp;&nbsp;{{ attach }}
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="candidateType === CandidateType.COMPANY">
              <facile-candidati-impresa-anag-info-form
                formControlName="candidato"
                [withImage]="false"
                [withAttach]="false"
              >
              </facile-candidati-impresa-anag-info-form>

              <div class="row mt-4">
                <div class="col-sm-12">
                  <div class="alert alert-primary" *ngFor="let durc of candidate?.durc">
                    <a href="{{ environment.recruitNestURL + '/storage/' + durc }}" target="_blank">
                      <span aria-hidden="true" class="p-button-icon pi pi-download"></span
                      ><span aria-hidden="true" class="p-button-label"></span> &nbsp;&nbsp;&nbsp;Durc:
                      {{ durc }}
                    </a>
                  </div>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-sm-12">
                  <div class="alert alert-primary" *ngFor="let visura of candidate?.visura">
                    <a href="{{ environment.recruitNestURL + '/storage/' + visura }}" target="_blank">
                      <span aria-hidden="true" class="p-button-icon pi pi-download"></span
                      ><span aria-hidden="true" class="p-button-label"></span> &nbsp;&nbsp;&nbsp;Visura:
                      {{ visura }}
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-sm-12 text-end">
                <button
                  type="button"
                  class="btn btn-primary rounded me-4"
                  [routerLink]="['/candidati/' + candidateType]"
                >
                  Indietro
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  `,
})
export class CandidatiDetailsAnagInfoComponent implements OnInit {
  form = this.fb.group({
    candidato: new UntypedFormControl({ value: null, disabled: true }),
  });

  id: string = null;
  environment = environment;

  events$: Observable<IStatus[]>;
  StatusCodes = AllStatusCodes;
  candidate: ICandidate;
  candidateType: CandidateType;
  CandidateType = CandidateType;
  candidateTypeLabel: string;
  exporting: boolean = false;
  allStatesOk = getStatusOKCodes(null, null);
  allStatesKo = getStatusOKCodes(null, null);

  constructor(
    private fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private candidatesServices: CandidatesServices,
    private messageService: MessageService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (this.activatedRoute.parent) {
      this.activatedRoute.parent.data.subscribe((res) => {
        this.candidateType = res.candidateType as CandidateType;
        this.candidateTypeLabel = CandidateType.toLabel(this.candidateType);
      });
    }

    this.activatedRoute.paramMap.subscribe((params) => {
      this.id = params.get('id');
      if (this.id) {
        this.candidatesServices
          .getCandidate(this.id)
          .pipe(filter((candidate) => candidate !== null))
          .subscribe((candidate) => {
            this.candidate = candidate;
            this.form.patchValue({ candidato: candidate });
          });
      }
    });

    this.getCandidateHistory();
  }

  getCandidateHistory() {
    this.candidatesServices.getCandidateHistory(this.id).subscribe((res) => (this.events$ = of(res)));
  }

  getCandidate() {
    this.candidatesServices.getCandidate(this.id).subscribe((res) => (this.candidate = res));
  }

  changeCandidateState(code) {
    switch (code) {
      case HRArchitectOKCodes.HR_ASSEGNATO:
        this.modalChangeStatus(code);
        break;
      default:
        this.modalChangeStatus(code);
        break;
    }
  }

  updatedStatusCode(candidate: ICandidate, statusCode: typeof AllStatusCodes) {
    const { status } = candidate;
    const newStatus = {
      ...status,
      code: statusCode,
    };
    return newStatus;
  }
  modalChangeStatus(statusCode) {
    const dialogRef = this.dialog.open(ModalCandidatiStatoComponent, {
      minWidth: '600px',
      minHeight: '300px',
      data: {
        statusCode,
        candidato: this.candidate,
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.candidatesServices.changeStatusCandidate(res.candidato.id, res.status).subscribe();
      } else {
        this.getCandidateHistory();
        this.getCandidate();
      }
    });
  }
}
