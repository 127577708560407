import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';
import { setFieldsProperties, SiteService } from '@renovars/core-ng';
import { environment } from '../../../../../environments/environment';
import { CandidateType, CandidateTypeSector } from '@renovars/common/recruit';
import { RecruitSourceService } from 'apps/recruit-ng/src/app/core/services/recruit-source.service';
import { BehaviorSubject } from 'rxjs';
import type { ISite } from '@renovars/common';
/**
 * form d'inserimento candidato completo di tutti i campi e di tipo impresa
 */
@Component({
  selector: 'facile-candidati-impresa-all-info-form',
  template: `
    <form [formGroup]="form" novalidate>
      <fullstack-contact-form
        [contactType]="'company'"
        formControlName="contact"
        [formInfoState]="form.get('contact').errors"
        [sources]="sources"
        [options]="{
          showSources: false,
          showCompany: true,
          showBillingAddress: false,
          showPhoneType: true,
          showMailType: true
        }"
      >
      </fullstack-contact-form>
      <div class="row">
        <div class="col-12">
          <header class="page-header">
            <h2 class="tx-title-2">Dettagli candidatura</h2>
          </header>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-sm-4" *ngIf="candidateType !== CandidateType.ARCHITECT">
          <fullstack-input-select-box
            [options]="{ label: 'Settore lavorativo' }"
            formControlName="sector"
            [error]="_formInfoState?.sector?.error"
          >
            <sb-option [value]="i" *ngFor="let i of CandidateTypeSector[candidateType]">{{ i }}</sb-option>
          </fullstack-input-select-box>
        </div>
        <div class="col-sm-8">
          <fullstack-input-select-box
            [options]="{ label: 'Sede candidatura *' }"
            formControlName="addressOp"
            [isAddress]="true"
            [error]="_formInfoState?.addressOp?.error"
          >
            <sb-option [value]="i.address" *ngFor="let i of sites$ | async">{{ i.name }}</sb-option>
          </fullstack-input-select-box>
        </div>
      </div>
      <div class="row mt-4" *ngIf="withImage === true && withAttach === true">
        <div class="col-sm-4" *ngIf="withImage === true">
          <fullstack-file-upload
            [options]="{ label: 'Logo' }"
            formControlName="image"
            [uploadApiUrl]="environment.apiGatewayURL + '/recruit/media'"
            [storageUrl]="environment.recruitNestURL + '/storage'"
            [error]="_formInfoState?.image?.error"
          ></fullstack-file-upload>
        </div>
        <div class="col-sm-4">
          <fullstack-file-upload
            [options]="{ label: 'Durc *' }"
            [accept]="'application/pdf'"
            formControlName="durc"
            [multiple]="true"
            [error]="_formInfoState?.durc?.error"
            [uploadApiUrl]="environment.apiGatewayURL + '/recruit/media'"
            [storageUrl]="environment.recruitNestURL + '/storage'"
          ></fullstack-file-upload>
        </div>
        <div class="col-sm-4">
          <fullstack-file-upload
            [options]="{ label: 'Visura' }"
            [accept]="'application/pdf'"
            formControlName="visura"
            [error]="_formInfoState?.visura?.error"
            [multiple]="true"
            [uploadApiUrl]="environment.apiGatewayURL + '/recruit/media'"
            [storageUrl]="environment.recruitNestURL + '/storage'"
          ></fullstack-file-upload>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-sm-12">
          <fullstack-input-textarea
            [options]="{ label: 'Note', rows: 5 }"
            formControlName="note"
          ></fullstack-input-textarea>
        </div>
      </div>
    </form>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CandidatiImpresaAllInfoFormComponent),
      multi: true,
    },
  ],
})
export class CandidatiImpresaAllInfoFormComponent implements ControlValueAccessor, OnInit {
  @Input() set formInfoState(formInfoState) {
    this._formInfoState = formInfoState;
    setFieldsProperties(this._formInfoState, this.form);
  }

  get formInfoState() {
    return this._formInfoState;
  }

  @Input() withAttach = true;
  @Input() withImage = true;
  @Input() user = null;
  @Input() candidateType: CandidateType;

  CandidateType = CandidateType;
  CandidateTypeSector = CandidateTypeSector;
  environment = environment;
  _formInfoState: ValidationErrors = [];
  sources: string[];
  sites$ = new BehaviorSubject<ISite[]>(null);
  form = this.fb.group({
    contact: new UntypedFormControl(null),
    type: new UntypedFormControl(null),
    sector: new UntypedFormControl(null),
    addressOp: new UntypedFormControl(null),
    durc: new UntypedFormControl(null),
    image: new UntypedFormControl(null),
    visura: new UntypedFormControl(null),
    note: new UntypedFormControl(null),
  });

  propagateChange: any = () => {};

  constructor(
    private fb: UntypedFormBuilder,
    private sourceService: RecruitSourceService,
    private siteService: SiteService
  ) {}

  ngOnInit(): void {
    this.form.valueChanges.subscribe((val) => {
      this.propagateChange({ ...val, type: this.candidateType });
    });
    this.sourceService.getSourcesAll$().subscribe((s) => (this.sources = s));
    this.siteService.findAll().subscribe((sl) => this.sites$.next(sl));
  }

  resetFormValue() {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).setValue(null);
    });
  }

  /* Metodi per ReactiveForm */
  writeValue(value: any) {
    if (value) {
      this.form.patchValue(value);
    } else {
      this.resetFormValue();
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  setDisabledState(isDisabled: boolean) {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).disable();
    });
  }

  registerOnTouched(fn: () => void): void {}
}
