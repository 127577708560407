import { Pipe, PipeTransform } from '@angular/core';
import { IStatus } from '@renovars/common';
import { ArchitectCodes, ICandidate, StatusCodesLabel, getStatusCodes } from '@renovars/common/recruit';

@Pipe({
  name: 'status',
})
export class StatusPipe implements PipeTransform {
  transform(value: ICandidate): string {
    const allStates = getStatusCodes(null, null);
    return allStates.find((s) => s.code === value?.status?.code)?.label || '-';
  }
}
@Pipe({
  name: 'statusLabel',
})
export class StatusLabelPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';
    return StatusCodesLabel[value];
  }
}
@Pipe({
  name: 'typeLabel',
})
export class TypeLabelPipe implements PipeTransform {
  transform(value: 'worker' | 'architect' | 'company'): string {
    if (!value) return '';
    if (value === 'architect') return 'Architetto';
    if (value === 'company') return 'Impresa';
    if (value === 'worker') return 'Operaio';
  }
}
@Pipe({
  name: 'recallDate',
})
export class RecallDatePipe implements PipeTransform {
  transform(value: IStatus): string {
    const roleCheck =
      value?.code === ArchitectCodes.HR_DA_RICHIAMARE ||
      value?.code === ArchitectCodes.SM_DA_RICHIAMARE ||
      value.code === ArchitectCodes.HS_PRE_OK_FOLLOWUP;
    if (value?.data?.date && roleCheck) {
      return value.data.date;
    } else {
      return null;
    }
  }
}
