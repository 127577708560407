<h2 mat-dialog-title>{{ candidato?.contact?.firstName }} {{ candidato?.contact?.lastName }}</h2>
<div [hidden]="!(statusCode === statuses.HR_ASSEGNATO)">
  <fullstack-radio-button #type [(ngModel)]="choice">
    <item [label]="'Associa store manager'" [value]="'assegnazione'"></item>
    <item
      [label]="'Assegna appuntamento'"
      [value]="'appuntamento'"
      *ngIf="candidato.type !== CandidateType.COMPANY"
    ></item>
  </fullstack-radio-button>
</div>

<div *ngIf="type.value == 'assegnazione'">
  <facile-modal-candidati-stato-direct
    [statusCode]="statusCode"
    [candidato]="candidato"
    (closeModal)="close($event)"
  ></facile-modal-candidati-stato-direct>
</div>

<div *ngIf="type.value == 'appuntamento'">
  <facile-modal-candidati-stato-appointment
    [candidato]="candidato"
    (closeModal)="close($event)"
  ></facile-modal-candidati-stato-appointment>
</div>
