import { AdminArchitectCodes, HRArchitectCodes, HSArchitectCodes } from '../../enums';

export interface IStatisticCandidateTotal {
  architect: IStatisticCandidate;
  worker: IStatisticCandidate;
  company: IStatisticCandidate;
  totals: IStatisticCandidate;
}
export interface IStatisticCandidate {
  candidatesAssigned: number;
  candidatesToWork: number;
  candidatesInProgress: number;
  candidatesOk: number;
  candidatesKoStoreManager: number;
  candidatesKoHr: number;
}

export interface ISmStatistic {
  toWork: number;
  koCv: number;
  appointment: number;
  appointmentOK: number;
  appointmentKO: number;
  partenershipSet: number;
  partenershipKO: number;
  doc: number;
  docKO: number;
  operative: number;
  active: number;
}
export interface INamedStatistic extends ISmStatistic {
  name: string;
  role: string;
  total?: number;
  operator: string;
}

export interface IBacklogStatistic {
  interview: number;
  koByHr: number;
  wip: number;
  toWork: number;
}

export type GroupSelector = 'appointment' | 'partnershipSet' | 'toWork';
export type SmQueryParams = {
  start: Date;
  end: Date;
  type: string;
  id?: string;
  groupSelector?: GroupSelector;
};
export const SpecialistKey = [
  HRArchitectCodes.HR_CONTACT_DUPLICATED,
  HRArchitectCodes.HR_INTERESSATO_PERCORSO_FORMATIVO,
  HSArchitectCodes.HS_PRE_OK_FOLLOWUP,
  HSArchitectCodes.HS_PRE_OK_DA_RICHIAMARE,
  HSArchitectCodes.HS_PRE_OK_SOLLECITO_MAIL,
  HSArchitectCodes.HS_PRE_KO_LAVORO,
  HSArchitectCodes.HS_PRE_KO_TRASFERIMENTO,
  HSArchitectCodes.HS_PRE_KO_NON_A_TARGET,
  HSArchitectCodes.HS_OK_PRIMO_COLLOQUIO,
  HSArchitectCodes.HS_OK_SECONDO_COLLOQUIO,
  HSArchitectCodes.HS_POST_KO_NON_PRESENTATO,
  HSArchitectCodes.HS_POST_OK_DA_ASSEGNARE,
  HSArchitectCodes.HS_POST_KO_NON_INTERESSATO,
  HSArchitectCodes.HS_POST_KO_NON_A_TARGET,
  HSArchitectCodes.HS_POST_OK_AFFIANCAMENTO,
  AdminArchitectCodes.ADMIN_ASSEGNATO,
] as const;

export type SpecialistStatistic = {
  count: number;
  status: typeof SpecialistKey[number];
}[];
