import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CandidatiComponent } from './components/candidati.component';
import { CandidatiUpsertComponent } from './upsert/components/candidati-upsert.component';
import { CandidatiDetailsComponent } from './details/components/candidati-details.component';
import { CandidatiMainInfoComponent } from './upsert/components/candidati-main-info.component';
import { CandidatiProfileInfoComponent } from './upsert/components/candidati-profile-info.component';
import { DetailCandidateResolver } from './resolvers/candidati.resolver';
import { CandidatiDetailsMainInfoComponent } from './details/components/candidati-details-main-info.component';
import { CandidatiDetailsProfileInfoComponent } from './details/components/candidati-details-profile-info.component';
import { CandidatiAnagInfoComponent } from './upsert/components/candidati-anag-info.component';
import { CandidatiDetailsAnagInfoComponent } from './details/components/candidati-details-anag-info.component';
import { CandidatiAllInfoComponent } from './upsert/components/candidati-all-info.component';

const candidatiRoutes: Routes = [
  {
    path: '',
    component: CandidatiComponent,
  },
  {
    path: 'upsert',
    component: CandidatiUpsertComponent,
    children: [
      {
        path: 'all-info',
        component: CandidatiAllInfoComponent,
      },
      {
        path: 'main-info/:id',
        component: CandidatiMainInfoComponent,
      },
      {
        path: 'anag-info/:id',
        component: CandidatiAnagInfoComponent,
      },
      {
        path: 'profile-info/:id',
        component: CandidatiProfileInfoComponent,
      },
    ],
  },
  {
    path: 'details',
    component: CandidatiDetailsComponent,
    children: [
      {
        path: 'main-info/:id',
        component: CandidatiDetailsMainInfoComponent,
      },
      {
        path: 'anag-info/:id',
        component: CandidatiDetailsAnagInfoComponent,
      },
      {
        path: 'profile-info/:id',
        component: CandidatiDetailsProfileInfoComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(candidatiRoutes)],
  exports: [RouterModule],
  providers: [DetailCandidateResolver],
})
export class CandidatiRoutingModule { }
