<h2 mat-dialog-title>Riassegna SM</h2>
<div mat-dialog-content>
  <div class="row mt-2">
    <fullstack-input-select-box
      [(ngModel)]="selectedSm"
      [options]="{ label: 'Sm' }"
      (ngModelChange)="onSelect($event)"
    >
      <sb-option [value]="i" *ngFor="let i of storeManagers$ | async">{{
        i.firstName + ' ' + i.lastName
      }}</sb-option>
    </fullstack-input-select-box>
  </div>
  <div class="text-end mt-2">
    <button type="button" class="btn btn-default rounded m-1" (click)="dialogRef.close()">Annulla</button>
    <button type="submit" class="btn btn-secondary rounded m-1" (click)="save()">Sposta</button>
  </div>
</div>
