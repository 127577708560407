import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { IStatus } from '@renovars/common';
import {
  AdminArchitectCodes,
  AllStatusCodes,
  CandidateType,
  getStatusCodes,
  getStatusKOCodes,
  getStatusOKCodes,
  HRArchitectCodes,
  HRArchitectOKCodes,
  HRCompanyOKCodes,
  ICandidate,
  isStoreManager,
  RecruitRoles,
  SMArchitectCodes,
  SMArchitectOKCodes,
  SMCompanyCodes,
} from '@renovars/common/recruit';
import { AuthServices } from '@renovars/core-ng';
import { MessageService } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { CandidatesServices } from '../../../../core/services/candidates.services';
import { ModalCandidatiStatoComponent } from '../../components/modal-candidati-stato/modal-candidati-stato.component';
import { ModalFrActivationComponent } from '../../components/modal-candidati-stato/modal-fr-activation.component';
import { ModalTrainingComponent } from '../../components/modal-candidati-stato/modal-training.component';
import { IFileServerModel } from '@renovars/core-nest';
import { ModalFrSeniorActivationComponent } from '../../components/modal-candidati-stato/modal-fr-senior-activation.component';

@Component({
  selector: 'facile-candidati-details-main-info',
  template: `
    <div class="content">
      <div class="row">
        <div class="col-5 text-center" *ngIf="candidate?.status?.code !== StatusCodes.SM_DA_VERIFICARE">
          <small> <b>Cambia Stato:&nbsp;</b> {{ candidate | status }} </small>
          <button mat-button [matMenuTriggerFor]="menu"><i class="fas fa-edit"></i></button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item [matMenuTriggerFor]="hr" *ngIf="!isSm">Recruiter</button>
            <button mat-menu-item [matMenuTriggerFor]="hr" *ngIf="!isSpecialist">Recruiter Specialist</button>
            <button mat-menu-item [matMenuTriggerFor]="sm">Store Manager</button>
          </mat-menu>
          <mat-menu #hr="matMenu">
            <button mat-menu-item *ngFor="let state of hrStates" (click)="changeCandidateState(state.code)">
              {{ state.label }}
            </button>
            <button mat-menu-item [matMenuTriggerFor]="hrko">KO</button>
          </mat-menu>
          <mat-menu #sm="matMenu">
            <button mat-menu-item *ngFor="let state of smStates" (click)="changeCandidateState(state.code)">
              {{ state.label }}
            </button>
            <button mat-menu-item [matMenuTriggerFor]="smko">KO</button>
          </mat-menu>
          <mat-menu #hrko="matMenu">
            <button mat-menu-item *ngFor="let state of hrKO" (click)="changeCandidateState(state.code)">
              {{ state.label }}
            </button>
          </mat-menu>
          <mat-menu #smko="matMenu">
            <button mat-menu-item *ngFor="let state of smKO" (click)="changeCandidateState(state.code)">
              {{ state.label }}
            </button>
          </mat-menu>
        </div>
        <div class="col-7">
          <div class="alert alert-dark" *ngIf="candidate?.assigned?.firstName">
            Candidato assegnato a: {{ candidate?.assigned?.firstName }} {{ candidate?.assigned.lastName }}
          </div>
        </div>
      </div>

      <div class="alert alert-warning" *ngIf="candidate?.crmFrSubappaltatoreId">
        Candidato esportato in FR CRM
      </div>
      <div class="row">
        <div class="col-5 pt-5">
          <div class="mb-5" *ngIf="candidate?.image">
            <img
              src="{{ environment.recruitNestURL + '/storage/' + candidate?.image }}"
              class="img-fluid"
              style="max-height: 300px"
            />
          </div>
          <facile-candidati-details-timeline [value]="events$ | async"> </facile-candidati-details-timeline>
        </div>
        <div class="col-7">
          <form [formGroup]="form" novalidate>
            <div
              *ngIf="
                candidateType === CandidateType.WORKER ||
                candidateType === CandidateType.ARCHITECT ||
                candidateType === CandidateType.TO_SKILL
              "
            >
              <facile-candidati-main-info-form
                formControlName="candidato"
                [candidateType]="candidateType"
                [withImage]="false"
                [withAttach]="false"
              >
              </facile-candidati-main-info-form>

              <div class="row mt-4">
                <div class="col-sm-12">
                  <div class="alert alert-primary" *ngFor="let attach of candidate?.attachments">
                    <a href="{{ environment.recruitNestURL + '/storage/' + attach }}" target="_blank">
                      <span aria-hidden="true" class="p-button-icon pi pi-download"></span
                      ><span aria-hidden="true" class="p-button-label"></span> &nbsp;&nbsp;&nbsp;{{ attach }}
                    </a>
                  </div>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-sm-12" *ngIf="candidate?.crmFrData?.registration?.contract">
                  <fullstack-file-upload-new
                    [options]="{ label: 'Contratto' }"
                    [accept]="'application/pdf'"
                    [fileList]="attachmentFiles"
                    [storageUrl]="environment.recruitNestURL + '/file-server/download'"
                    [disabled]="true"
                  >
                    <ng-template file-row let-file>
                      <div class="d-flex align-items-center">
                        <div class="p-1 flex-fill">
                          <fullstack-input-box
                            [options]="{ label: 'Nome file' }"
                            [ngModel]="file.metadata.name || file.originalName"
                            (ngModelChange)="file.metadata.name = $event"
                            [ngModelOptions]="{ standalone: true }"
                            [disabled]="true"
                          ></fullstack-input-box>
                        </div>
                      </div>
                    </ng-template>
                  </fullstack-file-upload-new>
                </div>
              </div>
            </div>

            <div *ngIf="candidateType === CandidateType.COMPANY">
              <facile-candidati-impresa-main-info-form
                formControlName="candidato"
                [withImage]="false"
                [withAttach]="false"
              >
              </facile-candidati-impresa-main-info-form>

              <div class="row mt-4">
                <div class="col-sm-12">
                  <div class="alert alert-primary" *ngFor="let durc of candidate?.durc">
                    <a href="{{ environment.recruitNestURL + '/storage/' + durc }}" target="_blank">
                      <span aria-hidden="true" class="p-button-icon pi pi-download"></span
                      ><span aria-hidden="true" class="p-button-label"></span> &nbsp;&nbsp;&nbsp;Durc:
                      {{ durc }}
                    </a>
                  </div>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-sm-12">
                  <div class="alert alert-primary" *ngFor="let visura of candidate?.visura">
                    <a href="{{ environment.recruitNestURL + '/storage/' + visura }}" target="_blank">
                      <span aria-hidden="true" class="p-button-icon pi pi-download"></span
                      ><span aria-hidden="true" class="p-button-label"></span> &nbsp;&nbsp;&nbsp;Visura:
                      {{ visura }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <button
              (click)="saveCompanyToFr()"
              [disabled]="exporting"
              type="button"
              class="btn btn-secondary rounded me-4"
              *ngIf="
                candidate?.status?.code === StatusCodes.SM_OPERATIVO &&
                candidate?.type === CandidateType.COMPANY &&
                candidate?.contact?.businessName &&
                candidate?.contact?.vatCode &&
                !candidate.crmFrSubappaltatoreId
              "
            >
              Trasferisci su FR
            </button>

            <div class="row mt-4" *ngIf="candidateType === CandidateType.ARCHITECT">
              <div class="col-sm-12 text-end mb-2">
                <div *ngIf="candidate?.status?.code === StatusCodes.SM_DA_ATTIVARE_FR">
                  <button
                    (click)="saveToFr()"
                    type="button"
                    class="btn btn-secondary rounded me-1 mb-2"
                    *permissions="['is_admin']"
                  >
                    Trasferisci su FR
                  </button>
                </div>
                <h6 *ngIf="candidate?.status?.code === StatusCodes.SM_ATTIVATO_FR" class="me-4">
                  Creato su FR il {{ candidate?.status?.createdAt | date: 'short' }}
                </h6>
                <button
                  (click)="viewToFr()"
                  type="button"
                  class="btn btn-secondary rounded me-1 mb-2"
                  *ngIf="candidate?.status?.code === StatusCodes.SM_ATTIVATO_FR"
                >
                  Visualizza Dati FR
                </button>
                <button
                  type="button"
                  class="btn btn-primary rounded me-4"
                  [routerLink]="['/candidati/' + candidateType]"
                >
                  Indietro
                </button>
              </div>
            </div>
            <div class="row mt-4" *ngIf="candidateType === CandidateType.TO_SKILL">
              <div class="col-sm-12 text-end">
                <div *ngIf="candidate?.status?.code === StatusCodes.HS_POST_OK_AFFIANCAMENTO">
                  <button
                    (click)="openModalSeniorActivation()"
                    type="button"
                    class="btn btn-secondary rounded me-1 mb-2"
                    *permissions="['is_admin']"
                  >
                    Trasferisci su FR
                  </button>
                </div>
                <button
                  (click)="openViewModalSenior()"
                  type="button"
                  class="btn btn-secondary rounded me-1 mb-2"
                  *ngIf="candidate?.status?.code === StatusCodes.ADMIN_SENIOR_ATTIVATO"
                >
                  Visualizza Dati FR
                </button>
                <button
                  type="button"
                  class="btn btn-primary rounded me-4"
                  [routerLink]="['/candidati/' + candidateType]"
                >
                  Indietro
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  `,
})
export class CandidatiDetailsMainInfoComponent implements OnInit {
  form = this.fb.group({
    candidato: new UntypedFormControl({ value: null, disabled: true }),
  });

  id: string = null;
  environment = environment;
  attachmentFiles: IFileServerModel[] = [];
  events$: Observable<IStatus[]>;
  StatusCodes = AllStatusCodes;
  candidate: ICandidate;
  candidateType: CandidateType;
  CandidateType = CandidateType;
  candidateTypeLabel: string;
  exporting = false;
  hrStates = [];
  hrKO = [];
  hsStates = [];
  hsKO = [];
  smStates = [];
  smKO = [];
  states = getStatusCodes(null, null);
  isSm = false;
  isSpecialist = false;

  constructor(
    private fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private candidatesServices: CandidatesServices,
    private messageService: MessageService,
    private dialog: MatDialog,
    private authService: AuthServices
  ) {}

  ngOnInit(): void {
    if (this.activatedRoute.parent) {
      this.activatedRoute.parent.data.subscribe((res) => {
        this.candidateType = res.candidateType as CandidateType;
        this.candidateTypeLabel = CandidateType.toLabel(this.candidateType);
        if (this.candidateType === CandidateType.ARCHITECT) {
          this.states = getStatusCodes(RecruitRoles.ADMIN, CandidateType.ARCHITECT);
        } else {
          this.states = getStatusCodes(RecruitRoles.ADMIN, CandidateType.COMPANY);
        }
      });
    }

    this.activatedRoute.paramMap.subscribe((params) => {
      this.id = params.get('id');
      if (this.id) {
        this.getCandidate();
        this.getCandidateHistory();
      }
    });
    this.authService.user().subscribe((user) => {
      const { roles } = user;
      const filterStates = (item: { code: string; label: string }) => {
        const statuses = [
          AdminArchitectCodes.ADMIN_ASSEGNATO.toString(),
          HRArchitectCodes.HR_DA_CONFERMARE.toString(),
          HRArchitectCodes.HR_CONTACT_DUPLICATED.toString(),
          SMArchitectCodes.SM_ATTIVATO_FR.toString(),
          HRCompanyOKCodes.SM_PRIMO_COLLOQUIO_SM.toString(),
        ];
        if (roles.includes(RecruitRoles.ADMIN)) {
          statuses.push(SMArchitectOKCodes.SM_ATTIVATO_FR);
        }
        return !statuses.includes(item.code);
      };
      const ctxHrOK = getStatusOKCodes(RecruitRoles.HR_OFFICE, this.candidateType)
        .filter((item: { code: string; label: string }) => !item.code.includes('HS'))
        .filter((item: { code: string; label: string }) => !item.code.includes('SM'))
        .filter(filterStates);
      const ctxHrKO = getStatusKOCodes(RecruitRoles.HR_OFFICE, this.candidateType)
        .filter((item: { code: string; label: string }) => !item.code.includes('HS'))
        .filter((item: { code: string; label: string }) => !item.code.includes('SM'))
        .filter(filterStates);
      const ctxHsOK = getStatusOKCodes(RecruitRoles.HR_SPECIALIST, this.candidateType)
        .filter((item: { code: string; label: string }) => !item.code.includes('HR'))
        .filter((item: { code: string; label: string }) => !item.code.includes('SM'))
        .filter(filterStates);
      const ctxHsKO = getStatusKOCodes(RecruitRoles.HR_SPECIALIST, this.candidateType)
        .filter((item: { code: string; label: string }) => !item.code.includes('HR'))
        .filter((item: { code: string; label: string }) => !item.code.includes('SM'))
        .filter(filterStates);
      const ctxSmOK = getStatusOKCodes(RecruitRoles.STORE_MANAGER, this.candidateType).filter(filterStates);
      const ctxSmKO = getStatusKOCodes(RecruitRoles.STORE_MANAGER, this.candidateType).filter(filterStates);

      if (isStoreManager(roles)) {
        this.smStates = ctxSmOK;
        this.smKO = ctxSmKO;
        this.isSm = true;
      } else if (roles.includes(RecruitRoles.HR_SPECIALIST)) {
        if (this.candidateType === CandidateType.ARCHITECT) {
          this.hsStates = ctxHsOK;
          this.hsKO = ctxHsKO;
          this.isSpecialist = true;
        }
      } else if (roles.includes(RecruitRoles.HR_OFFICE)) {
        this.hrStates = ctxHrOK;
        this.hrKO = ctxHrKO;
        this.smStates = ctxSmOK;
        this.smKO = ctxSmKO;
      } else if (roles.includes(RecruitRoles.ADMIN)) {
        if (this.candidateType === CandidateType.ARCHITECT) {
          this.hsStates = ctxHsOK;
          this.hsKO = ctxHsKO;
        }
        this.hrStates = ctxHrOK;
        this.hrKO = ctxHrKO;
        this.smStates = ctxSmOK;
        this.smKO = ctxSmKO;
      }
    });
  }

  getCandidateHistory() {
    this.candidatesServices.getCandidateHistory(this.id).subscribe((res) => {
      this.events$ = of(res);
    });
  }

  getCandidate() {
    this.candidatesServices
      .getCandidate(this.id)
      .pipe(
        filter((candidate) => candidate !== null),
        mergeMap((candidate) => {
          if (candidate?.crmFrData?.registration?.contract) {
            return of(candidate).pipe(
              switchMap(() => {
                return this.candidatesServices.getContract(candidate.crmFrData.registration.contract);
              }),
              tap((contract) => {
                this.attachmentFiles = [contract];
              }),
              map(() => candidate)
            );
          } else {
            return of(candidate);
          }
        })
      )
      .subscribe((candidate) => {
        this.candidate = candidate;
        this.form.patchValue({ candidato: candidate });
      });
  }

  saveToFr() {
    this.handleStatusOperativoModal();
  }
  private handleStatusOperativoModal() {
    const dialogRef = this.dialog.open(ModalFrActivationComponent, {
      minWidth: '1000px',
      minHeight: '600px',
      data: {
        candidato: this.candidate,
        type: 'active_detail',
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.getCandidate();
        this.getCandidateHistory();
      }
    });
  }
  viewToFr() {
    this.dialog.open(ModalFrActivationComponent, {
      minWidth: '1000px',
      minHeight: '600px',
      data: {
        candidato: this.candidate,
        type: 'active_detail',
        disabled: true,
      },
    });
  }
  saveCompanyToFr() {
    this.exporting = true;
    const sub = this.candidatesServices.saveToFr(this.id).subscribe((res) => {
      sub.unsubscribe();
      if (res.status === 409) {
        this.messageService.add({
          severity: 'success',
          summary: 'Candidato',
          detail: 'Candidato già presente nel CRM di FR',
        });
      } else {
        this.getCandidateHistory();
        this.getCandidate();
      }
      this.exporting = false;
    });
  }
  changeCandidateState(code) {
    switch (code) {
      case HRArchitectOKCodes.HR_ASSEGNATO:
        this.modalChangeStatus(code);
        break;
      case SMArchitectOKCodes.SM_AFFIANCAMENTO_FISSATO:
        this.trainingModal();
        break;
      default:
        this.modalChangeStatus(code);
        break;
    }
  }

  updatedStatusCode(candidate: ICandidate, statusCode: typeof AllStatusCodes) {
    const { status } = candidate;
    const newStatus = {
      ...status,
      code: statusCode,
    };
    return newStatus;
  }
  modalChangeStatus(statusCode) {
    const dialogRef = this.dialog.open(ModalCandidatiStatoComponent, {
      minWidth: '600px',
      minHeight: '300px',
      data: {
        statusCode,
        candidato: this.candidate,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(
        switchMap((res) => {
          if (res) {
            return this.candidatesServices.changeStatusCandidate(res.candidato.id, res.status);
          } else {
            this.getCandidate();
            this.getCandidateHistory();
            return of(null);
          }
        })
      )
      .subscribe(() => {
        this.getCandidate();
        this.getCandidateHistory();
      });
  }
  trainingModal() {
    const dialogRef = this.dialog.open(ModalTrainingComponent, {
      minWidth: '600px',
      minHeight: '300px',
      data: {
        candidato: this.candidate,
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getCandidate();
      this.getCandidateHistory();
    });
  }
  openModalSeniorActivation() {
    const dialogRef = this.dialog.open(ModalFrSeniorActivationComponent, {
      minWidth: '600px',
      minHeight: '300px',
      data: {
        candidato: this.candidate,
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getCandidate();
      this.getCandidateHistory();
    });
  }
  openViewModalSenior() {
    const dialogRef = this.dialog.open(ModalFrSeniorActivationComponent, {
      minWidth: '600px',
      minHeight: '300px',
      data: {
        candidato: this.candidate,
        disabled: true,
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getCandidate();
      this.getCandidateHistory();
    });
  }
}
