<div class="p-2 row">
  <h2 class="tx-title-2">Candidato</h2>
</div>
<div class="row">
  <div class="col">
    <h2 class="tx-title-2">Nome</h2>
  </div>
  <div class="col">
    <p>{{ candidate.cache.contact | firstOrBusiness }}</p>
  </div>
  <div class="col">
    <h2 class="tx-title-2">Cognome</h2>
  </div>
  <div class="col">
    <p>{{ candidate.cache.contact | lastName }}</p>
  </div>
</div>
<div class="row">
  <div class="col">
    <h2 class="tx-title-2">Indirizzo Operativo</h2>
  </div>
  <div class="col">
    <p>{{ candidate.addressOp?.formatted_address }}</p>
  </div>
  <div class="col">
    <h2 class="tx-title-2">Indirizzo</h2>
  </div>
  <div class="col">
    <p>{{ candidate.cache?.contact?.address?.formatted_address }}</p>
  </div>
</div>
<div class="row">
  <div class="col">
    <h2 class="tx-title-2">Email</h2>
  </div>
  <div class="col">
    <p>{{ candidate?.cache?.contact?.emails && candidate?.cache?.contact?.emails[0]?.email }}</p>
  </div>
  <div class="col">
    <h2 class="tx-title-2">Telefono</h2>
  </div>
  <div class="col">
    <p>{{ candidate?.cache?.contact?.phones && candidate?.cache?.contact?.phones[0]?.phoneNumber }}</p>
  </div>
</div>
