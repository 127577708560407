/* eslint-disable prefer-const */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FrLineGraph, IObjectList, IPaginateResponse, IStatus, IUser } from '@renovars/common';
import {
  AdminArchitectCodes,
  AllStatusCodes,
  ArchitectCodes,
  CandidateType,
  CompanyCodes,
  getStatusKOCodes,
  getStatusOKCodes,
  HRArchitectCodes,
  HRCompanyOKCodes,
  ICandidate,
  ICandidateProfile,
  IFRSearchResult,
  IRecruitEvent,
  isStoreManager,
  RecruitRoles,
  SMArchitectCodes,
  StatusCodesLabel,
  TurnoverWithCalculations,
  WorkerCodes,
} from '@renovars/common/recruit';
import * as _ from 'lodash';
import { Observable, of, zip } from 'rxjs';
import { catchError, filter, map, mergeMap, reduce, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { MenuItem, MessageService } from 'primeng/api';
import type { IFilter } from '@renovars/mongoose-nest';
import { FilesService, UtilsService } from '@renovars/core-ng';

@Injectable()
export class CandidatesServices {
  constructor(
    private http: HttpClient,
    private utilsService: UtilsService,
    private messageService: MessageService,
    private fileService: FilesService
  ) {}

  getCandidates(filters: any): Observable<{ candidate: ICandidate[]; total: number }> {
    const u = filters ? '?filter=' + encodeURIComponent(JSON.stringify(filters)) : '';
    return this.http.get<IObjectList>(`${environment.apiGatewayURL}/recruit/candidates${u}`).pipe(
      map((res) => {
        if (res.data.length > 0) {
          return {
            candidate: res.data,
            total: res.total,
          };
        } else {
          return {
            candidate: [],
            total: 0,
          };
        }
      })
    );
  }

  getCandidate(id): Observable<ICandidate> {
    return this.http.get<ICandidate>(`${environment.apiGatewayURL}/recruit/candidates/${id}`);
  }

  saveCandidate(candidate: ICandidate, note: string = null): Observable<ICandidate> {
    let body: any = { ...candidate };
    if (note) {
      body = {
        ...candidate,
        statusNote: note,
      };
    }
    return this.http.post<ICandidate>(`${environment.apiGatewayURL}/recruit/candidates`, body).pipe(
      tap(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Candidato creato con successo',
        });
      }),
      catchError((err) => {
        this.messageService.add({
          severity: 'error',
          summary: err?.error?.message,
        });
        return of(err);
      })
    );
  }

  updateCandidate(id: string, candidate: ICandidate): Observable<ICandidate> {
    return this.http.put<ICandidate>(`${environment.apiGatewayURL}/recruit/candidates/${id}`, candidate).pipe(
      tap(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Candidato Aggiornato',
        });
      }),
      catchError((err) => {
        this.messageService.add({
          severity: 'error',
          summary: err?.error?.message,
        });
        return of(err);
      })
    );
  }

  updateCandidateProfile(id: string, profile: ICandidateProfile): Observable<ICandidate> {
    return this.http
      .patch<ICandidate>(`${environment.apiGatewayURL}/recruit/candidates/${id}/profile`, profile)
      .pipe(
        tap(() => {
          this.messageService.add({
            severity: 'success',
            summary: 'Profilo Aggiornato',
          });
        }),
        catchError((err) => {
          this.messageService.add({
            severity: 'error',
            summary: err?.error?.message,
          });
          return of(err);
        })
      );
  }

  deleteCandidate(id: string): Observable<void> {
    return this.http.delete<void>(`${environment.apiGatewayURL}/recruit/candidates/${id}`).pipe(
      tap(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Candidato rimosso con successo',
        });
      }),
      catchError((err) => {
        this.messageService.add({
          severity: 'error',
          summary: err?.error?.message,
        });
        return of(err);
      })
    );
  }

  changeStatusCandidate(id: string, status: IStatus): Observable<IStatus> {
    return this.http.patch<IStatus>(`${environment.apiGatewayURL}/recruit/candidates/${id}/status`, status);
  }

  getCandidateHistory(id: string): Observable<IStatus[]> {
    return this.http
      .get<IObjectList>(`${environment.apiGatewayURL}/recruit/candidates/${id}/history`)
      .pipe(map((res) => res.data));
  }

  getStoreManagersAvailable(id: string): Observable<IUser[]> {
    return this.http
      .get<IObjectList>(`${environment.apiGatewayURL}/recruit/candidates/${id}/store-manager/assignable`)
      .pipe(map((res) => res.data));
  }

  setCandidateStoreManager(candidateId: string, storeManagerId): Observable<void> {
    return this.http.patch<void>(
      `${environment.apiGatewayURL}/recruit/candidates/${candidateId}/assigned/${storeManagerId}`,
      null
    );
  }
  setCandidateHr(candidateId: string, hrId): Observable<void> {
    return this.http.patch<void>(
      `${environment.apiGatewayURL}/recruit/candidates/${candidateId}/assignedHR/${hrId}`,
      null
    );
  }

  getCmdItemsForList(
    candidateType: CandidateType,
    roles: string[],
    callBack: (props: { action: string; data?: any }) => any
  ): MenuItem[] {
    const cmItems: MenuItem[] = [
      {
        id: 'modify',
        label: 'Modifica',
        icon: 'pi pi-fw pi-pencil',
        command: () => callBack({ action: 'gotoUpdate' }),
      },
      {
        id: 'detail',
        label: 'Dettaglio',
        icon: 'pi pi-fw pi-search',
        command: () => callBack({ action: 'gotoDetail' }),
      },
      {
        id: 'fr_activation',
        label: 'Richiedi attivazione',
        icon: 'pi pi-fw pi-reply',
        visible: false,
        command: () => callBack({ action: 'requestActivation' }),
      },
    ];

    if (!roles.includes(RecruitRoles.SHOW_ROOM_MANAGER)) {
      const changeStatusItem = {
        id: 'status',
        label: 'Cambia stato in:',
        icon: 'pi pi-fw pi-save',
        items: this.cmdListFactory(candidateType, callBack, roles),
      };
      cmItems.push(changeStatusItem);
    }

    const deleteItem = {
      label: 'Elimina',
      icon: 'pi pi-fw pi-times',
      command: () => callBack({ action: 'delete' }),
    };
    const assignItem = {
      label: 'Assegna SM/HR',
      icon: 'pi pi-fw pi-caret-right',
      command: () => callBack({ action: 'assign' }),
    };
    if (roles.includes(RecruitRoles.ADMIN)) {
      cmItems.push(deleteItem);
      cmItems.push(assignItem);
    }
    if (this.isAdminOrSpecialistAndArchitectOrAssistant(roles, candidateType) === CandidateType.ARCHITECT) {
      const moveCaniddate = {
        label: 'Sposta',
        icon: 'pi pi-fw pi-directions',
        items: [
          {
            label: 'Da architetto a assistente',
            icon: 'pi pi-fw pi-caret-left',
            command: () => callBack({ action: 'moveToAssistant' }),
          },
        ],
      };
      cmItems.push(moveCaniddate);
    }
    if (this.isAdminOrSpecialistAndArchitectOrAssistant(roles, candidateType) === CandidateType.TO_SKILL) {
      const moveCaniddate = {
        label: 'Sposta',
        icon: 'pi pi-fw pi-directions',
        items: [
          {
            label: 'Da assistente a architetto',
            icon: 'pi pi-fw pi-caret-left',
            command: () => callBack({ action: 'moveToArchitect' }),
          },
        ],
      };
      cmItems.push(moveCaniddate);
    }

    return cmItems;
  }
  private isAdminOrSpecialistAndArchitectOrAssistant(roles: string[], type: CandidateType) {
    if (roles.includes(RecruitRoles.ADMIN) || roles.includes(RecruitRoles.HR_SPECIALIST)) {
      return type === CandidateType.ARCHITECT
        ? CandidateType.ARCHITECT
        : type === CandidateType.TO_SKILL
        ? CandidateType.TO_SKILL
        : null;
    } else {
      return null;
    }
  }

  cmdListFactory(
    candidateType: CandidateType,
    callBack: (props: { action: string; data?: any }) => any,
    roles: string[] = []
  ) {
    return this.getCmdStatusAdminForList(candidateType, callBack, roles);
  }

  getCmdStatusAdminForList(
    candidateType: CandidateType,
    callBack: (props: { action: string; data?: any }) => any,
    roles: string[] = []
  ): MenuItem[] {
    const mapTo = (item: { code: string; label: string }) => ({
      label: item.label,
      command: () => callBack({ action: 'modalChangeStatus', data: { statusCode: item.code } }),
    });
    const filterStates = (item: { code: string; label: string }) => {
      const statuses = [
        AdminArchitectCodes.ADMIN_ASSEGNATO.toString(),
        HRArchitectCodes.HR_DA_CONFERMARE.toString(),
        HRArchitectCodes.HR_CONTACT_DUPLICATED.toString(),
        SMArchitectCodes.SM_ATTIVATO_FR.toString(),
      ];
      if (!roles.includes(RecruitRoles.ADMIN)) {
        statuses.push(AdminArchitectCodes.ADMIN_SENIOR_ATTIVATO);
      }
      return !statuses.includes(item.code);
    };
    let ctxMenu: MenuItem[] = [];
    const ctxHrOK: MenuItem[] = getStatusOKCodes(RecruitRoles.HR_OFFICE, candidateType)
      .filter((item: { code: string; label: string }) => !item.code.includes('HS'))
      .filter((item: { code: string; label: string }) => !item.code.includes('SM'))
      .filter(filterStates)
      .map(mapTo);
    const ctxHrKO: MenuItem[] = getStatusKOCodes(RecruitRoles.HR_OFFICE, candidateType)
      .filter((item: { code: string; label: string }) => !item.code.includes('HS'))
      .filter((item: { code: string; label: string }) => !item.code.includes('SM'))
      .filter(filterStates)
      .map(mapTo);
    const ctxHsOK: MenuItem[] = getStatusOKCodes(RecruitRoles.HR_SPECIALIST, candidateType)
      .filter((item: { code: string; label: string }) => !item.code.includes('HR'))
      .filter((item: { code: string; label: string }) => !item.code.includes('SM'))
      .filter(filterStates)
      .map(mapTo);
    const ctxHsKO: MenuItem[] = getStatusKOCodes(RecruitRoles.HR_SPECIALIST, candidateType)
      .filter((item: { code: string; label: string }) => !item.code.includes('HR'))
      .filter((item: { code: string; label: string }) => !item.code.includes('SM'))
      .filter(filterStates)
      .map(mapTo);
    const ctxSmOK: MenuItem[] = getStatusOKCodes(RecruitRoles.STORE_MANAGER, candidateType)
      .filter(filterStates)
      .map(mapTo);
    const ctxSmKO: MenuItem[] = getStatusKOCodes(RecruitRoles.STORE_MANAGER, candidateType)
      .filter(filterStates)
      .map(mapTo);
    if (roles.includes(RecruitRoles.ADMIN)) {
      ctxMenu.push({
        label: 'Recruiter',
        items: [
          {
            label: StatusCodesLabel[AdminArchitectCodes.ADMIN_ASSEGNATO],
            command: () =>
              callBack({
                action: 'modalChangeStatus',
                data: { statusCode: AdminArchitectCodes.ADMIN_ASSEGNATO },
              }),
          },
          ...ctxHrOK,
          {
            label: 'KO',
            items: ctxHrKO,
          },
        ],
      });
      if (candidateType === CandidateType.TO_SKILL) {
        ctxMenu = ctxMenu.filter((item) => item.label !== 'Recruiter');
        ctxMenu.push({
          label: 'Recruiter specialist',
          items: [
            ...ctxHsOK,
            {
              label: 'KO',
              items: ctxHsKO,
            },
          ],
        });
      }
      ctxMenu.push({
        label: 'Store manager',
        items: [
          ...ctxSmOK,
          {
            label: 'KO',
            items: ctxSmKO,
          },
        ],
      });
    } else if (roles.includes(RecruitRoles.HR_SPECIALIST) && candidateType === CandidateType.TO_SKILL) {
      ctxMenu.push({
        label: 'Recruiter specialist',
        items: [
          ...ctxHsOK,
          {
            label: 'KO',
            items: ctxHsKO,
          },
        ],
      });
    } else if (roles.includes(RecruitRoles.HR_OFFICE)) {
      ctxMenu.push({
        label: 'Recruiter',
        items: [
          ...ctxHrOK,
          {
            label: StatusCodesLabel[HRCompanyOKCodes.SM_PRIMO_COLLOQUIO_SM],
            command: () =>
              callBack({
                action: 'modalChangeStatus',
                data: { statusCode: HRCompanyOKCodes.SM_PRIMO_COLLOQUIO_SM },
              }),
          },
          {
            label: 'KO',
            items: ctxHrKO,
          },
        ],
      });
      ctxMenu.push({
        label: 'Store manager',
        items: [
          ...ctxSmOK,
          {
            label: 'KO',
            items: ctxSmKO,
          },
        ],
      });
    } else if (isStoreManager(roles)) {
      ctxMenu.push({
        label: 'Store manager',
        items: [
          ...ctxSmOK,
          {
            label: 'KO',
            items: ctxSmKO,
          },
        ],
      });
    }
    return ctxMenu;
  }

  createAppointment(obj: IRecruitEvent): Observable<any> {
    return this.http.post<any>(`${environment.apiGatewayURL}/recruit/events`, obj);
  }

  search(filter: IFilter, hideSpinner = false): Observable<IPaginateResponse<ICandidate>> {
    filter = _.cloneDeep(filter);
    this.utilsService.setRegexCriteria(filter.where, 'data.phones', filter.where['data.phones']);
    this.utilsService.setRegexCriteria(
      filter.where,
      'addressOp.locality',
      filter.where['addressOp.locality']
    );
    this.utilsService.setRegexCriteria(
      filter.where,
      'addressOp.province',
      filter.where['addressOp.province']
    );

    return this.http.post<IPaginateResponse<ICandidate>>(
      `${environment.apiGatewayURL}/recruit/candidates/search?extension=true&hideSpinner=${hideSpinner}`,
      filter
    );
  }

  getStatusAvailable(candidateType: CandidateType) {
    switch (candidateType) {
      case CandidateType.ARCHITECT:
        return { ...ArchitectCodes };
      case CandidateType.COMPANY:
        return { ...CompanyCodes };
      case CandidateType.WORKER:
        return { ...WorkerCodes };
      default:
        return AllStatusCodes;
    }
  }

  saveToFr(id): Observable<any> {
    return this.http.get<any>(`${environment.apiGatewayURL}/recruit/candidates/${id}/save-to-fr`);
  }
  setHr(candidateId: string, hrId: string) {
    return this.http.post(`${environment.apiGatewayURL}/recruit/candidates/assign-hr`, { candidateId, hrId });
  }
  downloadCandidateByDirector() {
    return this.http.get(`${environment.apiGatewayURL}/recruit/job-scheduler`, {
      responseType: 'arraybuffer',
    });
  }

  createFrArchitect(candidate: ICandidate) {
    return this.http.post(`${environment.apiGatewayURL}/recruit/external/architect`, candidate);
  }
  createFrArchitectWithSenior(candidate: ICandidate) {
    return this.http.post(`${environment.apiGatewayURL}/recruit/external/architect/senior`, candidate);
  }
  savePartialFrArchitectWithSenior(candidate: ICandidate) {
    return this.http.post(
      `${environment.apiGatewayURL}/recruit/external/architect/senior/partial`,
      candidate
    );
  }
  savePartialFrArchitect(candidate: ICandidate) {
    if (_.isEmpty(candidate?.crmFrData?.registration?.frAddress)) {
      this.messageService.add({
        severity: 'error',
        summary: 'Indirizzo FR necessario',
      });
      return;
    }
    if (_.isEmpty(candidate?.crmFrData?.registration?.parent)) {
      this.messageService.add({
        severity: 'error',
        summary: 'Superiore necessario',
      });
      return;
    }
    if (_.isEmpty(candidate?.crmFrData?.registration?.shop)) {
      this.messageService.add({
        severity: 'error',
        summary: 'Shop necessario',
      });
      return;
    }
    return this.http.post(`${environment.apiGatewayURL}/recruit/external/architect/partial`, candidate);
  }
  getFrSM$() {
    return this.http.get<IFRSearchResult[]>(`${environment.apiGatewayURL}/recruit/external/frStoreManager`);
  }
  getFrSeniorArchitect$(coords: [number, number]) {
    return this.http.post<Pick<IFRSearchResult, 'code' | 'email' | 'value'>[]>(
      `${environment.apiGatewayURL}/recruit/external/getFrSeniorArchitect`,
      { coords }
    );
  }

  checkValidAssistants$(assistant_id: string) {
    return this.http.post<IFRSearchResult[]>(
      `${environment.apiGatewayURL}/recruit/external/frCheckAssistants`,
      { id: assistant_id }
    );
  }
  getFrAm$() {
    return this.http.get<{ value: string; code: string; email: string; ancestors: string[] }[]>(
      `${environment.apiGatewayURL}/recruit/external/frAm`
    );
  }
  getFrDirector$() {
    return this.http.get<{ value: string; code: string; email: string; ancestors: string[] }[]>(
      `${environment.apiGatewayURL}/recruit/external/frDirector`
    );
  }
  getFrAnchestors$(ancestors: string[]) {
    return this.http.post<{ value: string; code: string; email: string; ancestors: string[] }[]>(
      `${environment.apiGatewayURL}/recruit/external/frStoreManager`,
      { ancestors }
    );
  }
  getFrChildrens$(childrens: string[]) {
    return this.http.post<
      { value: string; code: string; email: string; ancestors: string[]; isAvaible: boolean; busy: boolean }[]
    >(`${environment.apiGatewayURL}/recruit/external/frNetwork`, { childrens });
  }
  getFrUserById$(frId: string) {
    return this.http.get<
      { value: string; code: string; email: string; ancestors: string[]; isAvaible: boolean; busy: boolean }[]
    >(`${environment.apiGatewayURL}/recruit/external/get-by-id/${frId}`);
  }
  getFrShop$() {
    return this.http.get<{ value: string; code: string }[]>(
      `${environment.apiGatewayURL}/recruit/external/frShop`
    );
  }
  getFrLineChart(searchType: 'year' | 'month' | 'week', date: Date) {
    return this.http.post<FrLineGraph[]>(`${environment.apiGatewayURL}/recruit/external/line-chart`, {
      viewType: searchType,
      dateFilter: date,
    });
  }

  getFrTurnover(range: { start: Date; end: Date }, id: string) {
    return this.http.post<TurnoverWithCalculations[]>(
      `${environment.apiGatewayURL}/recruit/external/censimento`,
      {
        ...range,
        id,
      }
    );
  }
  getFrTurnoverImprese(range: { start: Date; end: Date }, id: string) {
    return this.http.post<TurnoverWithCalculations[]>(
      `${environment.apiGatewayURL}/recruit/external/censimento/imprese`,
      {
        ...range,
        id,
      }
    );
  }
  setFrArchitectState(frId: string, payload: { isAvaible?: boolean; busy?: boolean }) {
    return this.http.post(`${environment.apiGatewayURL}/recruit/external/update-status/${frId}`, payload);
  }
  addFile(files: FileList) {
    return this.fileService.uploadFiles(files).pipe(
      filter((res) => res.length > 0 && res[0].length > 0),
      map((matrix) => matrix.reduce((a, b) => a.concat(b), [])),
      mergeMap((ids) =>
        zip(
          ids.map((id, index) =>
            this.fileService
              .update(id, { name: files[index].name })
              .pipe(switchMap(() => this.fileService.searchById(id)))
          )
        )
      ),
      reduce((a, b) => a.concat(b), [])
    );
  }
  getContract(id: string) {
    return this.fileService.searchById(id);
  }
  architectToAssistant(id: string) {
    return this.http.put(`${environment.apiGatewayURL}/recruit/reassign/architectToAssistant/${id}`, {});
  }
  assistantToArchitect(id: string) {
    return this.http.put(`${environment.apiGatewayURL}/recruit/reassign/assistantToArchitect/${id}`, {});
  }
}
