import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';
import { CandidateTypeSector } from '@renovars/common/recruit';
import { setFieldsProperties } from '@renovars/core-ng';

@Component({
  selector: 'facile-candidati-impresa-profile-info-form',
  template: `
    <form [formGroup]="form" novalidate>
      <div class="row mt-3">
        <div class="col-12">
          <div class="row">
            <div class="col-8">Si ricorda la data di scadenza del DURC? (valido 4 mesi)</div>
            <div class="col-2">
              <p-radioButton [value]="true" formControlName="validDurc" label="Si"></p-radioButton>
            </div>
            <div class="col-2">
              <p-radioButton [value]="false" formControlName="validDurc" label="No"></p-radioButton>
            </div>
          </div>
        </div>
        <div class="col-12">
          <fullstack-datepicker
            [options]="{ label: 'Data di scadenza DURC' }"
            formControlName="expireDurcDate"
          ></fullstack-datepicker>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-8">Si avvale di un consulente esterno per la documentazione della sicurezza?</div>
            <div class="col-2">
              <p-radioButton
                [value]="true"
                formControlName="externalSecurityConsultant"
                label="Si"
              ></p-radioButton>
            </div>
            <div class="col-2">
              <p-radioButton
                [value]="false"
                formControlName="externalSecurityConsultant"
                label="No"
              ></p-radioButton>
            </div>
          </div>
        </div>
        <div class="col-12">
          <fullstack-input-select-box [options]="{ label: 'Settore lavorativo' }" formControlName="sector">
            <sb-option [value]="i" *ngFor="let i of sectors">{{ i }}</sb-option>
          </fullstack-input-select-box>
        </div>
        <div
          class="col-12"
          *ngIf="form.controls.sector.value === 'Elettrico' || form.controls.sector.value === 'Idraulico'"
        >
          <div class="row">
            <div class="col-8">Siete abilitati alla certificazione degli impianti?</div>
            <div class="col-2">
              <p-radioButton
                [value]="true"
                formControlName="installationCertification"
                label="Si"
              ></p-radioButton>
            </div>
            <div class="col-2">
              <p-radioButton
                [value]="false"
                formControlName="installationCertification"
                label="No"
              ></p-radioButton>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-8">Siete in possesso del DVR (Documento di valutazione rischi) ?</div>
            <div class="col-2">
              <p-radioButton [value]="true" formControlName="dvr" label="Si"></p-radioButton>
            </div>
            <div class="col-2">
              <p-radioButton [value]="false" formControlName="dvr" label="No"></p-radioButton>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-8">Avete un RSPP incaricato?</div>
            <div class="col-2">
              <p-radioButton [value]="true" formControlName="rspp" label="Si"></p-radioButton>
            </div>
            <div class="col-2">
              <p-radioButton [value]="false" formControlName="rspp" label="No"></p-radioButton>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-8">
              Avete gli attestati per la sicurezza e le idoneità sanitarie in corso di validità?
            </div>
            <div class="col-2">
              <p-radioButton [value]="true" formControlName="trainingMedicalExams" label="Si"></p-radioButton>
            </div>
            <div class="col-2">
              <p-radioButton
                [value]="false"
                formControlName="trainingMedicalExams"
                label="No"
              ></p-radioButton>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-8">
              Siete in possesso di una Assicurazione per danni a terzi in corso di validità?
            </div>
            <div class="col-2">
              <p-radioButton [value]="true" formControlName="thirdPartyInsurance" label="Si"></p-radioButton>
            </div>
            <div class="col-2">
              <p-radioButton [value]="false" formControlName="thirdPartyInsurance" label="No"></p-radioButton>
            </div>
          </div>
        </div>
        <div class="col-12">
          <fullstack-input-box
            [options]="{ label: 'Qual è il numero dei vostri addetti che operano nella vostra impresa?' }"
            [type]="'number'"
            formControlName="employeesNumber"
          ></fullstack-input-box>
        </div>
        <div class="col-12">
          <fullstack-input-textarea
            [options]="{ label: 'Note Profilo', rows: 5 }"
            formControlName="profileNote"
          ></fullstack-input-textarea>
        </div>
      </div>
    </form>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CandidatiImpresaProfileInfoFormComponent),
      multi: true,
    },
  ],
  styles: ['.col-12 { margin: 10px 0 10px 0 }'],
})
export class CandidatiImpresaProfileInfoFormComponent implements ControlValueAccessor, OnInit {
  @Input() set formInfoState(formInfoState) {
    this._formInfoState = formInfoState;
    setFieldsProperties(this._formInfoState, this.form);
  }

  get formInfoState() {
    return this._formInfoState;
  }

  _formInfoState: ValidationErrors = [];
  sectors = CandidateTypeSector.imprese;
  form = this.fb.group({
    validDurc: new UntypedFormControl(null),
    expireDurcDate: new UntypedFormControl(null),
    externalSecurityConsultant: new UntypedFormControl(null),
    installationCertification: new UntypedFormControl(null),
    dvr: new UntypedFormControl(null),
    noiseAssessment: new UntypedFormControl(null),
    rspp: new UntypedFormControl(null),
    competentDoctor: new UntypedFormControl(null),
    trainingMedicalExams: new UntypedFormControl(null),
    thirdPartyInsurance: new UntypedFormControl(null),
    workerHighRisk: new UntypedFormControl(null),
    firstAid: new UntypedFormControl(null),
    employeesNumber: new UntypedFormControl(null),
    profileNote: new UntypedFormControl(null),
    sector: new UntypedFormControl(null),
  });

  propagateChange: any = () => {};

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.form.valueChanges.subscribe((val) => {
      this.propagateChange(val);
    });
  }

  resetFormValue() {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).setValue(null);
    });
  }

  /* Metodi per ReactiveForm */
  writeValue(value: any) {
    if (value) {
      this.form.patchValue(value);
    } else {
      this.resetFormValue();
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  setDisabledState(isDisabled: boolean) {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).disable();
    });
  }

  registerOnTouched(fn: () => void): void {}
}
