import { Pipe, PipeTransform } from '@angular/core';
import { IContactCompany, IContactIndividual, isCompany, isIndividual } from '@renovars/common';

@Pipe({ name: 'fullName' })
export class FullNamePipe implements PipeTransform {
  transform(value: any) {
    if (value?.fullName) return value.fullName as string;
    if (value?.firstName && value?.lastName) return `${value.firstName} ${value.lastName}`;
    if (value?.email) return value.email as string;
    return '-';
  }
}
@Pipe({ name: 'firstOrBusiness' })
export class FirstOrBussinesNamePipe implements PipeTransform {
  transform(value: IContactCompany | IContactIndividual) {
    if (isCompany(value)) {
      return value.businessName;
    }
    if (isIndividual(value)) {
      return value.firstName;
    }
    return '';
  }
}
@Pipe({ name: 'lastName' })
export class LastNamePipe implements PipeTransform {
  transform(value: IContactCompany | IContactIndividual) {
    if (isCompany(value)) {
      return value.businessName;
    }
    if (isIndividual(value)) {
      return value.lastName;
    }
    return '';
  }
}
