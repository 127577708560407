import { registerLocaleData } from '@angular/common'; // without this line it didn't work
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import locale from '@angular/common/locales/it';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RecruitRoles, RecruitRolesLabels } from '@renovars/common/recruit';
import {
  AppConfigModule,
  DomainValuesModule,
  FileManagerModule,
  NetworkModule,
  ProfileModule,
  UtentiModule,
} from '@renovars/core-ng';
import { SpinnerInterceptor } from '@renovars/ui-ng';
import * as moment from 'moment';
import 'moment/locale/it';
import { MessageService } from 'primeng/api';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SetHeaderInterceptor } from './core/interceptor/set-header.interceptor';
import { LayoutModule } from './core/layout/layout.module';
import { FrViewModule } from './features/fr-view/fr-view.module';
import { UtentiService } from './features/utenti/utenti.service';

registerLocaleData(locale);
moment.locale('it');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LayoutModule,
    AppRoutingModule,
    FileManagerModule.forRoot({
      endpoint: `${environment.apiGatewayURL}/recruit`,
      roles: RecruitRoles,
    }),
    UtentiModule.forRoot({
      service: new UtentiService(),
      userMetaEndpoint: `${environment.apiGatewayURL}/recruit/user-meta`,
      excludeField: {
        acceptStar: true,
        site: true,
        bc: true,
      },
    }),
    NetworkModule.forRoot({
      networkURL: `${environment.apiGatewayURL}/recruit/network`,
      labels: RecruitRolesLabels,
    }),
    ProfileModule.forRoot({
      apiGatewayURL: environment.apiGatewayURL,
      applicationId: environment.applicationId,
    }),
    DomainValuesModule.forRoot({
      apiApplicationURL: `${environment.apiGatewayURL}/recruit`,
    }),
    AppConfigModule.forRoot({
      apiGatewayURL: environment.apiGatewayURL,
      applicationId: environment.applicationId,
      redirectUrl: environment.redirectUrl,
      apiProxiedUrl: environment.apiGatewayURL + '/recruit',
    }),
    FrViewModule,
  ],
  providers: [
    [
      { provide: HTTP_INTERCEPTORS, useClass: SetHeaderInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
    ],
    MessageService,
    { provide: LOCALE_ID, useValue: 'it-IT' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
