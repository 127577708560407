import { NgModule } from '@angular/core';
import { TemplateAppComponent } from './app/template-app.component';
import { UiModule } from '@renovars/ui-ng';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ServerEventService } from '../services/server-event.service';

@NgModule({
  imports: [RouterModule, CommonModule, UiModule],
  declarations: [TemplateAppComponent],
  exports: [],
  providers: [ServerEventService],
})
export class LayoutModule {}
