import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IUser, IUserMeta, mergeArrayCustomizer } from '@renovars/common';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

const ROUTE = 'recruit/user';
@Injectable()
export class UserService {
  constructor(private http: HttpClient) {}

  get(id): Observable<IUserMeta> {
    return this.http.get<any>(`${environment.apiGatewayURL}/${ROUTE}/${id}`);
  }

  save(obj: IUserMeta): Observable<any> {
    return this.http.post<any>(`${environment.apiGatewayURL}/${ROUTE}`, obj);
  }

  update(id: string, obj: IUserMeta): Observable<any> {
    return this.get(id).pipe(
      map((res) => {
        const payload: IUserMeta = _.cloneDeep(_.mergeWith({}, res, obj, mergeArrayCustomizer));
        return payload;
      }),
      switchMap((payload) => this.http.put<any>(`${environment.apiGatewayURL}/${ROUTE}/${id}`, payload))
    );
  }
  getUsersByRole(role: string): Observable<IUser[]> {
    return this.http.get<IUser[]>(`${environment.apiGatewayURL}/recruit/users-by-role/${role}`);
  }
}
