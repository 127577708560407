import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import type { IUser } from '@renovars/common';
import { IRecruitEvent, RecruitRoles } from '@renovars/common/recruit';
import { UsersService } from '@renovars/fe-core-libs/services/users.service';
import * as _ from 'lodash';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { ReassignEventService } from '../../reassign-event.service';
@Component({
  selector: 'facile-modal-reassign-appointment',
  templateUrl: './modal-reassign-appointment.component.html',
  styles: [],
})
export class ModalReassignAppointmentComponent implements OnInit {
  reassignForm: UntypedFormGroup;
  storeManagers$: Observable<IUser[]>;
  selectedSm: IUser;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IRecruitEvent,
    private reassignService: ReassignEventService,
    private messageService: MessageService,
    public dialogRef: MatDialogRef<ModalReassignAppointmentComponent>,
    private usersService: UsersService
  ) {}

  ngOnInit(): void {
    this.storeManagers$ = this.usersService.getUsersListDownline(
      [RecruitRoles.ADMIN, RecruitRoles.HR_OFFICE],
      [
        RecruitRoles.STORE_MANAGER,
        RecruitRoles.HR_OFFICE,
        RecruitRoles.CONSUMER,
        RecruitRoles.SM_VIP,
        RecruitRoles.WORKER_MANAGER,
      ]
    );
  }
  save() {
    const obs = {
      next: () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Evento',
          detail: 'Evento Riassegnato',
        });
        this.dialogRef.close();
      },
      error: (err) => {
        this.messageService.add({
          severity: 'warn',
          summary: 'Evento',
          detail: err?.error?.message,
        });
      },
    };
    this.reassignService
      .reassignEvent(
        this.data,
        this.selectedSm.id,
        this.selectedSm.firstName + ' ' + this.selectedSm.lastName
      )
      .subscribe(obs);
  }
  onSelect(user: IUser) {
    this.reassignService.checkUserBusy(this.data.start, user).subscribe();
  }
}
