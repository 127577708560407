import { Injectable } from '@angular/core';
import { concatAll, filter, map, Observable, reduce, shareReplay } from 'rxjs';
import { CandidatesServices } from '../../core/services/candidates.services';

@Injectable()
export class FrViewService {
  constructor(private candidateService: CandidatesServices) {}

  private getFRLineChart(searchType: 'year' | 'month' | 'week', date: Date) {
    return this.candidateService.getFrLineChart(searchType, date);
  }
  differenceGraph(searchType: 'year' | 'month' | 'week', date: Date) {
    return this.getFRLineChart(searchType, date).pipe(
      concatAll(),
      map((value) => ({
        in: value.count,
        out: value.numeroArchitettiInUscita,
        difference: value.count - value.numeroArchitettiInUscita,
        date: value.date,
      })),
      reduce((a, b) => [...a, b], [])
    );
  }
  monthlyGraph(date: Date) {
    return this.getFRLineChart('month', date);
  }
  getTurnover(range: { start: Date; end: Date }, id: string) {
    return this.candidateService.getFrTurnover(range, id).pipe(shareReplay(1));
  }
  getTurnoverImprese(range: { start: Date; end: Date }, id: string) {
    return this.candidateService.getFrTurnoverImprese(range, id).pipe(shareReplay(1));
  }
  getFrUsers(
    role: 'sm' | 'am' | 'dir' | 'children',
    childrens = null
  ): Observable<{ value: string; code: string; email: string; ancestors: string[] }[]> {
    switch (role) {
      case 'sm':
        return this.candidateService.getFrSM$().pipe(
          concatAll(),
          filter((e) => e.email.includes('ristrutturare')),
          reduce((a, b) => [...a, b], [])
        );
      case 'am':
        return this.candidateService.getFrAm$().pipe(
          concatAll(),
          filter((e) => e.email.includes('ristrutturare')),
          reduce((a, b) => [...a, b], [])
        );
      case 'dir':
        return this.candidateService.getFrDirector$().pipe(
          concatAll(),
          filter((e) => e.email.includes('ristrutturare')),
          reduce((a, b) => [...a, b], [])
        );
      case 'children':
        return this.candidateService.getFrChildrens$(childrens).pipe(
          concatAll(),
          filter((e) => e.email.includes('ristrutturare')),
          reduce((a, b) => [...a, b], [])
        );
      default:
        break;
    }
    return this.candidateService.getFrSM$().pipe(
      concatAll(),
      filter((e) => e.email.includes('ristrutturare')),
      reduce((a, b) => [...a, b], [])
    );
  }
}
