import { IUser } from '@renovars/common';
import { RecruitRoles, RecruitRolesLevels } from '@renovars/common/recruit';
import { IUtentiModuleService } from '@renovars/core-ng';

export class UtentiService implements IUtentiModuleService {
  calculateNetworkPriority(user: IUser, applicationId: string) {
    const reg = user.registrations.find((r) => r.applicationId == applicationId);

    const roles = reg.roles;

    let maxLevel = 0;
    roles.forEach((r) => {
      const level = RecruitRolesLevels.indexOf(<RecruitRoles>r);
      if (maxLevel < level) {
        maxLevel = level;
      }
    });

    return RecruitRolesLevels.slice(maxLevel + 1);
  }
}
