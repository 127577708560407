<fullstack-header *ngIf="!headerLess"></fullstack-header>

<ng-template *ngIf="!headerLess" header-body>
  <h1 class="tx-title-1">Appuntamenti</h1>
  <div class="text-end align-items-center">
    <button
      class="btn btn-header me-2"
      (click)="resetFilter()"
      pTooltip="Resetta Filtri"
      tooltipPosition="left"
    >
      <i class="fas fa-trash cl-medium fa-1x"></i>
    </button>
  </div>
</ng-template>
<facile-status-header
  [roles]="roles"
  [candidateType]="null"
  (onSelect)="onStatusSelect($event)"
></facile-status-header>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<fullstack-datatable
  [options]="{ lazy: true }"
  (lazyLoad)="onLazyLoad($event)"
  [list]="eventsList?.data"
  [totalRecords]="eventsList?.total"
  (selected)="selected = $event"
  [cmItems]="cmItems"
>
  <div t-header>
    <div t-column [width]="'250px'">
      <ng-template t-col-title>Nominativo</ng-template>
      <fullstack-input-select-box
        *t-filter
        [options]="{ autocomplete: true }"
        ngModel
        (ngModelChange)="paginateRequest.form.contactId = $event; search()"
        (inputChange)="searchContact($event)"
      >
        <sb-option [value]="i.id" *ngFor="let i of contacts$ | async">{{ i | contactName }}</sb-option>
      </fullstack-input-select-box>
    </div>
    <!-- <div t-column>
      <ng-template t-col-title>Data inserimento</ng-template>
      <fullstack-daterangepicker
        *t-filter
        (rangeChange)="paginateRequest.form.insertDateRange = $event; search()"
      >
      </fullstack-daterangepicker>
    </div> -->
    <div t-column width="100px">
      <ng-template t-col-title>Telefono</ng-template>
      <fullstack-input-box *t-filter (keyup.enter)="search()" [(ngModel)]="paginateRequest.form.phone">
      </fullstack-input-box>
    </div>
    <div t-column>
      <ng-template t-col-title>Email</ng-template>
      <fullstack-input-box *t-filter (keyup.enter)="search()" [(ngModel)]="paginateRequest.form.email">
      </fullstack-input-box>
    </div>
    <div t-column>
      <ng-template t-col-title>Indirizzo</ng-template>
      <fullstack-input-box *t-filter (keyup.enter)="search()" [(ngModel)]="paginateRequest.form.address">
      </fullstack-input-box>
    </div>
    <div t-column width="150px">
      <ng-template t-col-title>Data inizio</ng-template>
      <fullstack-daterangepicker
        *t-filter
        (rangeChange)="paginateRequest.form.eventStartRange = $event; search()"
      >
      </fullstack-daterangepicker>
    </div>
    <div t-column width="150px">
      <ng-template t-col-title>Data fine</ng-template>
      <fullstack-daterangepicker
        *t-filter
        (rangeChange)="paginateRequest.form.eventEndRange = $event; search()"
      >
      </fullstack-daterangepicker>
    </div>
    <div t-column width="150px">
      <ng-template t-col-title>Tipo</ng-template>
      <fullstack-input-select-box
        *t-filter
        [ngModel]="paginateRequest.form.eventType"
        (ngModelChange)="paginateRequest.form.eventType = $event; search()"
      >
        <sb-option [key]="'code'" [value]="i" *ngFor="let i of eventTypes">{{ i.label }}</sb-option>
      </fullstack-input-select-box>
    </div>
    <div t-column width="150px">
      <ng-template t-col-title>Tipo Candidato</ng-template>
      <fullstack-input-select-box *t-filter [(ngModel)]="selectedType" (ngModelChange)="handleTypeChange()">
        <sb-option [key]="'key'" [value]="elem" *ngFor="let elem of types">{{ elem.value }}</sb-option>
      </fullstack-input-select-box>
    </div>
    <div t-column width="150px">
      <ng-template t-col-title>Utente</ng-template>
      <fullstack-input-select-box
        *t-filter
        [ngModel]="paginateRequest.form.userId"
        (ngModelChange)="paginateRequest.form.userId = $event; search()"
      >
        <sb-option [key]="'id'" [value]="elem" *ngFor="let elem of usersList$ | async"
          >{{ elem?.firstName }} {{ elem?.lastName }}</sb-option
        >
      </fullstack-input-select-box>
    </div>
    <div t-column width="150px">
      <ng-template t-col-title>Stato</ng-template>
      <fullstack-input-select-box
        *t-filter
        [options]="{}"
        [(ngModel)]="paginateRequest.form['status.code']"
        (ngModelChange)="search()"
      >
        <sb-option [key]="'code'" [value]="i" *ngFor="let i of statusList">{{ i.label }}</sb-option>
      </fullstack-input-select-box>
    </div>
    <div t-column width="150px">
      <ng-template t-col-title>Creato Da</ng-template>
      <fullstack-input-select-box
        *t-filter
        [ngModel]="paginateRequest.form.insertUserId"
        (ngModelChange)="paginateRequest.form.insertUserId = $event; search()"
      >
        <sb-option [key]="'id'" [value]="elem" *ngFor="let elem of usersList$ | async"
          >{{ elem?.firstName }} {{ elem?.lastName }}</sb-option
        >
      </fullstack-input-select-box>
    </div>
    <div t-column width="150px">
      <ng-template t-col-title>Data Creazione</ng-template>
      <fullstack-daterangepicker
        *t-filter
        (rangeChange)="paginateRequest.form.insertDateRange = $event; search()"
      >
      </fullstack-daterangepicker>
    </div>
    <div t-column>
      <ng-template t-col-title>Nota dell' admin</ng-template>
      <fullstack-input-select-box
        *t-filter
        [ngModel]="paginateRequest.form.withAdminMsg"
        (ngModelChange)="paginateRequest.form.withAdminMsg = $event; search()"
      >
        <sb-option [value]="true">SI</sb-option>
        <sb-option [value]="false">No</sb-option>
      </fullstack-input-select-box>
    </div>
  </div>

  <ng-template t-body let-row>
    <td>{{ row?.cache?.contact | contactName }}</td>
    <!-- <td>{{ row?.createdAt | date: 'short' }}</td> -->
    <td>
      {{
        row?.cache?.contact?.phones && row?.cache?.contact.phones.length > 0
          ? row?.cache?.contact?.phones[0].phoneNumber
          : ''
      }}
    </td>
    <td>
      {{
        row?.cache?.contact?.emails && row?.cache?.contact?.emails.length > 0
          ? row?.cache?.contact?.emails[0].email
          : ''
      }}
    </td>
    <td>{{ row?.address?.formatted_address }}</td>
    <td>{{ row?.start | date: 'short' }}</td>
    <td>{{ row?.end | date: 'short' }}</td>
    <td>{{ row?.type | domainValue: 'event_type' | async }}</td>
    <td>{{ row?.cache?.candidate?.type }}</td>
    <td>{{ row?.user?.firstName }} {{ row?.user?.lastName }}</td>
    <td>{{ labels[row?.cache?.candidate?.status?.code] }}</td>
    <td>{{ row?.insertUserObj?.firstName }} {{ row?.insertUserObj?.lastName }}</td>
    <td>{{ row?.createdAt | date: 'short' }}</td>
    <td [pTooltip]="row?.adminMessage | inTarget | async">{{ row?.adminMessage | inTarget | async }}</td>
  </ng-template>
</fullstack-datatable>
