import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';
import { setFieldsProperties, SiteService } from '@renovars/core-ng';
import { environment } from '../../../../../environments/environment';
import { CandidateType, CandidateTypeSector } from '@renovars/common/recruit';
import { RecruitSourceService } from 'apps/recruit-ng/src/app/core/services/recruit-source.service';
import { BehaviorSubject } from 'rxjs';
import type { ISite } from '@renovars/common';
@Component({
  selector: 'facile-candidati-impresa-main-info-form',
  template: `
    <form [formGroup]="form" novalidate>
      <div class="row">
        <div class="col-12">
          <header class="page-header">
            <h2 class="tx-title-2">Dettagli candidatura</h2>
          </header>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-sm-6">
          <fullstack-input-box [options]="{ label: 'Campagna' }" formControlName="candidateCampaign">
          </fullstack-input-box>
        </div>
        <div
          class="col-sm-6"
          *ngIf="!form.get('trackingUrl').value || form.get('trackingUrl').value === 'self'; else notEditable"
        >
          <fullstack-input-select-box [options]="{ label: 'Fonte' }" formControlName="candidateSource">
            <sb-option [value]="i" *ngFor="let i of sources">{{ i }}</sb-option>
          </fullstack-input-select-box>
        </div>
        <ng-template #notEditable>
          <div class="col-sm-6">
            <fullstack-input-box [options]="{ label: 'Fonte' }" formControlName="candidateSource">
            </fullstack-input-box>
          </div>
        </ng-template>
        <div
          class="col-sm-12 mt-2"
          [pTooltip]="form.get('trackingUrl')?.value"
          [pTooltip]="form.get('trackingUrl')?.value"
        >
          <fullstack-input-box [options]="{ label: 'Tracking' }" formControlName="trackingUrl">
          </fullstack-input-box>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-sm-4" *ngIf="candidateType !== CandidateType.ARCHITECT">
          <fullstack-input-select-box [options]="{ label: 'Settore lavorativo' }" formControlName="sector">
            <sb-option [value]="i" *ngFor="let i of CandidateTypeSector[candidateType]">{{ i }}</sb-option>
          </fullstack-input-select-box>
        </div>
        <div class="col-sm-8">
          <fullstack-input-select-box
            [options]="{ label: 'Sede candidatura *' }"
            formControlName="addressOp"
            [isAddress]="true"
          >
            <sb-option [value]="i.address" *ngFor="let i of sites$ | async">{{ i.name }}</sb-option>
          </fullstack-input-select-box>
        </div>
      </div>
      <div class="row mt-4" *ngIf="withImage === true && withAttach === true">
        <div class="col-sm-4" *ngIf="withImage === true">
          <fullstack-file-upload
            [options]="{ label: 'Logo' }"
            formControlName="image"
            [uploadApiUrl]="environment.apiGatewayURL + '/recruit/media'"
            [storageUrl]="environment.recruitNestURL + '/storage'"
          ></fullstack-file-upload>
        </div>
        <div class="col-sm-4">
          <fullstack-file-upload
            [options]="{ label: 'Durc *' }"
            [accept]="'application/pdf'"
            formControlName="durc"
            [multiple]="true"
            [uploadApiUrl]="environment.apiGatewayURL + '/recruit/media'"
            [storageUrl]="environment.recruitNestURL + '/storage'"
          ></fullstack-file-upload>
        </div>
        <div class="col-sm-4">
          <fullstack-file-upload
            [options]="{ label: 'Visura' }"
            [accept]="'application/pdf'"
            formControlName="visura"
            [multiple]="true"
            [uploadApiUrl]="environment.apiGatewayURL + '/recruit/media'"
            [storageUrl]="environment.recruitNestURL + '/storage'"
          ></fullstack-file-upload>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-sm-12">
          <fullstack-input-textarea
            [options]="{ label: 'Note', rows: 5 }"
            formControlName="note"
          ></fullstack-input-textarea>
        </div>
      </div>
    </form>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CandidatiImpresaMainInfoFormComponent),
      multi: true,
    },
  ],
})
export class CandidatiImpresaMainInfoFormComponent implements ControlValueAccessor, OnInit {
  @Input() set formInfoState(formInfoState) {
    this._formInfoState = formInfoState;
    setFieldsProperties(this._formInfoState, this.form);
  }

  get formInfoState() {
    return this._formInfoState;
  }
  sources: string[];
  @Input() withAttach = true;
  @Input() withImage = true;
  @Input() user = null;
  @Input() candidateType: CandidateType;

  CandidateType = CandidateType;
  CandidateTypeSector = CandidateTypeSector;
  environment = environment;
  _formInfoState: ValidationErrors = [];
  sites$ = new BehaviorSubject<ISite[]>(null);
  form = this.fb.group({
    contact: new UntypedFormControl(null),
    type: new UntypedFormControl(null),
    sector: new UntypedFormControl(null),
    addressOp: new UntypedFormControl(null),
    durc: new UntypedFormControl(null),
    image: new UntypedFormControl(null),
    visura: new UntypedFormControl(null),
    note: new UntypedFormControl(null),
    candidateSource: new UntypedFormControl(null),
    candidateCampaign: new UntypedFormControl(null),
    trackingUrl: new UntypedFormControl(null),
  });

  propagateChange: any = () => {};

  constructor(
    private fb: UntypedFormBuilder,
    private sourceService: RecruitSourceService,
    private siteService: SiteService
  ) {}

  ngOnInit(): void {
    this.form.get('candidateCampaign').disable();
    this.form.get('trackingUrl').disable();
    this.form.valueChanges.subscribe((val) => {
      this.propagateChange({ ...val, type: this.candidateType });
    });
    this.sourceService.getSourcesAll$().subscribe((s) => (this.sources = s));
    this.siteService.findAll().subscribe((sl) => this.sites$.next(sl));
  }

  resetFormValue() {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).setValue(null);
    });
  }

  /* Metodi per ReactiveForm */
  writeValue(value: any) {
    if (value && value.trackingUrl && value.trackingUrl !== 'self') {
      this.form.get('candidateSource').disable();
    }
    if (value) {
      this.form.patchValue(value);
    } else {
      this.resetFormValue();
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  setDisabledState(isDisabled: boolean) {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).disable();
    });
  }

  registerOnTouched(fn: () => void): void {}
}
