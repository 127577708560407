import { Injectable, NgZone } from '@angular/core';
import { Observable, map, switchMap, tap } from 'rxjs';
import { AuthServices } from '@renovars/core-ng';
import { MessageService } from 'primeng/api';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { environment } from 'apps/recruit-ng/src/environments/environment';
import { ServerEventPayload } from '@renovars/common';

@Injectable()
export class ServerEventService {
  constructor(private _zone: NgZone, private auth: AuthServices, private message: MessageService) {}

  private connectClient(url) {
    return new Observable<string>((observer) => {
      const eventSource = new EventSource(url, {
        withCredentials: true,
      });
      eventSource.onmessage = (res) => {
        if (!res.data) return;
        this._zone.run(() => {
          observer.next(res.data);
        });
      };
      eventSource.onerror = (e) => {
        this._zone.run(() => {
          observer.error(e);
        });
      };
      return () => {
        eventSource.close();
      };
    });
  }

  subToServerEvents$() {
    return this.auth.user().pipe(
      switchMap((user) =>
        this.connectClient(`${environment.recruitNestURL}/api/v1/server-events/notify/sse?id=${user.sub}`)
      ),
      map((v) => JSON.parse(v)),
      tap((val: ServerEventPayload) => {
        if (val.kind === 'HTML') {
          this.message.add({
            severity: 'success',
            summary: val.data as string,
            sticky: true,
          });
        } else {
          this.message.add({
            severity: 'success',
            summary: val.data as string,
            sticky: true,
          });
        }
      })
    );
  }
}
