import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ICandidate, IRecruitEvent, RecruitEventType } from '@renovars/common/recruit';
import { EventService } from '@renovars/core-ng';
import dayjs from 'dayjs';
import { RecruitEventApiService } from '../../services/recruit-event-api.service';

@Component({
  selector: 'facile-modal-training',
  template: `
    <h2 mat-dialog-title>Nuovo evento affiancamento</h2>
    <mat-dialog-content class="mat-typography">
      <div class="row mb-2">
        <div class="col-12">
          <fullstack-input-box [options]="{ label: 'Descrizione' }" [(ngModel)]="note"></fullstack-input-box>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-12">
          <fullstack-datepicker [options]="{ label: 'Giorno' }" [(ngModel)]="date"> </fullstack-datepicker>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-6">
          <label for="">Dalle ore</label>
          <select class="form-select" [(ngModel)]="slot.start" (change)="checkStartRange()">
            <option *ngFor="let hour of slot.startHours; let i = index" [value]="hour | number">
              {{ hour }}
            </option>
          </select>
        </div>
        <div class="col-6">
          <label for="">Alle ore</label>
          <select class="form-select" [(ngModel)]="slot.end" (change)="checkEndRange()">
            <option *ngFor="let hour of slot.endHours; let i = index" [value]="hour | number">
              {{ hour }}
            </option>
          </select>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button class="me-2" mat-dialog-close>Annulla</button>
      <button class="btn btn-warning" (click)="save()" [disabled]="!date">Conferma</button>
    </mat-dialog-actions>
  `,
  styles: [],
})
export class ModalTrainingComponent implements OnInit {
  MIN_HOUR = 7;
  MAX_HOUR = 20;
  @Input() options: { maxHour: number; minHour: number } = { maxHour: this.MAX_HOUR, minHour: this.MIN_HOUR };

  slot: { endHours: number[]; start: number; startHours: number[]; end: number; day?: number };
  startHours = [];
  endHours = [];
  date: Date = null;
  note = '';

  event: IRecruitEvent;

  constructor(
    private recruitEventService: RecruitEventApiService,
    public dialogRef: MatDialogRef<ModalTrainingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { start: Date; candidato: ICandidate; eventId?: string }
  ) {}

  ngOnInit(): void {
    this.startHours = Array(this.options.maxHour - this.options.minHour)
      .fill(0)
      .map((_, i) => i + this.options.minHour);
    this.endHours = Array(this.options.maxHour - this.options.minHour + 1)
      .fill(0)
      .map((_, i) => i + this.options.minHour);
    if (this.data.start) {
      this.date = this.data.start;
      const startHour = dayjs(this.date).hour();
      if (startHour <= this.MAX_HOUR && startHour >= this.MIN_HOUR) {
        this.initSlot(startHour, startHour + 1);
      } else {
        this.initSlot(this.options.minHour, this.options.maxHour);
      }
    } else {
      this.initSlot(this.options.minHour, this.options.maxHour);
    }
  }

  initSlot(startHour: number, endHour: number) {
    this.slot = {
      day: 1,
      start: startHour,
      end: endHour,
      endHours: this.filterEndHours(startHour),
      startHours: this.filterStartHours(endHour),
    };
  }

  checkStartRange() {
    this.slot.endHours = this.filterEndHours(this.slot.start);
  }

  checkEndRange() {
    this.slot.startHours = this.filterStartHours(this.slot.end);
  }

  filterStartHours(endHour): number[] {
    return this.startHours.filter((hour) => hour < endHour);
  }

  filterEndHours(startHour): number[] {
    return this.endHours.filter((hour) => hour > startHour);
  }

  save() {
    this.event = {
      note: this.note,
      start: dayjs(this.date).hour(this.slot.start).minute(0).second(0).toDate(),
      end: dayjs(this.date).hour(this.slot.end).minute(0).second(0).toDate(),
      candidate: this.data.candidato._id,
      user:
        typeof this.data.candidato.assigned === 'string'
          ? this.data.candidato.assigned
          : this.data.candidato.assigned.id,
      type: RecruitEventType.AFFIANCAMENTO,
      contact:
        typeof this.data.candidato.contact === 'string'
          ? this.data.candidato.contact
          : this.data.candidato.contact._id,
      address: this.data.candidato.addressOp,
    };
    if (this.data.eventId) {
      this.recruitEventService.updateTraining(this.data.eventId, this.event).subscribe((_) => {
        this.dialogRef.close(true);
      });
    } else {
      this.recruitEventService.createTraining(this.event).subscribe((_) => {
        this.dialogRef.close(true);
      });
    }
  }
}
