import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DomainValuesModule, PipeLibsModule, SharedCoreModule } from '@renovars/core-ng';
import { UiModule } from '@renovars/ui-ng';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { EventModal } from '../core/modals/event-modal';
import { BusinessNamePipe } from '../core/pipes/business-name.pipe';
import { FirstOrBussinesNamePipe, FullNamePipe, LastNamePipe } from '../core/pipes/full-name.pipe';
import { IdToNamePipe } from '../core/pipes/id-to-name.pipe';
import { InTargetMsgPipe } from '../core/pipes/in-target-msg.pipe';
import { RecallDatePipe, StatusLabelPipe, StatusPipe, TypeLabelPipe } from '../core/pipes/status.pipe';
import { UserAdditionalInfoComponent } from './components/user-additional-info.component';
import { StatusHeaderComponent } from './components/status-header.component';

@NgModule({
  declarations: [
    UserAdditionalInfoComponent,
    BusinessNamePipe,
    StatusPipe,
    StatusLabelPipe,
    TypeLabelPipe,
    RecallDatePipe,
    FullNamePipe,
    IdToNamePipe,
    InTargetMsgPipe,
    FirstOrBussinesNamePipe,
    LastNamePipe,
    EventModal,
    StatusHeaderComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    UiModule,
    ToastModule,
    SharedCoreModule,
    ConfirmDialogModule,
    AccordionModule,
    TooltipModule,
    PipeLibsModule,
    DomainValuesModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UiModule,
    ToastModule,
    FullNamePipe,
    IdToNamePipe,
    InTargetMsgPipe,
    StatusPipe,
    StatusLabelPipe,
    TypeLabelPipe,
    RecallDatePipe,
    FirstOrBussinesNamePipe,
    LastNamePipe,
    SharedCoreModule,
    BusinessNamePipe,
    EventModal,
    ConfirmDialogModule,
    AccordionModule,
    TooltipModule,
    PipeLibsModule,
    DomainValuesModule,
    StatusHeaderComponent,
  ],
})
export class SharedModule {}
