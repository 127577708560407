import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CandidateType, RecruitRoles } from '@renovars/common/recruit';
import { CandidatesServices } from 'apps/recruit-ng/src/app/core/services/candidates.services';
import dayjs from 'dayjs';
import { filter } from 'rxjs/operators';
import { candidatiFormValidator } from '../../validators/candidati-form.validator';

@Component({
  selector: 'facile-candidati-all-info',
  template: `
    <form [formGroup]="form" novalidate>
      <div class="content">
        <div
          *ngIf="
            candidateType === CandidateType.WORKER ||
            candidateType === CandidateType.ARCHITECT ||
            candidateType === CandidateType.TO_SKILL
          "
        >
          <facile-candidati-all-info-form
            formControlName="candidato"
            [candidateType]="candidateType"
            [formInfoState]="form.get('candidato').errors"
          >
          </facile-candidati-all-info-form>
        </div>
        <div *ngIf="candidateType === CandidateType.COMPANY">
          <facile-candidati-impresa-all-info-form
            formControlName="candidato"
            [candidateType]="candidateType"
            [formInfoState]="form.get('candidato').errors"
          >
          </facile-candidati-impresa-all-info-form>
        </div>
        <div class="row mt-4" *permissions="[RecruitRoles.STORE_MANAGER, RecruitRoles.WORKER_MANAGER]">
          <fullstack-input-select-box
            [options]="{ label: 'Colloquio' }"
            class="col"
            formControlName="appointment"
          >
            <sb-option [value]="'colloquio effettuato in data'">colloquio effettuato in data</sb-option>
            <sb-option [value]="'colloquio da  effettuare in data'"
              >colloquio da effettuare in data</sb-option
            >
          </fullstack-input-select-box>
          <fullstack-datepicker
            [options]="{ label: 'Data' }"
            class="col"
            formControlName="date"
          ></fullstack-datepicker>
        </div>
        <div class="row mt-4">
          <div class="col-sm-12 text-end">
            <button
              type="button"
              class="btn btn-primary rounded me-4"
              [routerLink]="['/candidati/' + candidateType]"
            >
              Annulla
            </button>
            <button type="button" class="btn btn-secondary rounded" (click)="save()" [disabled]="!form.valid">
              Salva
            </button>
          </div>
        </div>
      </div>
    </form>
  `,
})
export class CandidatiAllInfoComponent implements OnInit {
  form = this.fb.group({
    candidato: new UntypedFormControl(null, [candidatiFormValidator]),
    date: new UntypedFormControl(null, []),
    appointment: new UntypedFormControl('', []),
  });

  id: string = null;
  candidateType: CandidateType;
  CandidateType = CandidateType;
  RecruitRoles = RecruitRoles;
  candidateTypeLabel: string;

  constructor(
    private fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private candidateService: CandidatesServices,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.activatedRoute.parent) {
      this.activatedRoute.parent.data.subscribe((res) => {
        this.candidateType = res.candidateType as CandidateType;
        this.candidateTypeLabel = CandidateType.toLabel(this.candidateType);
      });
    }

    this.activatedRoute.paramMap.subscribe((params) => {
      this.id = params.get('id');
      if (this.id) {
        this.candidateService
          .getCandidate(this.id)
          .pipe(filter((candidate) => candidate !== null))
          .subscribe((candidate) => this.form.patchValue({ candidato: candidate }));
      }
    });
  }

  save() {
    if (this.id) {
      this.candidateService.updateCandidate(this.id, this.form.get('candidato').value).subscribe();
    } else {
      const candidate = this.form.get('candidato').value;
      const statusNote =
        this.form.get('date').value && this.form.get('appointment').value
          ? this.form.get('appointment').value + ' ' + dayjs(this.form.get('date').value).format('DD/MM/YYYY')
          : null;
      this.candidateService.saveCandidate(candidate, statusNote).subscribe();
    }
  }
}
