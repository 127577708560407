import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CandidateType } from '@renovars/common/recruit';

@Component({
  selector: 'facile-candidati-upsert',
  template: `
    <fullstack-header></fullstack-header>
    <ng-template header-body>
      <h1 class="tx-title-1">Candidato {{ candidateTypeLabel }}</h1>
    </ng-template>
    <ng-template header-menu>
      <fullstack-tabs>
        <tab *ngIf="id" [label]="'Anagrafica'" [url]="'./anag-info' + queryString"></tab>
        <tab *ngIf="id" [label]="'Dettaglio'" [url]="'./main-info' + queryString"></tab>
        <tab *ngIf="id" [label]="'Profilo'" [url]="'./profile-info' + queryString"></tab>
      </fullstack-tabs>
    </ng-template>
    <router-outlet></router-outlet>
  `,
})
export class CandidatiUpsertComponent implements OnInit {
  public id: string;
  public queryString = '';
  candidateType: CandidateType;
  candidateTypeLabel: string;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((res) => {
      this.candidateType = res.candidateType as CandidateType;
      this.candidateTypeLabel = CandidateType.toLabel(this.candidateType);
    });

    if (this.activatedRoute.firstChild) {
      this.activatedRoute.firstChild.paramMap.subscribe((params) => {
        this.id = params.get('id');
        if (this.id) {
          this.queryString = `/${this.id}`;
        }
      });
    }
  }
}
