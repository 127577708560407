<div mat-dialog-content>
  <div class="m-2">
    {{ statusCode === StatusCodes.ADMIN_ASSEGNATO ? 'Assegna Hr' : 'Cambia stato del candidato in:'}}
    <span class="tx-x-bold">{{ StatusCodesLabel[statusCode] }}</span>
  </div>

  <form #statusForm="ngForm">
    <div class="row mt-2">
      <div class="col-12">
        <fullstack-input-textarea [options]="{ label: 'Note', rows: 2 }" [(ngModel)]="note" name="note">
        </fullstack-input-textarea>
      </div>
    </div>

    <div
      class="row mt-2"
      *ngIf="
            statusCode === StatusCodes.HR_DA_RICHIAMARE ||
            statusCode === StatusCodes.SM_COLLOQUIO_FISSATO ||
            statusCode === StatusCodes.SM_AFFIANCAMENTO_FISSATO ||
            statusCode === StatusCodes.SM_DA_RICHIAMARE ||
            statusCode === StatusCodes.HS_PRE_OK_FOLLOWUP
          "
    >
      <div class="col-12">
        <fullstack-datepicker [options]="{ label: 'Data' }" [(ngModel)]="date" name="date" required>
        </fullstack-datepicker>
      </div>
    </div>

    <div class="row mt-2" *ngIf="statusCode === StatusCodes.HR_ASSEGNATO">
      <div class="col-12">
        <fullstack-input-select-box
          [options]="{ label: 'Store manager' }"
          [(ngModel)]="assigned"
          name="assigned"
        >
          <sb-option [key]="'id'" [value]="i" *ngFor="let i of storeManagersAvailable$ | async">
            {{ i.fullName || i.email }}
          </sb-option>
        </fullstack-input-select-box>
      </div>
    </div>

    <div class="row mt-2" *ngIf="statusCode === StatusCodes.ADMIN_ASSEGNATO">
      <div class="col-12">
        <fullstack-input-select-box [options]="{ label: 'Hr' }" [(ngModel)]="assigned" name="assigned">
          <sb-option [key]="'id'" [value]="i" *ngFor="let i of selectableHr$ | async">
            {{ i.fullName || i.email }}
          </sb-option>
        </fullstack-input-select-box>
      </div>
    </div>
  </form>
</div>

<div mat-dialog-actions align="end">
  <button mat-button (click)="annulla()" class="btn btn-default rounded me-3">Annulla</button>
  <button
    mat-button
    (click)="salva(statusForm.value)"
    cdkFocusInitial
    class="btn btn-warning rounded"
    [disabled]="statusForm.invalid || invalidDate()"
  >
    Salva
  </button>
</div>
