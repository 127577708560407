import { UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { merge, isEmpty } from 'lodash';
import { ContactTypeEnum } from '@renovars/common';
import { CandidateType } from '@renovars/common/recruit';
const MESSAGE = 'Il campo è obbilgatorio';
export const candidatiFormValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
  const errors = {};
  if (control && control.value) {
    if (control.value.contact && control.value.contact.type === ContactTypeEnum.INDIVIDUAL) {
      merge(errors, validateContactIndividual(control));
    }

    if (control.value.contact && control.value.contact.type === ContactTypeEnum.COMPANY) {
      merge(errors, validateContactCompany(control));
    }

    if (control.value.type === CandidateType.ARCHITECT || control.value.type === CandidateType.WORKER) {
      merge(errors, validateCandidateArchitectWorker(control));
    }

    if (control.value.type === CandidateType.COMPANY) {
      merge(errors, validateCandidateCompany(control));
    }
  }
  return isEmpty(errors) ? null : errors;
};

const validateContactIndividual: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
  const errors = {};
  if (control && control.value) {
    if (!control.value.contact?.firstName) {
      merge(errors, { ['contact.firstName']: { required: true, error: MESSAGE } });
    }
    if (!control.value.contact?.lastName) {
      merge(errors, { ['contact.lastName']: { required: true, error: MESSAGE } });
    }
    if (!control.value.contact?.birthDate) {
      merge(errors, {
        ['contact.birthDate']: { required: true, error: MESSAGE },
      });
    }
    if (!control.value.contact?.source) {
      merge(errors, {
        ['contact.source']: { required: true, error: MESSAGE },
      });
    }
    if (!control.value.contact?.address) {
      merge(errors, {
        ['contact.address']: { required: true, error: MESSAGE },
      });
    }
  }
  return isEmpty(errors) ? null : errors;
};

const validateCandidateArchitectWorker: ValidatorFn = (
  control: UntypedFormGroup
): ValidationErrors | null => {
  const errors = {};

  if (!control.value.attachments || control.value.attachments.length === 0) {
    merge(errors, { attachments: { required: true, error: MESSAGE } });
  }

  if (!control.value.addressOp) {
    merge(errors, { addressOp: { required: true, error: MESSAGE } });
  }

  return isEmpty(errors) ? null : errors;
};

const validateContactCompany: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
  const errors = {};

  if (control && control.value) {
    if (!control.value.contact?.businessName) {
      merge(errors, {
        ['contact.businessName']: { required: true, error: MESSAGE },
      });
    }
    if (!control.value.contact?.vatCode) {
      merge(errors, {
        ['contact.vatCode']: { required: true, error: MESSAGE },
      });
    }
    if (!control.value.contact?.source) {
      merge(errors, {
        ['contact.source']: { required: true, error: MESSAGE },
      });
    }
    if (!control.value.contact?.referencePerson?.firstName) {
      merge(errors, {
        ['contact.referencePerson.firstName']: {
          required: true,
          error: MESSAGE,
        },
      });
    }

    if (!control.value.contact?.referencePerson?.lastName) {
      merge(errors, {
        ['contact.referencePerson.lastName']: {
          required: true,
          error: MESSAGE,
        },
      });
    }
  }

  return isEmpty(errors) ? null : errors;
};

const validateCandidateCompany: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
  const errors = {};
  if (!control.value.durc || control.value.durc.length === 0) {
    merge(errors, { durc: { required: true, error: MESSAGE } });
  }

  if (!control.value.addressOp) {
    merge(errors, { addressOp: { required: true, error: MESSAGE } });
  }

  return isEmpty(errors) ? null : errors;
};
export const addressOpValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
  const errors = {};
  if (control && control.value) {
    if (!control.value.addressOp) {
      merge(errors, { addressOp: { required: true, error: MESSAGE } });
    }
  }

  return isEmpty(errors) ? null : errors;
};
