import { Component, OnInit } from '@angular/core';
import { CommonRoles } from '@renovars/common';
import { RecruitRoles, TurnoverWithCalculations } from '@renovars/common/recruit';
import { AuthServices } from '@renovars/core-ng';
import dayjs from 'dayjs';
import { concatAll, filter, map, Observable, of, reduce, switchMap } from 'rxjs';
import { FrViewService } from './fr-view.service';

@Component({
  selector: 'facile-fr-view-turnover-imprese',
  templateUrl: './fr-view-turnover-imprese.component.html',
  styles: [],
})
export class FrViewTurnoverImpreseComponent implements OnInit {
  turnover$: Observable<TurnoverWithCalculations[]>;
  frUsers$: Observable<{ value: string; code: string }[]>;
  selectedUserId: string;
  //2023-05-27 data di inizio batch su fr
  minDate = dayjs('2023-05-27').startOf('day').toDate();
  maxDate = dayjs().subtract(1, 'day').endOf('day').toDate();
  range: { start: Date; end: Date } = {
    start: this.minDate,
    end: dayjs().subtract(1, 'day').endOf('day').toDate(),
  };
  constructor(private frViewService: FrViewService, private authService: AuthServices) {}

  ngOnInit(): void {
    const userByRole$ = this.authService.user().pipe(
      switchMap((u) => {
        const mapReturn = map((users: any[]) => ({
          user: u,
          userNet: users,
        }));
        if (u.roles.includes(RecruitRoles.AREA_MANAGER))
          return this.frViewService.getFrUsers('am').pipe(mapReturn);
        if (
          u.roles.includes(RecruitRoles.STORE_MANAGER) ||
          u.roles.includes(RecruitRoles.SM_VIP) ||
          u.roles.includes(RecruitRoles.WORKER_MANAGER)
        )
          return this.frViewService.getFrUsers('sm').pipe(mapReturn);
        if (u.roles.includes(RecruitRoles.DIRECTOR))
          return this.frViewService.getFrUsers('dir').pipe(mapReturn);
        return this.frViewService.getFrUsers('sm').pipe(mapReturn);
      })
    );
    this.frUsers$ = userByRole$.pipe(
      switchMap(({ user, userNet }) => {
        const meOnFr = userNet.find((u) => u.email == user.email);
        return user.roles.includes(CommonRoles.IS_ADMIN)
          ? this.frViewService.getFrUsers('sm')
          : this.frViewService.getFrUsers('children', meOnFr?.childrens);
      })
    );
    this.turnover$ = userByRole$.pipe(
      map(({ user, userNet }) => {
        const meOnFr = userNet.find((u) => u.email == user.email);
        return {
          frId: meOnFr?.code,
          recruitRole: user.roles,
        };
      }),
      switchMap(({ frId, recruitRole }) => {
        if (!frId && !recruitRole.includes(RecruitRoles.ADMIN)) {
          return of([]);
        }
        if (
          recruitRole.includes(RecruitRoles.STORE_MANAGER) ||
          recruitRole.includes(RecruitRoles.AREA_MANAGER) ||
          recruitRole.includes(RecruitRoles.DIRECTOR) ||
          recruitRole.includes(RecruitRoles.SHOW_ROOM_MANAGER)
        ) {
          this.selectedUserId = frId;
        }
        return this.frViewService.getTurnoverImprese(this.range, frId).pipe(
          concatAll(),
          filter((v) => !!v),
          map((v) => ({
            ...v,
            direttore: v.direttore.replace('|', ''),
            areaManager: v.areaManager.replace('|', ''),
            regionalManager: v.regionalManager.replace('|', ''),
            storeManager: v.storeManager.replace('|', ''),
          })),
          reduce((a, b) => [...a, b], [])
        );
      })
    );
  }
  search() {
    this.turnover$ = this.authService.getUserRoles().pipe(
      switchMap((roles) => {
        if (roles.includes(RecruitRoles.STORE_MANAGER) && !this.selectedUserId) {
          return of([]);
        } else {
          return this.frViewService.getTurnover(this.range, this.selectedUserId).pipe(
            concatAll(),
            filter((v) => !!v),
            map((v) => ({
              ...v,
              direttore: v.direttore.replace('|', ''),
              areaManager: v.areaManager.replace('|', ''),
              regionalManager: v.regionalManager.replace('|', ''),
              storeManager: v.storeManager.replace('|', ''),
            })),
            reduce((a, b) => [...a, b], [])
          );
        }
      })
    );
  }
}
