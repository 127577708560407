import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IRecruitEvent } from '@renovars/common/recruit';
import { ReassignEventService } from '../../reassign-event.service';

@Component({
  selector: 'facile-modal-appointment-msg',
  templateUrl: './modal-appointment-msg.component.html',
  styles: [],
})
export class ModalAppointmentMsgComponent implements OnInit {
  msg: UntypedFormControl;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IRecruitEvent,
    private reassignService: ReassignEventService,
    public dialogRef: MatDialogRef<ModalAppointmentMsgComponent>
  ) {}
  ngOnInit(): void {
    this.msg = new UntypedFormControl('');
    console.log(this.data);
  }
  save() {
    this.reassignService.setAdmingMsg(this.data, this.msg.value).subscribe(() => this.dialogRef.close());
  }
}
