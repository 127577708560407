import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IObjectList } from '@renovars/common';
import { IRecruitSource } from '@renovars/common/recruit';
import { environment } from 'apps/recruit-ng/src/environments/environment';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RecruitSourceService {
  cachedSources$: Observable<IRecruitSource[]>;
  constructor(private http: HttpClient) {}

  getSources$() {
    return this.http
      .get<
        {
          pos: number;
          label: string;
        }[]
      >(`${environment.apiGatewayURL}/recruit/external/source`)
      .pipe(map((ds) => ds.map((d) => d.label)));
  }
  getSourcesAll$() {
    return this.http
      .get<IObjectList>(`${environment.apiGatewayURL}/recruit/recruit-sources`)
      .pipe(map((res) => res.data.map((s) => s.label as string)));
  }
  getSourcesFull$() {
    return this.http
      .get<IObjectList<IRecruitSource>>(`${environment.apiGatewayURL}/recruit/recruit-sources`)
      .pipe(map((res) => res.data));
  }
  getCached$() {
    if (this.cachedSources$) {
      return this.cachedSources$;
    } else {
      this.cachedSources$ = this.getSourcesFull$().pipe(shareReplay(1));
      return this.cachedSources$;
    }
  }
}
