import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IEvent } from '@renovars/common';
import { environment } from 'apps/recruit-ng/src/environments/environment';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RecruitEventApiService {
  environment = environment;
  constructor(private http: HttpClient, private messageService: MessageService) {}

  createTraining(obj: IEvent): Observable<any> {
    return this.http.post<any>(`${this.environment.apiGatewayURL}/recruit/events/training`, obj);
  }
  updateTraining(id: string, obj: IEvent): Observable<any> {
    return this.http.post<any>(`${this.environment.apiGatewayURL}/recruit/events/training/${id}`, obj);
  }
}
