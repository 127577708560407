import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AvailabilityResponseBody, IEvent } from '@renovars/common';
import {
  HRCompanyOKCodes,
  ICandidate,
  RecruitEventType,
  RecruitRoles,
  SMCompanyOKCodes,
} from '@renovars/common/recruit';
import { EventService } from '@renovars/core-ng';
import { switchMap, zip } from 'rxjs';
import { buildCompanyMailText } from './mail-text.utils';
import { UsersService } from '@renovars/fe-core-libs/services/users.service';

@Component({
  selector: 'facile-modal-company-appointment',
  template: `
    <div mat-dialog-content>
      <form [formGroup]="formAppointment" (submit)="save()">
        <div class="row">
          <div class="col-12 mt-1">
            <fullstack-input-address-box [options]="{ label: 'Indirizzo' }" formControlName="address">
            </fullstack-input-address-box>
          </div>

          <div class="col-6 mt-1">
            <fullstack-daterangepicker
              [options]="{ label: 'Range' }"
              (rangeChange)="range = $event; checkEventAvailability()"
            >
            </fullstack-daterangepicker>
          </div>

          <div class="col-6 mt-1">
            <fullstack-input-select-box [options]="{ label: 'Store manager' }" [formControl]="slot">
              <ng-container *ngFor="let s of slotList">
                <sb-option [value]="{ account: a, slot: s }" *ngFor="let a of s.accounts">
                  {{ s.start | date: 'short' }} - {{ a.fullname }}
                </sb-option>
              </ng-container>
            </fullstack-input-select-box>
          </div>

          <div class="col-12 mt-1">
            <fullstack-input-textarea [options]="{ label: 'Note', rows: 2 }" formControlName="note">
            </fullstack-input-textarea>
          </div>

          <div class="col-12 mt-1" *ngIf="slot.value">
            <fullstack-input-check-box
              [options]="{ label: 'Vuoi inviare l\\'email?' }"
              formControlName="sendMail"
            ></fullstack-input-check-box>
          </div>

          <div class="col-12 mt-1" *ngIf="slot.value && formAppointment.controls?.sendMail.value">
            <fullstack-input-check-box
              [options]="{ label: 'Vuoi Sostituire il corpo della mail?' }"
              formControlName="mailVisible"
            ></fullstack-input-check-box>
          </div>

          <div class="col-12 mt-1" *ngIf="formAppointment.controls?.mailVisible.value">
            <fullstack-input-textarea
              [options]="{ label: 'Testo opzionale', rows: 4 }"
              formControlName="mailText"
            >
            </fullstack-input-textarea>
          </div>
        </div>

        <div class="text-end">
          <button type="button" class="btn btn-default rounded m-1" (click)="dialogRef.close()">
            Annulla
          </button>

          <button [disabled]="!formAppointment.valid" type="submit" class="btn btn-warning rounded m-1">
            Crea
          </button>
        </div>
      </form>
    </div>
  `,
  styles: [],
})
export class ModalCompanyAppointmentComponent implements OnInit {
  fb: FormBuilder = new FormBuilder();
  formAppointment: FormGroup;
  slotList: AvailabilityResponseBody[];
  range: { start: Date; end: Date };
  slot: UntypedFormControl;
  constructor(
    private eventService: EventService,
    public dialogRef: MatDialogRef<ModalCompanyAppointmentComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { candidato: ICandidate; statusCode: HRCompanyOKCodes },
    private userService: UsersService
  ) {
    this.formAppointment = this.fb.group({
      contact: new UntypedFormControl('', []),
      start: new UntypedFormControl('', []),
      end: new UntypedFormControl('', []),
      address: new UntypedFormControl(this.data?.candidato?.addressOp ?? '', []),
      user: new UntypedFormControl('', []),
      note: new UntypedFormControl('', []),
      mailVisible: new UntypedFormControl(false, []),
      sendMail: new UntypedFormControl(true, []),
      mailText: new UntypedFormControl('', []),
    });
    this.slot = new UntypedFormControl({}, []);
  }

  ngOnInit(): void {
    this.formAppointment
      .get('mailVisible')
      .valueChanges.pipe(
        switchMap(() => {
          const event = this.buildEvent();
          return zip([this.userService.getUser(event.user), this.userService.getUserMeta(event.user)]);
        })
      )
      .subscribe(([user, meta]) => {
        const event = this.buildEvent();
        const text = buildCompanyMailText(this.data.candidato, user, meta, event);
        this.formAppointment.get('mailText').setValue(text);
      });
  }

  checkEventAvailability() {
    const eventForm = this.formAppointment.value;
    if (eventForm.address && this.range && this.range.start && this.range.end) {
      const request: Partial<IEvent> = {
        start: this.range.start,
        end: this.range.end,
        address: eventForm.address,
      };
      this.eventService
        .checkEventAvailability(
          request,
          this.data.statusCode == HRCompanyOKCodes.SM_PRIMO_COLLOQUIO_SM
            ? [RecruitRoles.STORE_MANAGER]
            : [RecruitRoles.HR_OFFICE]
        )
        .subscribe((res) => (this.slotList = res));
    }
  }
  save() {
    if (this.formAppointment.valid && this.slot.valid) {
      const eventForm = this.buildEvent();
      this.eventService.create(eventForm).subscribe(() => this.dialogRef.close());
    }
  }

  private buildEvent() {
    const eventForm = this.formAppointment.value;
    const slot: { account; slot } = this.slot.value;
    eventForm.start = new Date(slot.slot.start);
    eventForm.end = new Date(slot.slot.end);
    eventForm.user = slot.account.id;
    eventForm.type =
      this.data.statusCode == HRCompanyOKCodes.SM_PRIMO_COLLOQUIO_SM
        ? RecruitEventType.COMPANY_PRIMO_COLLOQUIO
        : RecruitEventType.COMPANY_SECONDO_COLLOQUIO;
    eventForm.contact = this.data.candidato.contact;
    eventForm.candidate = this.data.candidato._id;
    return eventForm;
  }
}
