import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IUserMeta } from '@renovars/common';

@Injectable()
export class RecuitUsersServices {
  constructor(private http: HttpClient) {}

  getUserMeta(id): Observable<IUserMeta> {
    return this.http.get<IUserMeta>(`${environment.apiGatewayURL}/recruit/user-meta/by-user-id/${id}`);
  }

  saveUserMeta(userMeta: IUserMeta): Observable<IUserMeta> {
    console.log('+ userMeta', userMeta, typeof userMeta);
    return this.http.post<IUserMeta>(`${environment.apiGatewayURL}/recruit/user-meta`, userMeta);
  }

  updateUserMeta(id: string, userMeta: IUserMeta): Observable<IUserMeta> {
    console.log('+ userMeta', userMeta, typeof userMeta);
    return this.http.put<IUserMeta>(`${environment.apiGatewayURL}/recruit/user-meta/${id}`, userMeta);
  }

  deleteUserMeta(id: string): Observable<void> {
    return this.http.delete<void>(`${environment.apiGatewayURL}/recruit/user-meta/${id}`);
  }
}
