import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FrViewPageComponent } from './fr-view-page.component';
import { FrViewStateComponent } from './fr-view-state.component';
import { FrViewTurnoverComponent } from './fr-view-turnover.component';
import { FrViewService } from './fr-view.service';
import { FrViewTurnoverImpreseComponent } from './fr-view-turnover-imprese.component';

const routes: Routes = [
  {
    path: '',
    component: FrViewPageComponent,
    children: [
      {
        path: 'stati',
        component: FrViewStateComponent,
      },
      {
        path: 'turnover',
        children: [
          {
            path: 'architetti',
            component: FrViewTurnoverComponent,
          },
          {
            path: 'imprese',
            component: FrViewTurnoverImpreseComponent,
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [FrViewService],
})
export class FrViewRoutingModule {}
