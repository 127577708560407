import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IUser } from '@renovars/common';
import {
  AllStatusCodes,
  CandidateType,
  HRCompanyOKCodes,
  ICandidate,
  RecruitRoles,
  SMArchitectOKCodes,
  SMCompanyOKCodes,
  StatusCodesLabel,
  getStatusCodes,
  isStoreManager,
} from '@renovars/common/recruit';
import { AuthServices } from '@renovars/core-ng';
import { UsersService } from '@renovars/fe-core-libs/services/users.service';
import type { IFilter } from '@renovars/mongoose-nest';
import * as _ from 'lodash';
import { MenuItem, MessageService } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { CandidatesServices } from '../../../core/services/candidates.services';
import { CsvUtilsService } from '../../../core/services/csv-utils.service';
import removeEmpty from '../../../core/utils/obj.util';
import { CandidatiFilterService } from '../services/candidati-filter.service';
import { ModalCandidatiStatoComponent } from './modal-candidati-stato/modal-candidati-stato.component';
import { ModalCompanyAppointmentComponent } from './modal-candidati-stato/modal-company-appointment.component';
import { ModalAssingUserComponent } from './modal-candidati-stato/modal-assing-user.component';

@Component({
  selector: 'facile-company',
  template: `
    <fullstack-header></fullstack-header>
    <div class="row">
      <h1 class="tx-title-1">Candidati {{ candidateTypeLabel }}</h1>
      <h2 class="tx-subtitle mb-0">Lista</h2>
      <div class="text-end align-items-center">
        <button
          class="btn btn-header me-2"
          (click)="resetFilter()"
          pTooltip="Resetta Filtri"
          tooltipPosition="left"
        >
          <i class="fas fa-trash cl-medium fa-1x"></i>
        </button>
        <button class="btn btn-header" [routerLink]="['upsert/all-info']">
          <i class="fas fa-plus cl-medium fa-1x"></i>
        </button>
      </div>
      <div class="text-end align-items-center">
        <p-confirmDialog
          [style]="{ width: '50vw' }"
          [baseZIndex]="10000"
          rejectButtonStyleClass="p-button-text"
        ></p-confirmDialog>
        <button
          class="btn btn-warning me-2 btn-sm"
          (click)="downloadCSV()"
          *permissions="['is_admin', 'is_director', 'interview_result_viewer']"
        >
          Download candidati
        </button>
        <button
          class="btn btn-warning btn-sm"
          (click)="downloadCandidateByDirector()"
          *permissions="['is_admin', 'is_director']"
        >
          Download rete
        </button>
      </div>
    </div>
    <facile-status-header
      [roles]="roles"
      [candidateType]="CandidateType.COMPANY"
      (onSelect)="onStatusSelect($event)"
    ></facile-status-header>
    <fullstack-datatable
      #dt1
      [options]="{ lazy: true }"
      (lazyLoad)="onLazyLoad($event)"
      [list]="candidates$ | async"
      [totalRecords]="total$ | async"
      [cmItems]="cmItems"
      [selection]="selected"
      (selected)="onSelection($event)"
      [paginator]="(total$ | async) > 0"
      [stileClass]="'p-datatable-sm'"
    >
      <div t-header>
        <div t-column field="createdAt">
          <ng-template t-col-title hideable="'Creato il'">Creato il</ng-template>
          <fullstack-daterangepicker
            *t-filter
            (rangeChange)="rangeChange($event, 'createdAt')"
          ></fullstack-daterangepicker>
        </div>
        <div t-column field="cache.contact.businessName">
          <ng-template t-col-title>Nome</ng-template>
          <fullstack-input-box
            *t-filter
            (keyup.enter)="search()"
            [(ngModel)]="candidateFilter.where.businessName"
          >
          </fullstack-input-box>
        </div>
        <div t-column width="160px">
          <ng-template t-col-title>Telefono</ng-template>
          <fullstack-input-box
            *t-filter
            (keyup.enter)="search()"
            [(ngModel)]="candidateFilter.where['phone']"
          >
          </fullstack-input-box>
        </div>
        <div t-column width="160px">
          <ng-template t-col-title>Località</ng-template>
          <fullstack-input-box
            *t-filter
            [(ngModel)]="candidateFilter.where['addressOp.locality']"
            (keyup.enter)="search()"
          >
          </fullstack-input-box>
        </div>
        <div t-column width="130px">
          <ng-template t-col-title>Provincia</ng-template>
          <fullstack-input-box
            *t-filter
            [(ngModel)]="candidateFilter.where['addressOp.province']"
            (keyup.enter)="search()"
          >
          </fullstack-input-box>
        </div>
        <div t-column>
          <ng-template t-col-title>Store Manager</ng-template>
          <fullstack-input-select-box
            *t-filter
            [(ngModel)]="candidateFilter.where.assigned"
            (ngModelChange)="search()"
          >
            <sb-option [key]="'id'" [value]="i" *ngFor="let i of storeManagers$ | async">{{
              i.firstName + ' ' + i.lastName
            }}</sb-option>
          </fullstack-input-select-box>
        </div>
        <div t-column>
          <ng-template t-col-title>Inserito da</ng-template>
          <fullstack-input-select-box
            *t-filter
            [(ngModel)]="candidateFilter.where.createdBy"
            (ngModelChange)="search()"
          >
            <sb-option [key]="'id'" [value]="i" *ngFor="let i of storeManagers$ | async">{{
              i.firstName + ' ' + i.lastName
            }}</sb-option>
          </fullstack-input-select-box>
        </div>
        <div t-column>
          <ng-template t-col-title>Recruiter</ng-template>
          <fullstack-input-select-box
            *t-filter
            [(ngModel)]="candidateFilter.where.assignedHR"
            (ngModelChange)="search()"
          >
            <sb-option [key]="'id'" [value]="i" *ngFor="let i of hrs$ | async">{{
              i.firstName + ' ' + i.lastName
            }}</sb-option>
          </fullstack-input-select-box>
        </div>
        <div t-column field="assignedAt">
          <ng-template t-col-title>Assegnato il</ng-template>
          <fullstack-daterangepicker
            *t-filter
            (rangeChange)="rangeChange($event, 'assignedAt')"
          ></fullstack-daterangepicker>
        </div>
        <div t-column>
          <ng-template t-col-title>Stato</ng-template>

          <fullstack-input-select-box
            *t-filter
            [options]="{}"
            [(ngModel)]="candidateFilter.where['status.code']"
            (ngModelChange)="search()"
          >
            <sb-option [key]="'code'" [value]="i" *ngFor="let i of statusList">{{ i.label }}</sb-option>
          </fullstack-input-select-box>
        </div>
        <div t-column>
          <ng-template t-col-title>Da Contattare il</ng-template>
          <fullstack-daterangepicker
            *t-filter
            (rangeChange)="rangeChange($event, 'recallAt')"
          ></fullstack-daterangepicker>
        </div>
        <div t-column>
          <ng-template t-col-title>Ultimo Appuntamento</ng-template>
        </div>
        <div t-column>
          <ng-template t-col-title>Note</ng-template>
        </div>
      </div>
      <ng-template t-body let-row>
        <td>{{ row?.createdAt | date: 'dd-MM-yyyy HH:mm' }}</td>
        <td>{{ row?.cache?.contact?.businessName }}</td>
        <td>{{ row?.cache?.contact?.phones && (row?.cache?.contact?.phones)[0]?.phoneNumber }}</td>
        <td>{{ row?.addressOp?.locality }}</td>
        <td>{{ row?.addressOp?.province }}</td>
        <td>{{ row?.assigned | fullName }}</td>
        <td>{{ row?.createdBy | fullName }}</td>
        <td>{{ row?.assignedHR | idToName: users }}</td>
        <td>{{ row?.assignedAt | date: 'dd-MM-yyyy HH:mm' }}</td>
        <td>{{ StatusCodesLabel[row?.status?.code] }}</td>
        <td>{{ row?.status | recallDate | date: 'dd-MM-yyyy' }}</td>
        <td>{{ row?.lastAppointment | date: 'dd-MM-yyyy HH:mm' }}</td>
        <td>{{ row?.status?.data?.note }}</td>
      </ng-template>
    </fullstack-datatable>

    <div *ngIf="(candidates$ | async)?.length === 0" class="alert alert-primary" role="alert">
      Nessun candidato trovato
    </div>
  `,
})
export class CompanyComponent implements OnInit {
  cmStatusItems: MenuItem[] = [];
  cmItems: MenuItem[];

  StatusCodesLabel = StatusCodesLabel;
  StatusCodes = AllStatusCodes;
  candidates$: Observable<ICandidate[]>;
  storeManagers$: Observable<IUser[]>;
  hrs$: Observable<IUser[]>;
  users: IUser[];
  total$: Observable<number>;
  selected: ICandidate = null;
  statusListOK: { code: string; label: string }[] = [];
  statusListKO: { code: string; label: string }[] = [];
  statusList: { code: string; label: string }[] = [];
  candidateType: CandidateType;
  CandidateType = CandidateType;
  candidateTypeLabel: string;
  isAdmin = false;
  candidateFilter: IFilter = { where: {} };
  roles: RecruitRoles[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private candidatesServices: CandidatesServices,
    private authService: AuthServices,
    private usersService: UsersService,
    private csvUtilService: CsvUtilsService,
    private filterService: CandidatiFilterService,
    private message: MessageService
  ) {}

  ngOnInit(): void {
    this.route.data
      .pipe(
        switchMap((res) => {
          this.candidateType = res.candidateType as CandidateType;
          this.candidateTypeLabel = CandidateType.toLabel(this.candidateType);
          return this.authService.user();
        }),
        switchMap((user) => {
          return this.usersService
            .getUsersListDownline(
              [RecruitRoles.ADMIN, RecruitRoles.HR_OFFICE],
              [
                RecruitRoles.STORE_MANAGER,
                RecruitRoles.HR_OFFICE,
                RecruitRoles.CONSUMER,
                RecruitRoles.SM_VIP,
                RecruitRoles.WORKER_MANAGER,
              ]
            )
            .pipe(map((metas) => ({ metas, user })));
        })
      )
      .subscribe(({ metas, user }) => {
        const roles = user.roles;
        this.roles = roles as RecruitRoles[];
        if (roles.includes(RecruitRoles.ADMIN)) this.isAdmin = true;
        this.cmItems = this.candidatesServices.getCmdItemsForList(
          this.candidateType,
          roles,
          this.selectAction
        );
        if (roles.includes(RecruitRoles.HR_OFFICE)) {
          this.hrs$ = of(metas.filter((meta) => meta.id === user.sub));
        } else {
          this.users = metas.filter((m) =>
            m.registrations.some((reg) => reg.roles.includes(RecruitRoles.HR_OFFICE))
          );
          this.hrs$ = of(this.users);
        }
        this.filterStatusByRoles(this.roles);
      });

    // Elenco degli store manager
    this.storeManagers$ = this.usersService.getUsersListDownline(
      [RecruitRoles.ADMIN, RecruitRoles.HR_OFFICE],
      [
        RecruitRoles.STORE_MANAGER,
        RecruitRoles.HR_OFFICE,
        RecruitRoles.CONSUMER,
        RecruitRoles.SM_VIP,
        RecruitRoles.WORKER_MANAGER,
      ]
    );
  }
  private filterStatusByRoles(roles: RecruitRoles[]) {
    this.statusList = getStatusCodes(RecruitRoles.ADMIN, CandidateType.COMPANY);
    if (isStoreManager(roles)) {
      this.statusList = getStatusCodes(RecruitRoles.STORE_MANAGER, CandidateType.COMPANY);
    }
    if (roles.includes(RecruitRoles.HR_SPECIALIST)) {
      this.statusList = getStatusCodes(RecruitRoles.HR_SPECIALIST, CandidateType.COMPANY);
    } else if (roles.includes(RecruitRoles.HR_OFFICE)) {
      this.statusList = getStatusCodes(RecruitRoles.HR_OFFICE, CandidateType.COMPANY);
    }
  }
  onLazyLoad(event) {
    this.candidateFilter.skip = event.first;
    this.candidateFilter.limit = event.rows;
    this.candidateFilter.order = this.buildOrder(event);
    const where = this.filterService.getFilter(this.candidateType)?.where;
    this.candidateFilter.where = {
      ...where,
      type: this.candidateType,
    };
    this.search();
  }
  resetFilter() {
    this.candidateFilter.skip = 0;
    this.candidateFilter.limit = 10;
    this.candidateFilter.order = ['-createdAt'];
    this.candidateFilter.where = {
      type: this.candidateType,
    };
    this.filterService.setFilter(this.candidateFilter, this.candidateType);
    this.search();
  }
  searchFrIntegration(code: SMArchitectOKCodes.SM_ATTIVATO_FR | SMArchitectOKCodes.SM_DA_ATTIVARE_FR) {
    this.candidateFilter.where = {
      type: this.candidateType,
      'status.code': code,
    };
    this.search();
  }
  onSelection(event) {
    if (!event) return;
    this.selected = event;
    const item = this.cmItems.find((i) => i.id === 'fr_activation');
    if (event?.status?.code === SMArchitectOKCodes.SM_OPERATIVO && event.type == CandidateType.ARCHITECT) {
      item.visible = true;
    } else {
      item.visible = false;
    }
    this.authService
      .user()
      .pipe(
        filter((u) => u.roles.includes(RecruitRoles.STORE_MANAGER)),
        map(() => event?.status?.code === SMArchitectOKCodes.SM_DA_VERIFICARE)
      )
      .subscribe((value) => {
        if (value) {
          const item = this.cmItems.find((i) => i.id === 'status');
          item.visible = false;
        }
      });
  }

  private buildOrder(event) {
    if (!_.isEmpty(event.sortField)) {
      let sign = '';
      if (event.sortOrder.toString().length == 2) {
        sign = event.sortOrder.toString().substring(0, 1);
      }
      return [sign + event.sortField];
    } else {
      return ['-createdAt'];
    }
  }

  selectAction = (props: { action: string; data: any }) => {
    switch (props.action) {
      case 'gotoUpdate':
        this.gotoUpdate();
        break;
      case 'gotoDetail':
        this.gotoDetail();
        break;
      case 'delete':
        this.delete();
        break;
      case 'assign':
        this.openModalAssignUsers();
        break;
      case 'modalChangeStatus':
        if (props.data.statusCode === HRCompanyOKCodes.SM_PRIMO_COLLOQUIO_SM) {
          this.createAppointmentForCompany(HRCompanyOKCodes.SM_PRIMO_COLLOQUIO_SM);
        } else if (props.data.statusCode === HRCompanyOKCodes.HR_SECONDO_COLLOQUIO_RECRUITER) {
          this.createAppointmentForCompany(HRCompanyOKCodes.HR_SECONDO_COLLOQUIO_RECRUITER);
        } else {
          this.modalChangeStatus(props.data.statusCode);
        }
        break;
    }
  };

  gotoUpdate() {
    this.router.navigate(['upsert/main-info', this.selected.id], { relativeTo: this.route });
  }

  gotoDetail() {
    this.router.navigate(['details/main-info', this.selected.id], { relativeTo: this.route });
  }

  delete() {
    this.candidatesServices.deleteCandidate(this.selected.id).subscribe(() => this.search());
  }

  rangeChange(event, eventType?: string) {
    if (event) {
      switch (eventType) {
        case 'createdAt':
          this.candidateFilter.where = {
            ...this.candidateFilter.where,
            createdAt: { $gte: event.start, $lte: event.end },
          };
          break;
        case 'assignedAt':
          this.candidateFilter.where = {
            ...this.candidateFilter.where,
            assignedAt: { $gte: event.start, $lte: event.end },
          };
          break;
        case 'lastAppointmentAt':
          this.candidateFilter.where = {
            ...this.candidateFilter.where,
            lastAppointment: { $gte: event.start, $lte: event.end },
          };
          break;
        case 'recallAt':
          this.candidateFilter.where = {
            ...this.candidateFilter.where,
            'status.data.date': { $gte: event.start, $lte: event.end },
          };
          break;
        case 'frAt':
          this.candidateFilter.where = {
            ...this.candidateFilter.where,
            'status.createdAt': { $gte: event.start, $lte: event.end },
            'status.code': SMArchitectOKCodes.SM_ATTIVATO_FR,
          };
          break;
        default:
      }
    } else {
      this.candidateFilter.where['createdAt'] = undefined;
      this.candidateFilter.where['assignedAt'] = undefined;
      this.candidateFilter.where['status.data.date'] = undefined;
      this.candidateFilter.where['status.createdAt'] = undefined;
    }
    this.search();
  }

  modalChangeStatus(statusCode) {
    const dialogRef = this.dialog.open(ModalCandidatiStatoComponent, {
      minWidth: '600px',
      minHeight: '300px',
      data: {
        statusCode,
        candidato: this.selected,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(
        switchMap((res) => {
          if (res && res.error) {
            this.message.add({
              severity: 'error',
              summary: res.error,
            });
            return of(null);
          }
          return res ? this.candidatesServices.changeStatusCandidate(res.candidato.id, res.status) : of(null);
        })
      )
      .subscribe(() => {
        this.search();
      });
  }

  createAppointmentForCompany(type: SMCompanyOKCodes | HRCompanyOKCodes) {
    const dialogRef = this.dialog.open(ModalCompanyAppointmentComponent, {
      minWidth: '600px',
      minHeight: '300px',
      data: {
        statusCode: type,
        candidato: this.selected,
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.search();
    });
  }
  openModalAssignUsers() {
    const dialogRef = this.dialog.open(ModalAssingUserComponent, {
      minWidth: '600px',
      minHeight: '300px',
      data: {
        assigned:
          typeof this.selected?.assigned === 'string' ? this.selected.assigned : this.selected?.assigned?.id,
        assignedHR: this.selected?.assignedHR,
        candidateId: this.selected?._id,
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.search();
    });
  }

  search() {
    this.filterService.setFilter(removeEmpty(this.candidateFilter), this.candidateType);
    const filter = this.filterService.getFilter(this.candidateType);
    this.candidatesServices
      .search(filter)
      .pipe(
        tap((res) => {
          this.candidates$ = of(res.data);
          this.total$ = of(res.total);
        })
      )
      .subscribe();
  }

  downloadCSV() {
    this.candidateFilter.limit = 99999;
    this.candidateFilter.skip = 0;
    this.csvUtilService
      .mapCsv(this.candidatesServices.search(this.candidateFilter, true), 'candidates')
      .subscribe();
  }
  downloadCandidateByDirector() {
    this.candidatesServices.downloadCandidateByDirector().subscribe((arr: ArrayBuffer) => {
      const a = document.createElement('a');
      const blob = new Blob([arr], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
      });
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = 'candidates_by_directors.xlsx';
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
  }
  onStatusSelect(status: keyof typeof AllStatusCodes) {
    this.candidateFilter.where['status.code'] = status;
    this.search();
  }
}
