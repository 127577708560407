import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CandidateType } from '@renovars/common/recruit';
import { CandidatesServices } from 'apps/recruit-ng/src/app/core/services/candidates.services';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'facile-candidati-details-profile-info',
  template: `
    <form [formGroup]="form" novalidate>
      <div class="content">
        <div class="row">
          <div class="col-12">
            <header class="page-header">
              <h2 class="tx-title-2">Profilo</h2>
            </header>
          </div>
        </div>

        <facile-candidati-profile-info-form
          *ngIf="
            candidateType === CandidateType.WORKER ||
            candidateType === CandidateType.ARCHITECT ||
            candidateType === CandidateType.TO_SKILL
          "
          formControlName="profile"
          [candidateType]="candidateType"
        >
        </facile-candidati-profile-info-form>
        <facile-candidati-impresa-profile-info-form
          *ngIf="candidateType === CandidateType.COMPANY"
          formControlName="profile"
        >
        </facile-candidati-impresa-profile-info-form>

        <div class="row mt-4">
          <div class="col-sm-12 text-end">
            <button
              type="button"
              class="btn btn-primary rounded me-4"
              [routerLink]="['/candidati/' + candidateType]"
            >
              Indietro
            </button>
          </div>
        </div>
      </div>
    </form>
  `,
  styles: [],
})
export class CandidatiDetailsProfileInfoComponent implements OnInit {
  form = this.fb.group({
    profile: new UntypedFormControl({ value: null, disabled: true }),
  });

  id: string = null;
  candidateType: CandidateType;
  CandidateType = CandidateType;
  candidateTypeLabel: string;

  constructor(
    private fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private candidateService: CandidatesServices
  ) {}

  ngOnInit(): void {
    if (this.activatedRoute.parent) {
      this.activatedRoute.parent.data.subscribe((res) => {
        this.candidateType = res.candidateType as CandidateType;
        this.candidateTypeLabel = CandidateType.toLabel(this.candidateType);
      });
    }

    this.activatedRoute.paramMap.subscribe((params) => {
      this.id = params.get('id');
      if (this.id) {
        this.candidateService
          .getCandidate(this.id)
          .pipe(filter((candidate) => candidate !== null))
          .subscribe((candidate) => {
            if (candidate.profile) {
              this.form.patchValue({ profile: candidate.profile });
            }
          });
      }
    });
  }
}
