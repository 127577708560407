import { Pipe, PipeTransform } from '@angular/core';
import { CommonRoles } from '@renovars/common';
import { AuthServices } from '@renovars/core-ng';
import { map, of } from 'rxjs';

@Pipe({ name: 'inTarget' })
export class InTargetMsgPipe implements PipeTransform {
  constructor(private authServices: AuthServices) {}
  transform(value: { to: string; msg: string }) {
    if (!value) return of('-');
    return this.authServices.user().pipe(
      map((user) => {
        if (user.roles.includes(CommonRoles.IS_ADMIN)) return value.msg;
        if (user.sub === value.to) return value.msg;
        return '-';
      })
    );
  }
}
