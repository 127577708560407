import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppuntamentoModule, ContactsServices, UtilsService } from '@renovars/core-ng';
import { environment } from 'apps/recruit-ng/src/environments/environment';
import { RecuitUsersServices } from '../../core/services/recuit-users-services.service';
import { SharedModule } from '../../shared/shared.module';
import { AppointmentRecruitListComponent } from './appointment-list.component';
import { AppointmentWrapRoutingModule } from './appointment-wrap-routing';
import { ModalAppointmentMsgComponent } from './modals/modal-appointment-msg/modal-appointment-msg.component';
import { ModalAppointmentStatoComponent } from './modals/modal-appointment-stato.component';
import { ModalEditStatoComponent } from './modals/modal-edit-stato.component';
import { ModalReassignAppointmentComponent } from './modals/modal-reassign-appointment/modal-reassign-appointment.component';
import { ReassignEventService } from './reassign-event.service';

@NgModule({
  declarations: [
    AppointmentRecruitListComponent,
    ModalAppointmentStatoComponent,
    ModalEditStatoComponent,
    ModalReassignAppointmentComponent,
    ModalAppointmentMsgComponent,
  ],
  imports: [
    SharedModule,
    CommonModule,
    AppointmentWrapRoutingModule,
    AppuntamentoModule.forRoot({
      eventsEndpoint: `${environment.apiGatewayURL}/recruit/events`,
    }),
  ],
  exports: [ModalReassignAppointmentComponent],
  providers: [ReassignEventService, UtilsService, ContactsServices, RecuitUsersServices],
})
export class AppointmentWrapModule {}
