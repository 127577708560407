/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IAddress, IContactIndividual } from '@renovars/common';
import { ArchitectCodes, ICandidate } from '@renovars/common/recruit';
import { AuthServices } from '@renovars/core-ng';
import { CandidatesServices } from 'apps/recruit-ng/src/app/core/services/candidates.services';
import { MessageService } from 'primeng/api';
import { concatAll, filter, Observable, of, switchMap, tap } from 'rxjs';

type ViewType = 'active_ctx' | 'active_detail';
@Component({
  selector: 'facile-modal-fr-activation',
  templateUrl: './modal-fr-activation.component.html',
  styles: [],
})
export class ModalFrActivationComponent implements OnInit {
  statusCode: string;
  type: ViewType;
  candidato: ICandidate;
  disabled = false;
  parent: string;
  shop: string;
  username: string;
  workingEmail: string;
  stateOnFr: { isAvailable: boolean; busy: boolean } = { isAvailable: false, busy: false };
  frAddress: IAddress;
  frType: string;
  radius: number;
  note: string;
  shops: Observable<{ value: string; code: string }[]>;
  frStoreManagers: Observable<{ value: string; code: string; email: string; ancestors: string[] }[]>;
  architectType: 'Architetto' | 'Geometra' | 'Ingegnere' = 'Architetto';
  areaManager: string;
  dc: string;
  constructor(
    public dialogRef: MatDialogRef<ModalFrActivationComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { candidato: ICandidate; type: 'active_ctx' | 'active_detail'; disabled?: boolean },
    private candidateService: CandidatesServices,
    private messageService: MessageService,
    private authService: AuthServices
  ) {}
  ngOnInit(): void {
    this.candidato = this.data.candidato;
    const contact = this.candidato?.cache?.contact as IContactIndividual;
    this.type = this.data.type;
    this.disabled = this.data?.disabled || false;
    this.parent = this.candidato?.crmFrData?.registration?.parent;
    this.shop = this.candidato?.crmFrData?.registration?.shop;
    this.username =
      this.candidato?.crmFrData?.registration?.username ||
      contact.firstName.trim().toLowerCase().replace(' ', '') +
        '.' +
        contact.lastName.trim().toLowerCase().replace(' ', '') ||
      null;
    this.workingEmail = this.candidato?.crmFrData?.registration?.workingEmail || null;
    this.frType = this.candidato?.crmFrData?.registration?.type || null;
    this.radius = this.candidato?.crmFrData?.registration?.radius || null;
    this.note = this.candidato?.crmFrData?.registration?.note || null;
    this.shops = this.candidateService.getFrShop$();
    this.frStoreManagers = this.candidateService.getFrSM$();
    this.architectType = this.data?.candidato?.crmFrData?.registration?.typology || 'Architetto';
    this.frAddress = this.data?.candidato?.crmFrData?.registration?.frAddress || null;
    this.note = this.data?.candidato?.crmFrData?.registration?.note || null;
    if (this.type === 'active_detail' && this.candidato?.crmFrData?.crmFrId) {
      this.candidateService
        .getFrUserById$(this.candidato?.crmFrData?.crmFrId)
        .pipe(
          concatAll(),
          tap((v: any) => {
            this.stateOnFr.isAvailable = v.isAvailable;
            this.stateOnFr.busy = v.busy;
          })
        )
        .subscribe();
    }
    if (!this.parent) {
      this.withoutParent();
    } else {
      this.withParent();
    }
  }
  withParent() {
    of(this.parent)
      .pipe(
        switchMap((id) =>
          this.candidateService.getFrSM$().pipe(
            concatAll(),
            filter((sm) => sm.code === id),
            switchMap((sm) => this.candidateService.getFrAnchestors$(sm.ancestors)),
            tap((ar) => {
              this.areaManager = ar?.find((e) => e.value.includes('AM'))?.value
                ? ar?.find((e) => e.value.includes('AM'))?.value
                : '';
              this.dc = ar?.find((e) => e.value.includes('DC'))?.value
                ? ar?.find((e) => e.value.includes('DC'))?.value
                : '';
            })
          )
        )
      )
      .subscribe();
  }
  private withoutParent() {
    this.authService
      .user()
      .pipe(
        switchMap((user) =>
          this.candidateService.getFrSM$().pipe(
            concatAll(),
            filter((sm) => sm.email === user.email),
            tap((sm) => {
              if (sm.email === user.email) {
                this.parent = sm.code;
              }
            }),
            switchMap((sm) => this.candidateService.getFrAnchestors$(sm.ancestors)),
            tap((ar) => {
              this.areaManager = ar?.find((e) => e.value.includes('AM'))?.value
                ? ar?.find((e) => e.value.includes('AM'))?.value
                : '';
              this.dc = ar?.find((e) => e.value.includes('DC'))?.value
                ? ar?.find((e) => e.value.includes('DC'))?.value
                : '';
            })
          )
        )
      )
      .subscribe();
  }
  salva() {
    if (this.type === 'active_ctx') {
      const body: ICandidate = {
        ...this.candidato,
        crmFrData: {
          registration: {
            username: this.username,
            workingEmail: this.workingEmail,
            type: this.frType,
            radius: this.radius,
            shop: this.shop,
            parent: this.parent,
            typology: this.architectType,
            frAddress: this.frAddress,
            note: this.note,
          },
        },
        status: {
          ...this.candidato.status,
          code: ArchitectCodes.SM_DA_ATTIVARE_FR,
        },
      };
      this.candidateService.savePartialFrArchitect(body).subscribe(() => this.onClose());
    } else {
      const body: ICandidate = {
        ...this.candidato,
        crmFrData: {
          registration: {
            username: this.username,
            workingEmail: this.workingEmail,
            type: this.frType,
            radius: this.radius,
            shop: this.shop,
            parent: this.parent,
            typology: this.architectType,
            frAddress: this.frAddress,
            note: this.note,
          },
        },
      };
      this.candidateService.createFrArchitect(body).subscribe({
        next: () => this.onClose(),
        error: (err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Creazione Fr',
            detail: err?.error?.message,
          });
        },
      });
    }
  }
  onClose() {
    this.dialogRef.close({ id: this.candidato._id });
  }
  toogleState($event: boolean) {
    const payload = $event ? { isAvailable: true } : { busy: true };
    this.stateOnFr = {
      isAvailable: $event,
      busy: !$event,
    };
    this.candidateService.setFrArchitectState(this.candidato.crmFrData.crmFrId, payload).subscribe();
  }
}
