import { CandidateType } from '../interfaces';
import { RecruitRoles } from './roles.enum';

enum Common {
  ADMIN_ASSEGNATO = 'ADMIN_ASSEGNATO',
  HR_CONTACT_DUPLICATED = 'HR_CONTACT_DUPLICATED',
}
export enum AdminArchitectCodes {
  ADMIN_ASSEGNATO = 'ADMIN_ASSEGNATO',
  ADMIN_SENIOR_ATTIVATO = 'ADMIN_SENIOR_ATTIVATO',
}
export enum HSArchitectKOCodes {
  HS_PRE_KO_LAVORO = 'HS_PRE_KO_LAVORO',
  HS_PRE_KO_TRASFERIMENTO = 'HS_PRE_KO_TRASFERIMENTO',
  HS_PRE_KO_NON_A_TARGET = 'HS_PRE_KO_NON_A_TARGET',
  HS_POST_KO_NON_PRESENTATO = 'HS_POST_KO_NON_PRESENTATO',
  HS_POST_KO_NON_INTERESSATO = 'HS_POST_KO_NON_INTERESSATO',
  HS_POST_KO_NON_A_TARGET = 'HS_POST_KO_NON_A_TARGET',
}
export enum HSArchitectOKCodes {
  HS_PRE_OK_FOLLOWUP = 'HS_PRE_OK_FOLLOWUP',
  HS_PRE_OK_DA_RICHIAMARE = 'HS_PRE_OK_DA_RICHIAMARE',
  HS_PRE_OK_SOLLECITO_MAIL = 'HS_PRE_OK_SOLLECITO_MAIL',
  HS_OK_PRIMO_COLLOQUIO = 'HS_OK_PRIMO_COLLOQUIO',
  HS_OK_SECONDO_COLLOQUIO = 'HS_OK_SECONDO_COLLOQUIO',
  HS_POST_OK_DA_ASSEGNARE = 'HS_POST_OK_DA_ASSEGNARE',
  HS_POST_OK_AFFIANCAMENTO = 'HS_POST_OK_AFFIANCAMENTO',
}
export enum HRArchitectOKCodes {
  HR_DA_CONFERMARE = 'HR_DA_CONFERMARE',
  HR_DA_RICHIAMARE = 'HR_DA_RICHIAMARE',
  HR_DA_ASSEGNARE = 'HR_DA_ASSEGNARE',
  HR_ASSEGNATO = 'HR_ASSEGNATO',
  HR_INTERESSATO_BP = 'HR_INTERESSATO_BP',
  HR_INTERESSATO_PERCORSO_FORMATIVO = 'HR_INTERESSATO_PERCORSO_FORMATIVO',
}
export enum HRArchitectKOCodes {
  HR_CONTACT_DUPLICATED = 'HR_CONTACT_DUPLICATED',
  HR_KOCV = 'HR_KOCV',
  HR_KO_NO_INTERESSATO = 'HR_KO_NO_INTERESSATO',
  HR_KO_ALTRO = 'HR_KO_ALTRO',
  HR_KO_NO_ABILITATO = 'HR_KO_NO_ABILITATO',
  HR_KO_NO_LAVORO_ESCLUSIVO = 'HR_KO_NO_LAVORO_ESCLUSIVO',
  HR_KO_ETA = 'HR_KO_ETA',
  //HR_KO_NO_IMPRESA = 'HR_KO_NO_IMPRESA',//rimosse è per le impres
}
export enum SMArchitectOKCodes {
  SM_DA_VERIFICARE = 'SM_DA_VERIFICARE',
  SM_DA_RICHIAMARE = 'SM_DA_RICHIAMARE',
  SM_COLLOQUIO_FISSATO = 'SM_COLLOQUIO_FISSATO',
  SM_COLLOQUIO_ESEGUITO_OK = 'SM_COLLOQUIO_ESEGUITO_OK',
  SM_AFFIANCAMENTO_FISSATO = 'SM_AFFIANCAMENTO_FISSATO',
  SM_IN_FORMAZIONE = 'SM_IN_FORMAZIONE',
  SM_AFFIANCAMENTO_OK = 'SM_AFFIANCAMENTO_OK',
  SM_OPERATIVO = 'SM_OPERATIVO',
  SM_DA_ATTIVARE_FR = 'SM_DA_ATTIVARE_FR',
  SM_ATTIVATO_FR = 'SM_ATTIVATO_FR',
}
export enum SMArchitectKOCodes {
  SM_COLLOQUIO_NON_PRESENTATO = 'SM_COLLOQUIO_NON_PRESENTATO',
  SM_COLLOQUIO_RIFISSATO = 'SM_COLLOQUIO_RIFISSATO',
  SM_COLLOQUIO_ESEGUITO_OK = 'SM_COLLOQUIO_ESEGUITO_OK',
  SM_COLLOQUIO_ESEGUITO_KO = 'SM_COLLOQUIO_ESEGUITO_KO',
  SM_COLLOQUIO_RICHIAMATO_KO = 'SM_COLLOQUIO_RICHIAMATO_KO',
  SM_KO_NO_INTERESSATO = 'SM_KO_NO_INTERESSATO',
  SM_AFFIANCAMENTO_NON_PRESENTATO = 'SM_AFFIANCAMENTO_NON_PRESENTATO',
  SM_AFFIANCAMENTO_KO = 'SM_AFFIANCAMENTO_KO',
}

export const SMArchitectCodes = { ...SMArchitectOKCodes, ...SMArchitectKOCodes } as const;
export const HRArchitectCodes = {
  ...HRArchitectOKCodes,
  ...HRArchitectKOCodes,
  ...SMArchitectCodes,
} as const;
export const HSArchitectCodes = {
  ...HSArchitectKOCodes,
  ...HSArchitectOKCodes,
  ...SMArchitectOKCodes,
} as const;
export const ArchitectCodes = {
  ...AdminArchitectCodes,
  ...HSArchitectCodes,
  ...HRArchitectCodes,
  ...SMArchitectCodes,
} as const;

export enum AdminCompanyCodes {
  ADMIN_ASSEGNATO = 'ADMIN_ASSEGNATO',
}

export enum HRCompanyKOCodes {
  HR_CONTACT_DUPLICATED = 'HR_CONTACT_DUPLICATED',
  //HR_KOCV = 'HR_KOCV',//da migrare
  HR_KO_NON_IDONEO = 'HR_KO_NON_IDONEO',
  HR_KO_NO_INTERESSATO = 'HR_KO_NO_INTERESSATO',
  HR_KO_ALTRO = 'HR_KO_ALTRO',
  // HR_KO_NO_ABILITATO = 'HR_KO_NO_ABILITATO',//rimossi perch'è architetti
  // HR_KO_NO_LAVORO_ESCLUSIVO = 'HR_KO_NO_LAVORO_ESCLUSIVO',//rimossi perch'è architetti
  HR_KO_NO_IMPRESA = 'HR_KO_NO_IMPRESA',
  HR_KO_DOCUMENTI = 'HR_KO_DOCUMENTI', //nuovo
}
export enum HRCompanyOKCodes {
  HR_DA_CONFERMARE = 'HR_DA_CONFERMARE',
  HR_DA_RICHIAMARE = 'HR_DA_RICHIAMARE',
  HR_DA_ASSEGNARE = 'HR_DA_ASSEGNARE',
  HR_ASSEGNATO = 'HR_ASSEGNATO',
  SM_PRIMO_COLLOQUIO_SM = 'SM_PRIMO_COLLOQUIO_SM',
  HR_SECONDO_COLLOQUIO_RECRUITER = 'HR_SECONDO_COLLOQUIO_RECRUITER',
  HR_SECONDO_COLLOQUIO_RECRUITER_OK = 'HR_SECONDO_COLLOQUIO_RECRUITER_OK',
  HR_SECONDO_COLLOQUIO_NON_PRESENTATO_KO = 'HR_SECONDO_COLLOQUIO_NON_PRESENTATO_KO',
  HR_RICHIESTA_DOCUMENTI_HR = 'HR_RICHIESTA_DOCUMENTI_HR',
}

export enum SMCompanyOKCodes {
  SM_DA_VERIFICARE = 'SM_DA_VERIFICARE',
  SM_DA_RICHIAMARE = 'SM_DA_RICHIAMARE',
  SM_PRIMO_COLLOQUIO_SM_OK = 'SM_PRIMO_COLLOQUIO_SM_OK',
  // SM_COLLOQUIO_ESEGUITO_OK = 'SM_COLLOQUIO_ESEGUITO_OK',//rimosse
  SM_RICHIESTA_DOCUMENTI_SM = 'SM_RICHIESTA_DOCUMENTI_SM',
  SM_OPERATIVO = 'SM_OPERATIVO',
}

export enum SMCompanyKOCodes {
  SM_COLLOQUIO_RIFISSATO = 'SM_COLLOQUIO_RIFISSATO',
  SM_KO_NON_IDONEO = 'SM_KO_NON_IDONEO', //nuovo
  SM_COLLOQUIO_NON_PRESENTATO = 'SM_COLLOQUIO_NON_PRESENTATO',
  SM_COLLOQUIO_ESEGUITO_KO = 'SM_COLLOQUIO_ESEGUITO_KO',
  SM_COLLOQUIO_RICHIAMATO_KO = 'SM_COLLOQUIO_RICHIAMATO_KO',
  SM_KO_NO_INTERESSATO = 'SM_KO_NO_INTERESSATO',
  SM_KO_PREZZO = 'SM_KO_PREZZO',
  SM_KO_DOCUMENTI = 'SM_KO_DOCUMENTI',
}

export const SMCompanyCodes = { ...SMCompanyOKCodes, ...SMCompanyKOCodes };
export const HRCompanyCodes = { ...HRCompanyOKCodes, ...HRCompanyKOCodes, ...SMCompanyCodes };
export const CompanyCodes = { ...AdminCompanyCodes, ...HRCompanyCodes, ...SMCompanyCodes };

export enum SMWorkerOKCodes {
  SM_DA_VERIFICARE = 'SM_DA_VERIFICARE',
  SM_DA_RICHIAMARE = 'SM_DA_RICHIAMARE',
  SM_COLLOQUIO_FISSATO = 'SM_COLLOQUIO_FISSATO',
  SM_COLLOQUIO_ESEGUITO_OK = 'SM_COLLOQUIO_ESEGUITO_OK',
  SM_OPERATIVO = 'SM_OPERATIVO',
}
export enum SMWorkerKOCodes {
  SM_COLLOQUIO_NON_PRESENTATO = 'SM_COLLOQUIO_NON_PRESENTATO',
  SM_COLLOQUIO_RIFISSATO = 'SM_COLLOQUIO_RIFISSATO',
  SM_COLLOQUIO_ESEGUITO_KO = 'SM_COLLOQUIO_ESEGUITO_KO',
  SM_COLLOQUIO_RICHIAMATO_KO = 'SM_COLLOQUIO_RICHIAMATO_KO',
  SM_KO_NO_INTERESSATO = 'SM_KO_NO_INTERESSATO',
  SM_KO_PREZZO = 'SM_KO_PREZZO',
  SM_RICHIESTA_DOCUMENTI_SM = 'SM_RICHIESTA_DOCUMENTI_SM',
  SM_KO_DOCUMENTI = 'SM_KO_DOCUMENTI',
}

export const SMWorkerCodes = { ...SMWorkerOKCodes, ...SMWorkerKOCodes };

export const WorkerCodes = { ...AdminCompanyCodes, ...HRCompanyCodes, ...SMWorkerCodes };

export const AllStatusCodes = { ...ArchitectCodes, ...CompanyCodes, ...WorkerCodes };

function mapKeys(record: Record<string, string>) {
  return Object.keys(record).map((key) => ({ code: key, label: StatusCodesLabel[key] }));
}

export function getStatusOKCodes(select: RecruitRoles, type: CandidateType) {
  switch (type) {
    case CandidateType.ARCHITECT:
      switch (select) {
        case RecruitRoles.ADMIN:
          return mapKeys({
            ...HRArchitectOKCodes,
            ...HSArchitectOKCodes,
            ...SMArchitectOKCodes,
            ...AdminArchitectCodes,
          });
        case RecruitRoles.HR_OFFICE:
          return mapKeys({
            ...HRArchitectOKCodes,
            ...SMArchitectOKCodes,
            ADMIN_ASSEGNATO: Common.ADMIN_ASSEGNATO,
          });
        case RecruitRoles.HR_SPECIALIST:
          return mapKeys({
            ...HSArchitectOKCodes,
            ...SMArchitectOKCodes,
            ADMIN_ASSEGNATO: Common.ADMIN_ASSEGNATO,
            ADMIN_SENIOR_ATTIVATO: AdminArchitectCodes.ADMIN_SENIOR_ATTIVATO,
          });
        case RecruitRoles.STORE_MANAGER:
          return mapKeys({ ...SMArchitectOKCodes });
        default:
          return mapKeys({
            ...HRArchitectOKCodes,
            ...HSArchitectOKCodes,
            ...SMArchitectOKCodes,
            ...AdminArchitectCodes,
          });
      }
    case CandidateType.COMPANY:
      switch (select) {
        case RecruitRoles.ADMIN:
          return mapKeys({
            ...HRCompanyOKCodes,
            ...SMCompanyOKCodes,
            ADMIN_ASSEGNATO: Common.ADMIN_ASSEGNATO,
          });
        case RecruitRoles.HR_OFFICE:
          return mapKeys({
            ...HRCompanyOKCodes,
            ...SMCompanyOKCodes,
            ADMIN_ASSEGNATO: Common.ADMIN_ASSEGNATO,
          });
        case RecruitRoles.STORE_MANAGER:
          return mapKeys(SMCompanyOKCodes);
        default:
          return mapKeys({ ...HRCompanyOKCodes, ...SMCompanyOKCodes });
      }
    case CandidateType.WORKER:
      switch (select) {
        case RecruitRoles.ADMIN:
          return mapKeys({
            ...HRCompanyOKCodes,
            ...SMWorkerOKCodes,
            ADMIN_ASSEGNATO: Common.ADMIN_ASSEGNATO,
          });
        case RecruitRoles.HR_OFFICE:
          return mapKeys({
            ...HRCompanyOKCodes,
            ...SMWorkerOKCodes,
            ADMIN_ASSEGNATO: Common.ADMIN_ASSEGNATO,
          });
        case RecruitRoles.STORE_MANAGER:
          return mapKeys(SMWorkerOKCodes);
        default:
          return mapKeys({ ...HRCompanyOKCodes, ...SMWorkerOKCodes });
      }
    case CandidateType.TO_SKILL:
      switch (select) {
        case RecruitRoles.ADMIN:
          return mapKeys({ ...HSArchitectOKCodes, ADMIN_ASSEGNATO: Common.ADMIN_ASSEGNATO });
        case RecruitRoles.HR_OFFICE:
          return mapKeys({ ...HSArchitectOKCodes, ADMIN_ASSEGNATO: Common.ADMIN_ASSEGNATO });
        case RecruitRoles.HR_SPECIALIST:
          return mapKeys({
            ...HSArchitectOKCodes,
            ADMIN_SENIOR_ATTIVATO: AdminArchitectCodes.ADMIN_SENIOR_ATTIVATO,
          });
        case RecruitRoles.STORE_MANAGER:
          return mapKeys(HSArchitectOKCodes);
        default:
          return mapKeys({ ...HSArchitectOKCodes, ADMIN_ASSEGNATO: Common.ADMIN_ASSEGNATO });
      }
    default:
      return mapKeys({
        ...HRArchitectOKCodes,
        ...HSArchitectOKCodes,
        ...SMArchitectOKCodes,
        ...HRCompanyOKCodes,
        ...SMCompanyOKCodes,
        ...SMWorkerOKCodes,
        ...AdminArchitectCodes,
      });
  }
}

export function getStatusKOCodes(select: RecruitRoles, type: CandidateType) {
  switch (type) {
    case CandidateType.ARCHITECT:
      switch (select) {
        case RecruitRoles.ADMIN:
          return mapKeys({
            ...HRArchitectKOCodes,
            ...HSArchitectKOCodes,
            ...SMArchitectKOCodes,
          });
        case RecruitRoles.HR_OFFICE:
          return mapKeys({ ...HRArchitectKOCodes, ...SMArchitectKOCodes });
        case RecruitRoles.HR_SPECIALIST:
          return mapKeys({ ...HSArchitectKOCodes });
        case RecruitRoles.STORE_MANAGER:
          return mapKeys({ ...SMArchitectKOCodes });
        default:
          return mapKeys({
            ...HRArchitectKOCodes,
            ...HSArchitectKOCodes,
            ...SMArchitectKOCodes,
          });
      }
    case CandidateType.COMPANY:
      switch (select) {
        case RecruitRoles.ADMIN:
          return mapKeys({ ...HRCompanyKOCodes, ...SMCompanyKOCodes });
        case RecruitRoles.HR_OFFICE:
          return mapKeys({ ...HRCompanyKOCodes, ...SMCompanyKOCodes });
        case RecruitRoles.STORE_MANAGER:
          return mapKeys(SMCompanyKOCodes);
        default:
          return mapKeys({ ...HRCompanyKOCodes, ...SMCompanyKOCodes });
      }
    case CandidateType.WORKER:
      switch (select) {
        case RecruitRoles.ADMIN:
          return mapKeys({ ...HRCompanyKOCodes, ...SMWorkerKOCodes });
        case RecruitRoles.HR_OFFICE:
          return mapKeys({ ...HRCompanyKOCodes, ...SMWorkerKOCodes });
        case RecruitRoles.STORE_MANAGER:
          return mapKeys(SMWorkerKOCodes);
        default:
          return mapKeys({ ...HRCompanyKOCodes, ...SMWorkerKOCodes });
      }
    case CandidateType.TO_SKILL:
      switch (select) {
        case RecruitRoles.ADMIN:
          return mapKeys(HSArchitectKOCodes);
        case RecruitRoles.HR_OFFICE:
          return mapKeys(HSArchitectKOCodes);
        case RecruitRoles.HR_SPECIALIST:
          return mapKeys(HSArchitectKOCodes);
        case RecruitRoles.STORE_MANAGER:
          return mapKeys(HSArchitectKOCodes);
        default:
          return mapKeys(HSArchitectKOCodes);
      }
    default:
      return mapKeys({
        ...HRArchitectKOCodes,
        ...HSArchitectKOCodes,
        ...SMArchitectKOCodes,
        ...HRCompanyKOCodes,
        ...SMCompanyKOCodes,
        ...SMWorkerKOCodes,
      });
  }
}

export function getStatusCodes(select: RecruitRoles, type: CandidateType) {
  switch (type) {
    case CandidateType.ARCHITECT:
      switch (select) {
        case RecruitRoles.ADMIN:
          return mapKeys(ArchitectCodes);
        case RecruitRoles.HR_OFFICE:
          return mapKeys({ ...HRArchitectCodes, ...SMArchitectCodes });
        case RecruitRoles.HR_SPECIALIST:
          return mapKeys(HSArchitectCodes);
        case RecruitRoles.STORE_MANAGER:
          return mapKeys(SMArchitectCodes);
        default:
          return mapKeys(ArchitectCodes);
      }
    case CandidateType.COMPANY:
      switch (select) {
        case RecruitRoles.ADMIN:
          return mapKeys(CompanyCodes);
        case RecruitRoles.HR_OFFICE:
          return mapKeys({ ...HRCompanyCodes, ...SMCompanyCodes });
        case RecruitRoles.STORE_MANAGER:
          return mapKeys(SMCompanyCodes);
        default:
          return mapKeys(CompanyCodes);
      }
    case CandidateType.WORKER:
      switch (select) {
        case RecruitRoles.ADMIN:
          return mapKeys(WorkerCodes);
        case RecruitRoles.HR_OFFICE:
          return mapKeys({ ...HRCompanyCodes, ...SMWorkerCodes });
        case RecruitRoles.STORE_MANAGER:
          return mapKeys(SMWorkerCodes);
        default:
          return mapKeys(WorkerCodes);
      }
    default:
      return mapKeys(AllStatusCodes);
  }
}
export enum StatusCodesLabel {
  ADMIN_ASSEGNATO = 'Assegnato da sistema',
  ADMIN_SENIOR_ATTIVATO = 'Attivo (affiancamento)',
  HS_PRE_OK_FOLLOWUP = 'Follow up',
  HS_PRE_OK_DA_RICHIAMARE = 'Da richiamare',
  HS_PRE_OK_SOLLECITO_MAIL = 'Mail',
  HS_PRE_KO_LAVORO = 'Ko lavoro',
  HS_PRE_KO_TRASFERIMENTO = 'Ko trasferimento',
  HS_PRE_KO_NON_A_TARGET = '(pre) KO non a target',
  HS_OK_PRIMO_COLLOQUIO = 'OK per 1° colloquio',
  HS_OK_SECONDO_COLLOQUIO = 'OK per 2° colloquio',
  HR_SECONDO_COLLOQUIO_RECRUITER = 'Secondo colloquio HR', //company only
  HR_SECONDO_COLLOQUIO_RECRUITER_OK = 'OK Secondo colloquio HR',
  HS_POST_KO_NON_PRESENTATO = 'Non presentato',
  HS_POST_OK_AFFIANCAMENTO = 'Ok affiancamento',
  HS_POST_KO_NON_INTERESSATO = 'Ko non interessato',
  HS_POST_KO_NON_A_TARGET = '(post) Ko non a target',
  HS_POST_OK_DA_ASSEGNARE = 'Da assegnare',
  HR_INTERESSATO_PERCORSO_FORMATIVO = 'Interessato al percorso formativo',
  HR_RICHIESTA_DOCUMENTI_HR = 'Richiesta documenti HR',
  HR_DA_CONFERMARE = 'Da contattare',
  HR_DA_RICHIAMARE = 'Da richiamare',
  HR_DA_ASSEGNARE = 'Da assegnare',
  HR_CONTACT_DUPLICATED = 'Contatto duplicato',
  HR_KOCV = 'KO CV',
  HR_KO_NO_INTERESSATO = 'KO Non interessato',
  HR_KO_ALTRO = 'KO Altro',
  HR_KO_NO_ABILITATO = 'KO No abilitato',
  HR_KO_NO_LAVORO_ESCLUSIVO = 'KO No lavoro esclusivo',
  HR_SECONDO_COLLOQUIO_NON_PRESENTATO_KO = 'Secondo colloquio non presentato',
  HR_KO_ETA = 'KO Età',
  HR_KO_NO_IMPRESA = 'KO No impresa',
  HR_KO_NON_IDONEO = 'KO non idoneo HR',
  HR_KO_DOCUMENTI = 'KO documenti HR',
  HR_ASSEGNATO = 'Assegnato',
  HR_INTERESSATO_BP = 'Interessato BP',
  SM_DA_VERIFICARE = 'in attesa di approvazione',
  SM_DA_RICHIAMARE = 'Da richiamare SM',
  SM_COLLOQUIO_FISSATO = 'Colloquio fissato',
  SM_PRIMO_COLLOQUIO_SM = 'Primo colloquio SM',
  SM_PRIMO_COLLOQUIO_SM_OK = 'OK Primo colloquio SM',
  SM_COLLOQUIO_NON_PRESENTATO = 'Colloquio non presentato',
  SM_COLLOQUIO_RIFISSATO = 'Colloquio rifissato',
  SM_COLLOQUIO_ESEGUITO_OK = 'OK Colloquio eseguito',
  SM_COLLOQUIO_ESEGUITO_KO = 'KO Colloquio eseguito',
  SM_COLLOQUIO_RICHIAMATO_KO = 'Colloquio Richiamato KO',
  SM_KO_NO_INTERESSATO = 'KO Non interessato',
  SM_AFFIANCAMENTO_FISSATO = 'Affiancamento fissato',
  SM_AFFIANCAMENTO_NON_PRESENTATO = 'Affiancamento non presentato',
  SM_IN_FORMAZIONE = 'Affiancamento in corso',
  SM_AFFIANCAMENTO_KO = 'KO Affiancamento eseguito',
  SM_AFFIANCAMENTO_OK = 'Percorso formativo', //'OK Affiancamento eseguito',
  SM_KO_RICHIESTA_DOCUMENTI = 'Richiesta documenti', //vedere se rimuovere
  SM_KO_PREZZO = 'KO Prezzo',
  SM_KO_NON_IDONEO = 'KO non idoneo SM',
  SM_RICHIESTA_DOCUMENTI_SM = 'Richiesta documenti SM',
  SM_KO_DOCUMENTI = 'KO documenti SM',
  SM_OPERATIVO = 'Operativo',
  SM_DA_ATTIVARE_FR = 'Da Attivare',
  SM_ATTIVATO_FR = 'Attivo',
}
