import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CandidateType } from '@renovars/common/recruit';
import { AuthServices } from '@renovars/core-ng';
import { AuthGuard } from '@renovars/fe-core-libs/guards/auth.guard';
import { TemplateAppComponent } from './core/layout/app/template-app.component';
import { AppResolver } from './core/resolver/app.resolver';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard/riepilogo',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () => import('./features/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: '',
    component: TemplateAppComponent,
    canActivate: [AuthGuard],
    resolve: [AppResolver],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./features/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'integrazione',
        loadChildren: () => import('./features/fr-view/fr-view.module').then((m) => m.FrViewModule),
      },
      {
        path: 'contacts',
        loadChildren: () => import('@renovars/core-ng').then((m) => m.ContactModule),
      },
      {
        path: 'candidati/architetti',
        loadChildren: () => import('./features/candidati/candidati.module').then((m) => m.CandidatiModule),
        data: { candidateType: CandidateType.ARCHITECT },
      },
      {
        path: 'candidati/operai',
        loadChildren: () => import('./features/candidati/candidati.module').then((m) => m.CandidatiModule),
        data: { candidateType: CandidateType.WORKER },
      },
      {
        path: 'candidati/imprese',
        loadChildren: () => import('./features/candidati/candidati.module').then((m) => m.CandidatiModule),
        data: { candidateType: CandidateType.COMPANY },
      },
      {
        path: 'candidati/formazione',
        loadChildren: () => import('./features/candidati/candidati.module').then((m) => m.CandidatiModule),
        data: { candidateType: CandidateType.TO_SKILL },
      },
      {
        path: 'network',
        loadChildren: () => import('@renovars/core-ng').then((m) => m.NetworkModule),
      },
      {
        path: 'calendario',
        loadChildren: () =>
          import('./features/calendar-wrapper/calendar-wrapper.module').then((m) => m.CalendarWrapperModule),
      },
      {
        path: 'utenti',
        loadChildren: () => import('./features/recruit-utenti').then((m) => m.RecruitUtentiModule),
      },
      {
        path: 'profile',
        loadChildren: () => import('./features/recruit-profile').then((m) => m.RecruitProfileModule),
      },
      {
        path: 'appuntamento',
        loadChildren: () =>
          import('./features/appointment/appointment-wrap.module').then((m) => m.AppointmentWrapModule),
      },
      {
        path: 'sources',
        loadChildren: () => import('./features/recruit-sources/sources.module').then((m) => m.SourceModule),
      },
      {
        path: 'site',
        loadChildren: () => import('@renovars/core-ng').then((m) => m.SiteModule),
      },
      {
        path: 'file-manager',
        loadChildren: () => import('@renovars/core-ng').then((m) => m.FileManagerModule),
      },
      {
        path: 'assistenza',
        loadChildren: () =>
          import('./features/mail-assistance/mail-assistance.module').then((m) => m.MailAssistanceModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [],
  providers: [AppResolver, AuthGuard, AuthServices],
})
export class AppRoutingModule {}
