/* eslint-disable @typescript-eslint/no-empty-function */
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonRoles } from '@renovars/common';
import {
  AllStatusCodes,
  ArchitectCodes,
  CandidateType,
  RecruitEventType,
  RecruitRoles,
  StatusCodesLabel,
  getStatusCodes,
} from '@renovars/common/recruit';
import {
  AppointmentListAbstractComponent,
  AuthServices,
  ContactsServices,
  EventService,
  EventSharedService,
} from '@renovars/core-ng';
import { DomainValuesService } from '@renovars/fe-core-libs/services/domain-values.service';
import { UsersService } from '@renovars/fe-core-libs/services/users.service';
import { merge } from 'lodash';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ModalTrainingComponent } from '../candidati/components/modal-candidati-stato/modal-training.component';
import { ModalAppointmentMsgComponent } from './modals/modal-appointment-msg/modal-appointment-msg.component';
import { ModalAppointmentStatoComponent } from './modals/modal-appointment-stato.component';
import { ModalReassignAppointmentComponent } from './modals/modal-reassign-appointment/modal-reassign-appointment.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'appointment-recruit-list',
  templateUrl: './appointment-list.component.html',
  providers: [ConfirmationService],
})
export class AppointmentRecruitListComponent extends AppointmentListAbstractComponent {
  types = [CandidateType.ARCHITECT, CandidateType.COMPANY, CandidateType.WORKER].map((v) => ({
    key: v,
    value: v,
  }));
  headerLess = false;
  selectedType: string;
  labels = StatusCodesLabel;
  statusList = getStatusCodes(null, null);
  roles: RecruitRoles[];
  constructor(
    usersServices: UsersService,
    eventService: EventService,
    eventSharedService: EventSharedService,
    contactsService: ContactsServices,
    domainValuesService: DomainValuesService,
    private authService: AuthServices,
    private dialog: MatDialog,
    private router: Router,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {
    super(usersServices, eventService, eventSharedService, contactsService, domainValuesService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.authService.user().subscribe((user) => {
      const baseItem: MenuItem[] = [
        {
          label: 'Modifica',
          icon: 'pi pi-fw pi-pencil',
          command: () => {
            this.gotoEdit();
          },
        },
        {
          label: 'Dettagli',
          icon: 'pi pi-fw pi-search',
          command: () => {
            this.gotoDetail();
          },
        },
        {
          label: 'Sposta',
          icon: 'pi pi-fw pi-undo',
          command: () => {
            this.gotoResult();
          },
        },
      ];
      if (user.roles.includes(CommonRoles.IS_ADMIN)) {
        baseItem.push({
          label: 'Admin',
          icon: 'pi pi-fw pi-shield',
          items: [
            {
              label: 'Cancella Evento',
              icon: 'pi pi-fw pi-trash',
              command: () => {
                this.deleteAction();
              },
            },
            {
              label: 'Cambia Store manager',
              icon: 'pi pi-fw pi-undo',
              command: () => {
                this.reassignSmToEvent();
              },
            },
            {
              label: 'Nota per lo store manager',
              icon: 'pi pi-fw pi-pencil',
              command: () => {
                this.setMsg();
              },
            },
          ],
        });
      }
      this.roles = user.roles as RecruitRoles[];
      this.cmItems = baseItem;
    });
  }
  searchBySm(event) {
    this.paginateRequest.form = {
      userId: event,
    };
    super.search();
  }

  gotoDetail() {
    const selected = this.selected as any;
    if (selected?.candidate?.type) {
      this.router.navigateByUrl(
        `/candidati/${selected?.candidate?.type}/details/main-info/${selected.candidate._id}`
      );
    } else {
      this.router.navigateByUrl(`/candidati/details/main-info/${selected.candidate._id}`);
    }
  }

  gotoEdit() {
    const selected = this.selected as any;
    if (selected?.candidate?.type) {
      this.router.navigateByUrl(
        `/candidati/${selected?.candidate?.type}/upsert/main-info/${selected.candidate._id}`
      );
    } else {
      this.router.navigateByUrl(`/candidati/upsert/main-info/${selected.candidate._id}`);
    }
  }

  deleteAction() {
    const selected = this.selected as any;
    this.confirmationService.confirm({
      message: 'sei sicuro di voler cancellare questo evento?',
      header: 'Conferma',
      icon: 'pi pi-info-circle',
      accept: () => {
        if (!selected && !selected._id) return;
        this.eventService.delete(selected._id).subscribe(() => {
          this.messageService.add({
            severity: 'success',
            summary: 'Evento',
            detail: 'Evento eliminato correttamente',
          });
          this.search();
        });
      },
      reject: () => {},
    });
  }
  reassignSmToEvent() {
    const dialogRef = this.dialog.open(ModalReassignAppointmentComponent, {
      minWidth: '600px',
      data: this.selected,
    });
    dialogRef.afterClosed().subscribe(() => this.search());
  }
  setMsg() {
    const dialogRef = this.dialog.open(ModalAppointmentMsgComponent, {
      minWidth: '600px',
      data: this.selected,
    });
    dialogRef.afterClosed().subscribe(() => this.search());
  }

  gotoResult() {
    const sel = this.selected as any;
    if (sel.type === RecruitEventType.AFFIANCAMENTO) {
      const dialogRef = this.dialog.open(ModalTrainingComponent, {
        data: {
          candidato: sel.candidate,
          eventId: sel._id,
        },
      });
      dialogRef.afterClosed().subscribe(() => this.search());
    } else {
      const dialogRef = this.dialog.open(ModalAppointmentStatoComponent, {
        minWidth: '600px',
        minHeight: '300px',
        data: {
          statusCode: ArchitectCodes.HR_ASSEGNATO,
          candidato: sel.candidate,
          eventFull: sel,
        },
      });
      dialogRef.afterClosed().subscribe(() => this.search());
    }
  }
  onLazyLoad(event: any): void {
    this.paginateRequest.skip = event.first;
    this.paginateRequest.limit = event.rows;
    super.search();
  }
  handleTypeChange() {
    this.paginateRequest = merge(this.paginateRequest, {
      form: {
        candidate: {
          type: this.selectedType,
        },
      },
    });
    super.search();
  }
  onStatusSelect(status: keyof typeof AllStatusCodes) {
    this.paginateRequest.form['status.code'] = status;
    super.search();
  }
  resetFilter() {
    this.paginateRequest = { form: {} };
    super.search();
  }
}
