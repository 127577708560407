import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppointmentRecruitListComponent } from './appointment-list.component';


const appointmentRoutes: Routes = [
  {
    path: '',
    component: AppointmentRecruitListComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(appointmentRoutes)],
  exports: [RouterModule],
})
export class AppointmentWrapRoutingModule { }
