import { Injectable } from '@angular/core';
import { IPaginateResponse } from '@renovars/common';
import { ArchitectCodes, ICandidate, StatusCodesLabel } from '@renovars/common/recruit';
import * as dayjs from 'dayjs';
import * as _ from 'lodash';
import { map, Observable, of, tap } from 'rxjs';
import * as XLSX from 'xlsx';

const jsonToXlsx = (jsonData: any) => {
  const wb = XLSX.utils.book_new();
  const newWs = XLSX.utils.json_to_sheet(jsonData);
  XLSX.utils.book_append_sheet(wb, newWs);
  const rawExcel = XLSX.write(wb, { type: 'array', bookType: 'xlsx' });
  return rawExcel;
};
@Injectable()
export class CsvUtilsService {
  mapCsv<T = any>(listService: Observable<IPaginateResponse<T>>, mapKey: string) {
    return listService.pipe(
      map((res) => res.data),
      map((values) => values.map((v) => this.csvFactory(v, mapKey))),
      tap((mappedValues) => this.downloadFile(mappedValues))
    );
  }
  listToCsv<T = any>(list: T[], mapKey: string) {
    return of(list).pipe(
      map((values) => values.map((v) => this.csvFactory(v, mapKey))),
      tap((mappedValues) => this.downloadFile(mappedValues))
    );
  }
  multiSheet(matrix: Record<string, any[]>) {
    return of(matrix).pipe(tap((values) => this.downloadMultiSheet(values)));
  }
  private downloadFile(data: any) {
    const replacer = (value) => (value === null || value === undefined ? '' : value);
    const header = Object.keys(data[0]);
    const csv = data.map((row) =>
      header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(',')
    );
    csv.unshift(header.join(','));
    const a = document.createElement('a');
    const blob = new Blob([jsonToXlsx(data)], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
    });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = 'candidati.xlsx';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
  private downloadMultiSheet(data: Record<string, any[]>) {
    const a = document.createElement('a');
    const blob = new Blob([this.multToXlsx(data, 'sources')], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
    });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = 'fonti.xlsx';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
  private csvFactory(row, mapKey: string) {
    if (mapKey === 'candidates') {
      return {
        creatoIl: dayjs(row.createdAt).format('DD/MM/YY HH:mm'),
        email: !_.isEmpty(row?.cache?.contact?.emails) ? row?.cache?.contact?.emails[0]?.email : null,
        telefono: !_.isEmpty(row?.cache?.contact?.phones)
          ? row?.cache?.contact?.phones[0]?.phoneNumber
          : null,
        nome: row?.cache?.contact?.firstName || row?.cache?.contact?.businessName,
        cognome: row?.cache?.contact?.lastName,
        dataDiNascita: row?.cache?.contact?.birthDate
          ? dayjs(row?.cache?.contact?.birthDate).format('DD/MM/YYYY')
          : null,
        // sesso: row?.cache?.contact?.gender,
        indirizzo: this.pipeAddress(row),
        fonte: row?.candidateSource,
        tipo: row?.type,
        creatoDa: row?.createdBy?.fullName || row?.createdBy?.email,
        stato: StatusCodesLabel[row?.status?.code],
        assegnatoA: row?.assigned?.fullName || row?.assigned?.email,
        ultimoAppuntamento: row?.lastAppointment ? dayjs(row?.lastAppointment).format('DD/MM/YYYY') : null,
        note: row?.status?.data?.note,
        dataCreazioneFr:
          row?.status?.code == ArchitectCodes.SM_ATTIVATO_FR
            ? dayjs(row.status.createdAt).format('DD/MM/YYYY')
            : null,
        // utmSource: row?.tracking?.utmSource,
        // utmCampaign: row?.tracking?.utmCampaign,
        // utmContent: row?.tracking?.utmContent,
        // utmMedium: row?.tracking?.utmMedium,
      };
    } else if (mapKey === 'sources') {
      return {
        fonte: row.source,
        totale: row.total,
        da_lavorare: row.toWork,
        ko_cv: row.koCv,
        colloquio_fissato: row.appointment,
        colloquio_ok: row.appointmentOk,
        affiancamento: row.partentshipSet,
        affiancamento_ko: row.partentshipKo,
        richiesta_doc: row.docReq,
        doc_ko: row.docKO,
        colloquio_ko: row.appointmentKO,
        operativi: row.operative,
      };
    }
  }
  private pipeAddress(candiate: ICandidate) {
    if (!candiate.addressOp) return null;
    const address = candiate?.addressOp as any;
    const loc = address?.locality;
    const province = address?.province;
    const country = address?.country;
    const postalCode = address?.postalCode;
    return `${loc}, ${province}, ${country}, ${postalCode}`;
  }
  private multToXlsx(jsonData: Record<string, any[]>, key: string) {
    const wb = XLSX.utils.book_new();
    for (const prop in jsonData) {
      const newWs = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_json(
        newWs,
        jsonData[prop]?.map((row) => this.csvFactory(row, key))
      );
      XLSX.utils.book_append_sheet(wb, newWs, prop);
    }
    const rawExcel = XLSX.write(wb, { type: 'array', bookType: 'xlsx' });
    return rawExcel;
  }
}
