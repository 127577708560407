import { Component, OnInit } from '@angular/core';
import type { FrLineGraph } from '@renovars/common';
import dayjs from 'dayjs';
import { forkJoin, tap } from 'rxjs';
import { FrViewService } from './fr-view.service';

type Formula = {
  A: keyof FrLineGraph;
  B: keyof FrLineGraph;
  operation: '+' | '-' | '*' | '/';
};
@Component({
  selector: 'facile-fr-view-page',
  template: `
    <div class="m-2 row">
      <div class="col-12">
        <h1 class="tx-title-2">Architetti in entrata e in uscita</h1>
      </div>
      <div class="col-12">
        <fullstack-datepicker
          [options]="{ label: 'Data di riferimento' }"
          [ngModel]="date"
          (ngModelChange)="rangeChange($event)"
        ></fullstack-datepicker>
        <fullstack-input-select-box
          [options]="{ label: 'Tipologia di Ricerca' }"
          [ngModel]="searchType"
          (ngModelChange)="onSearch($event)"
        >
          <sb-option [value]="'year'">Anno</sb-option>
          <sb-option [value]="'month'">Mese</sb-option>
          <sb-option [value]="'week'">Settimana</sb-option>
        </fullstack-input-select-box>
      </div>
      <p-chart type="line" [data]="lineGraphData" [options]="{ tension: 0.2 }"></p-chart>
    </div>
    <hr size="1" />
    <div class="m-2">
      <h1 class="tx-title-2">Stati degli Architetti {{ viewDate }}</h1>
      <div class="row">
        <div class="col-4">
          <fullstack-input-select-box [(ngModel)]="formula.A" [options]="{ label: 'A' }">
            <sb-option [value]="'numeroArchitettiOperativi'">Operativi</sb-option>
            <sb-option [value]="'numeroArchitettiBusy'">Busy</sb-option>
            <sb-option [value]="'numeroArchitettiDisabilitati'">Disabilitati</sb-option>
            <sb-option [value]="'numeroArchitettiInUscita'">In Uscita</sb-option>
          </fullstack-input-select-box>
        </div>
        <div class="col-4">
          <fullstack-input-select-box [(ngModel)]="formula.operation" [options]="{ label: 'Operazione' }">
            <sb-option [value]="'+'">+</sb-option>
            <sb-option [value]="'-'">-</sb-option>
            <sb-option [value]="'*'">*</sb-option>
            <sb-option [value]="'/'">/</sb-option>
          </fullstack-input-select-box>
        </div>
        <div class="col-4">
          <fullstack-input-select-box [(ngModel)]="formula.B" [options]="{ label: 'B' }">
            <sb-option [value]="'numeroArchitettiOperativi'">Operativi</sb-option>
            <sb-option [value]="'numeroArchitettiBusy'">Busy</sb-option>
            <sb-option [value]="'numeroArchitettiDisabilitati'">Disabilitati</sb-option>
            <sb-option [value]="'numeroArchitettiInUscita'">In Uscita</sb-option>
          </fullstack-input-select-box>
        </div>
      </div>
      <button class="btn btn-warning btn-sm m-2" (click)="process()">Calcola</button>
      <p-chart type="bar" [data]="montlyGraphData"></p-chart>
    </div>
  `,
  styles: [],
})
export class FrViewStateComponent implements OnInit {
  lineGraphData;
  montlyGraphData;
  searchType: 'year' | 'month' | 'week' = 'week';
  date: Date = new Date();
  formula: Formula = {
    A: 'numeroArchitettiOperativi',
    B: 'numeroArchitettiDisabilitati',
    operation: '-',
  };
  viewDate = dayjs().format('MMM-YYYY');
  constructor(private frViewService: FrViewService) {}
  ngOnInit(): void {
    this.loadData();
  }
  private buildLabels(searchType: 'year' | 'month' | 'week', values: FrLineGraph[]) {
    switch (searchType) {
      case 'year':
        return [
          'Gennaio',
          'Febbraio',
          'Marzo',
          'Aprile',
          'Maggio',
          'Giugno',
          'Luglio',
          'Agosto',
          'Settembre',
          'Ottobre',
          'Novembre',
          'Dicembre',
        ];
      case 'month':
        return values.map(
          (v) => `${dayjs(v.date).startOf('week').format('DD')} - ${dayjs(v.date).endOf('week').format('DD')}`
        );
      case 'week':
        return ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'];
    }
  }
  private loadDiffGraph$() {
    return this.frViewService.differenceGraph(this.searchType, this.date).pipe(
      tap((values) => {
        this.lineGraphData = {
          labels: this.buildLabels(this.searchType, values),
          datasets: [
            {
              label: 'Entrata',
              backgroundColor: '#42A5F5',
              data: values.map((v) => v.in),
            },
            {
              label: 'Differenze',
              type: 'line',
              backgroundColor: '#7CFC00',
              data: values.map((v) => v.difference),
            },
            {
              label: 'Uscita',
              backgroundColor: '#FFA726',
              data: values.map((v) => v.out),
            },
          ],
        };
      })
    );
  }
  private selectOp(a: number, b: number, op: string) {
    switch (op) {
      case '+':
        return a + b;
      case '-':
        return a - b;
      case '*':
        return a * b;
      case '/':
        return a / b;
      default:
        return a - b;
    }
  }
  process() {
    this.loadMonthlyGraph$().subscribe();
  }
  private selectKeyLabel(key: keyof FrLineGraph) {
    switch (key) {
      case 'numeroArchitettiOperativi':
        return 'Operativi';
      case 'numeroArchitettiBusy':
        return 'Busy';
      case 'numeroArchitettiDisabilitati':
        return 'Disabilitati';
      case 'numeroArchitettiInUscita':
        return 'In Uscita';
    }
  }
  private labelTitle(formula: Formula) {
    return `${this.selectKeyLabel(formula.A)} ${this.formula.operation} ${this.selectKeyLabel(formula.B)}`;
  }
  private addSets(values: FrLineGraph[]) {
    if (!this.formula.A || !this.formula.B || !this.formula.operation) return null;
    return {
      label: this.labelTitle(this.formula),
      type: 'line',
      backgroundColor: '#7CFC00',
      data: values.map((_, i) =>
        this.processPerc(
          this.selectOp(
            values[i][this.formula.A as string],
            values[i][this.formula.B as string],
            this.formula.operation
          ),
          values[i]?.numeroArchitettiTotali
        )
      ),
    };
  }
  private processPerc(a: number, b: number) {
    if (!a && a == 0) {
      return 0;
    }
    if (!b && b == 0) {
      return 0;
    }
    return Number((a / b) * 100).toFixed(2);
  }
  private loadMonthlyGraph$() {
    return this.frViewService.monthlyGraph(this.date).pipe(
      tap((values) => {
        this.montlyGraphData = {
          labels: values.map(
            (v) =>
              `${dayjs(v.date).startOf('week').format('DD')} - ${dayjs(v.date).endOf('week').format('DD')}`
          ),
          datasets: [
            {
              label: 'Operativo',
              backgroundColor: '#3ec254',
              data: values.map((v) =>
                this.processPerc(v.numeroArchitettiOperativi, v.numeroArchitettiTotali)
              ),
            },
            {
              label: 'Busy',
              backgroundColor: '#ebb463',
              data: values.map((v) => this.processPerc(v.numeroArchitettiBusy, v.numeroArchitettiTotali)),
            },
            {
              label: 'Uscita',
              backgroundColor: '#eb7063',
              data: values.map((v) => this.processPerc(v.numeroArchitettiInUscita, v.numeroArchitettiTotali)),
            },
            {
              label: 'Disabilitato',
              backgroundColor: '#a963eb',
              data: values.map((v) =>
                this.processPerc(v.numeroArchitettiDisabilitati, v.numeroArchitettiTotali)
              ),
            },
            this.addSets(values),
          ],
        };
      })
    );
  }
  rangeChange(event: Date) {
    this.date = event;
    this.viewDate = dayjs(event).format('MMM-YYYY');
    this.loadData();
  }
  onSearch(event: 'year' | 'month' | 'week') {
    if (!event) return;
    this.searchType = event;
    this.loadDiffGraph$().subscribe();
  }
  loadData() {
    forkJoin([this.loadDiffGraph$(), this.loadMonthlyGraph$()]).subscribe();
  }
}
