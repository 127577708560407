import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FrViewRoutingModule } from './fr-view-routing.module';
import { FrViewStateComponent } from './fr-view-state.component';
import { SharedModule } from '../../shared/shared.module';
import { ChartModule } from 'primeng/chart';
import { CandidatiModule } from '../candidati/candidati.module';
import { FrViewPageComponent } from './fr-view-page.component';
import { FrViewTurnoverComponent } from './fr-view-turnover.component';
import { TurnoverPipe } from './turnover.pipe';
import { FrViewTurnoverImpreseComponent } from './fr-view-turnover-imprese.component';

@NgModule({
  declarations: [
    FrViewStateComponent,
    FrViewPageComponent,
    FrViewTurnoverComponent,
    TurnoverPipe,
    FrViewTurnoverImpreseComponent,
  ],
  imports: [CommonModule, SharedModule, FrViewRoutingModule, ChartModule, CandidatiModule],
})
export class FrViewModule {}
