import { RecruitRoles, RecruitRolesLabels } from '@renovars/common/recruit';
import { APPLICATION_ID, IUser } from '../interfaces';

export const getIUserFullName = (user: IUser) => {
  let str = '';
  if (user.firstName) str += user.firstName;
  if (user.lastName) str += ' ' + user.lastName;
  return str;
};

export const getRecruitSMType = (user: IUser) => {
  const recruit = user.registrations.find((reg) => (reg.applicationId = APPLICATION_ID.RECRUIT));
  if (!recruit || !recruit.roles || recruit.roles.length === 0) return '';
  return recruit.roles.includes(RecruitRoles.WORKER_MANAGER)
    ? RecruitRolesLabels.is_worker_manager
    : RecruitRolesLabels.is_store_manager;
};
