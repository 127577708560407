import { IUser, IContactIndividual, getRecruitSMType, IContactCompany, IUserMeta } from '@renovars/common';
import { ICandidate, IRecruitEvent } from '@renovars/common/recruit';
import dayjs from 'dayjs';

export function buildIndividualMailText(
  candidate: ICandidate,
  sm: IUser,
  meta: IUserMeta,
  event: Partial<IRecruitEvent>
) {
  const contact: IContactIndividual = candidate?.cache?.contact;
  const candidateFullName = contact?.firstName + ' ' + contact?.lastName;
  return (
    `Gent.mo/ma ${candidateFullName},\n` +
    `come da accordi telefonici intercorsi,\n` +
    `Le confermiamo l'appuntamento per il colloquio conoscitivo,` +
    `con il nostro ${getRecruitSMType(sm)} ${sm?.firstName} ${sm?.lastName}, ` +
    `presso la sede sita in ${meta?.address?.formatted_address} il giorno ${
      event.start ? dayjs(event?.start).format('DD-MM-YYYY') : ''
    } alle ore ${event?.start ? dayjs(event?.start).format('HH:mm') : ''}.\n` +
    `Il recapito dello ${getRecruitSMType(sm)} è il ${sm?.mobilePhone}.\n` +
    `Cordialmente,\n` +
    `Facile Ristrutturare\n`
  );
}
export function buildCompanyMailText(
  candidate: ICandidate,
  sm: IUser,
  meta: IUserMeta,
  event: Partial<IRecruitEvent>
) {
  const contact: IContactCompany = candidate?.cache?.contact;
  const candidateFullName = contact?.businessName;
  return (
    `Gent.mo/ma ${candidateFullName},\n` +
    `come da accordi telefonici intercorsi,\n` +
    `Le confermiamo l'appuntamento per il colloquio conoscitivo,` +
    `con il nostro ${getRecruitSMType(sm)} ${sm?.firstName} ${sm?.lastName}, ` +
    `presso la sede sita in ${meta?.address?.formatted_address} il giorno ${
      event.start ? dayjs(event?.start).format('DD-MM-YYYY') : ''
    } alle ore ${dayjs(event?.start).format('HH:mm')}.\n` +
    `Il recapito dello ${getRecruitSMType(sm)} è il ${sm?.mobilePhone}.\n` +
    `Cordialmente,\n` +
    `Facile Ristrutturare\n`
  );
}
