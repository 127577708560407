import { Component, OnDestroy, OnInit } from '@angular/core';
import { IUser } from '@renovars/common';
import { isStoreManager, RecruitRoles } from '@renovars/common/recruit';
import { AuthServices } from '@renovars/core-ng';
import { Observable, Subscription } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ServerEventService } from '../../services/server-event.service';

@Component({
  selector: 'facile-template-app',
  template: `
    <fullstack-page-layout [registration]="(user$ | async)?.registrations">
      <div sidebar>
        <fullstack-sidebar-menu
          [options]="{
            logoUrl: 'assets/img/logo.png',
            logoMinUrl: 'assets/img/logo-min.png'
          }"
          [user]="name"
          [userId]="userId"
          (logout)="logout()"
        >
          <sidebar-item
            [label]="'Dashboard'"
            [url]="'./dashboard'"
            [icon]="'fas fa-chart-pie'"
          ></sidebar-item>
          <sidebar-item
            [label]="'Turnover'"
            [url]="'./integrazione'"
            [icon]="'fas fa-chart-pie'"
          ></sidebar-item>
          <!-- <sidebar-item [label]="'Anagrafica'" [url]="'./contacts'" [icon]="'fas fa-address-book'">
          </sidebar-item> -->
          <sidebar-item
            *ngIf="availableCandidateType.architetti"
            [label]="'Architetti'"
            [url]="'./candidati/architetti'"
            [icon]="'fas fa-pencil-ruler'"
          ></sidebar-item>
          <sidebar-item
            *ngIf="specialist"
            [label]="'Formazione'"
            [url]="'./candidati/formazione'"
            [icon]="'fas fa-graduation-cap'"
          ></sidebar-item>
          <sidebar-item
            *ngIf="availableCandidateType.imprese"
            [label]="'Imprese'"
            [url]="'./candidati/imprese'"
            [icon]="'fas fa-warehouse'"
          ></sidebar-item>
          <sidebar-item
            *ngIf="availableCandidateType.operai"
            [label]="'Operai'"
            [url]="'./candidati/operai'"
            [icon]="'fas fa-hard-hat'"
          ></sidebar-item>
          <sidebar-item
            [label]="'Calendario'"
            [url]="'./calendario'"
            [icon]="'fas fa-calendar'"
          ></sidebar-item>
          <sidebar-item
            *ngIf="canManageItem"
            [label]="'Network'"
            [url]="'./network'"
            [icon]="'fas fa-network-wired'"
          ></sidebar-item>
          <sidebar-item
            *ngIf="canManageItem"
            [label]="'Utenti'"
            [url]="'./utenti'"
            [icon]="'fas fa-user'"
          ></sidebar-item>
          <sidebar-item
            [label]="'Appuntamenti'"
            [url]="'./appuntamento'"
            [icon]="'fas fa-calendar-check'"
          ></sidebar-item>
          <sidebar-item
            *ngIf="admin"
            [label]="'Fonti'"
            [url]="'./sources'"
            [icon]="'fas fa-ad'"
          ></sidebar-item>
          <sidebar-item *ngIf="admin" [label]="'Sedi'" [url]="'./site'" [icon]="'fas fa-map'"></sidebar-item>
          <sidebar-item
            [label]="'File manager'"
            [url]="'./file-manager'"
            [icon]="'fas fa-file'"
          ></sidebar-item>
          <sidebar-item [label]="'Assistenza'" [url]="'assistenza'">
            <sidebar-item [label]="'Contatta un admin'" [url]="'mail'"></sidebar-item>
            <!-- <sidebar-item
              [label]="'Renovars'"
              [url]="'https://assistenza.renovars.com/'"
              [externalLink]="true"
            ></sidebar-item> -->
          </sidebar-item>
        </fullstack-sidebar-menu>
      </div>
      <div content>
        <router-outlet></router-outlet>
      </div>
    </fullstack-page-layout>
  `,
})
export class TemplateAppComponent implements OnInit, OnDestroy {
  user$: Observable<IUser>;
  environment = environment;
  menuItems = [];
  storeManager = false;
  hrManager = false;
  admin = false;
  specialist = false;
  availableCandidateType = {
    imprese: false,
    architetti: false,
    operai: false,
  };
  name = '';
  userId: string;
  canManageItem = false;
  subs: Subscription[] = [];
  constructor(private authService: AuthServices, private sseService: ServerEventService) {}
  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  ngOnInit(): void {
    this.subs.push(this.sseService.subToServerEvents$().subscribe());
    this.user$ = this.authService.user().pipe(
      first((user) => !!user),
      switchMap((u) =>
        this.authService
          .userRegistrations(u.sub)
          .pipe(map((value) => ({ ...u, registrations: value.registrations })))
      ),
      switchMap((user) => {
        return this.authService.userMeta().pipe(
          map((userMeta) => ({
            user,
            userMeta,
          }))
        );
      }),
      map((res) => {
        const roles = res.user.roles;
        this.storeManager = isStoreManager(roles);
        this.hrManager = roles.indexOf(RecruitRoles.HR_OFFICE) !== -1;
        this.admin = roles.indexOf(RecruitRoles.ADMIN) !== -1;
        this.specialist =
          roles.indexOf(RecruitRoles.HR_SPECIALIST) !== -1 || roles.indexOf(RecruitRoles.ADMIN) !== -1;
        if (
          roles.includes(RecruitRoles.ADMIN) ||
          roles.includes(RecruitRoles.HR_OFFICE) ||
          roles.includes(RecruitRoles.AREA_MANAGER)
        ) {
          this.canManageItem = true;
        }

        this.name = `${res.user.given_name} ${res.user.family_name}`;
        this.userId = res.user.sub;
        this.availableCandidateType = {
          imprese:
            this.storeManager || this.hrManager ? !!res.userMeta?.candidateType.includes('imprese') : true,
          operai:
            this.storeManager || this.hrManager ? !!res.userMeta?.candidateType.includes('operai') : true,
          architetti:
            this.storeManager || this.hrManager ? !!res.userMeta?.candidateType.includes('architetti') : true,
        };

        return res.user;
      })
    );
  }

  logout() {
    this.authService.logout(environment.applicationId);
  }
}
