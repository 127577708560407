import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';
import { CandidateType, CandidateTypeSector } from '@renovars/common/recruit';
import { setFieldsProperties } from '@renovars/core-ng';
import { RecruitSourceService } from 'apps/recruit-ng/src/app/core/services/recruit-source.service';
import { environment } from 'apps/recruit-ng/src/environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'facile-candidati-impresa-anag-info-form',
  template: `
    <form [formGroup]="form" novalidate>
      <fullstack-contact-form
        formControlName="contact"
        [contactType]="'company'"
        [formInfoState]="form.get('contact').errors"
        [sources]="sources"
        [options]="{
          showSources: false,
          showCompany: true,
          showBillingAddress: false,
          showPhoneType: true,
          showMailType: true
        }"
      >
      </fullstack-contact-form>
    </form>
  `,
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CandidatiImpresaAnagInfoFormComponent),
      multi: true,
    },
  ],
})
export class CandidatiImpresaAnagInfoFormComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() set formInfoState(formInfoState) {
    this._formInfoState = formInfoState;
    setFieldsProperties(this._formInfoState, this.form);
  }

  get formInfoState() {
    return this._formInfoState;
  }
  sources: string[];
  subs: Subscription[] = [];
  @Input() withAttach = true;
  @Input() withImage = true;
  @Input() user = null;
  @Input() candidateType: CandidateType;

  CandidateType = CandidateType;
  CandidateTypeSector = CandidateTypeSector;
  environment = environment;
  _formInfoState: ValidationErrors = [];

  form = this.fb.group({
    contact: new UntypedFormControl(null),
    type: new UntypedFormControl(null),
    sector: new UntypedFormControl(null),
    addressOp: new UntypedFormControl(null),
    durc: new UntypedFormControl(null),
    image: new UntypedFormControl(null),
    visura: new UntypedFormControl(null),
    note: new UntypedFormControl(null),
  });

  propagateChange: any = () => {};

  constructor(private fb: UntypedFormBuilder, private sourceService: RecruitSourceService) {}

  ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }

  ngOnInit(): void {
    this.form.valueChanges.subscribe((val) => {
      this.propagateChange({ ...val, type: this.candidateType });
    });
    this.subs.push(this.sourceService.getSourcesAll$().subscribe((s) => (this.sources = s)));
  }

  resetFormValue() {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).setValue(null);
    });
  }

  /* Metodi per ReactiveForm */
  writeValue(value: any) {
    if (value) {
      this.form.patchValue(value);
    } else {
      this.resetFormValue();
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  setDisabledState(isDisabled: boolean) {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).disable();
    });
  }

  registerOnTouched(fn: () => void): void {}
}
