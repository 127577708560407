import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'facile-fr-view-page',
  template: `
    <fullstack-header></fullstack-header>
    <ng-template header-body>
      <h1 class="tx-title-1">Integrazione FR</h1>
    </ng-template>
    <div class="pt-2 border-bottom" style="background-color: white;">
      <fullstack-tabs>
        <tab [label]="'Stati Fr'" [url]="'./stati'" *permissions="['is_admin']"></tab>
        <tab [label]="'Turnover Architetti'" [url]="'./turnover/architetti'"></tab>
        <tab [label]="'Turnover Imprese'" [url]="'./turnover/imprese'"></tab>
      </fullstack-tabs>
    </div>
    <router-outlet></router-outlet>
  `,
  styles: [],
})
export class FrViewPageComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {
    this.router.navigate(['integrazione/turnover/architetti']);
  }
}
