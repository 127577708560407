<h2 mat-dialog-title>Scrivi una nota per lo store manager</h2>
<div mat-dialog-content>
  <div class="row">
    <div class="col">
      <fullstack-input-box [options]="{ label: 'Note' }" [formControl]="msg"></fullstack-input-box>
    </div>
  </div>
  <div class="text-end">
    <button type="button" class="btn btn-default rounded m-1" (click)="dialogRef.close()">Annulla</button>
    <button type="submit" class="btn btn-secondary rounded m-1" (click)="save()">Salva</button>
  </div>
</div>
