import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IUser } from '@renovars/common';
import { IRecruitEvent } from '@renovars/common/recruit';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { environment } from 'apps/recruit-ng/src/environments/environment';
import dayjs from 'dayjs';
import { MessageService } from 'primeng/api';
import { tap } from 'rxjs';

@Injectable()
export class ReassignEventService {
  constructor(private http: HttpClient, private messageService: MessageService) {}
  getAllAvaibleSm(start: Date, end: Date) {
    return this.http.post<IUser[]>(`${environment.apiGatewayURL}/recruit/reassign`, { start, end });
  }
  reassignEvent(currentEvent: IRecruitEvent, newStoreManagerId: string, smName: string) {
    return this.http.post(`${environment.apiGatewayURL}/recruit/reassign/${newStoreManagerId}`, {
      event: currentEvent,
      name: smName,
    });
  }
  setAdmingMsg(currentEvent: IRecruitEvent, msg: string) {
    return this.http.post(`${environment.apiGatewayURL}/recruit/reassign/msg/${currentEvent._id}`, {
      to: (<IUser>currentEvent.user).id,
      msg: msg,
    });
  }
  checkUserBusy(start: Date, newUser: IUser) {
    return this.http
      .get<IRecruitEvent>(`${environment.apiGatewayURL}/recruit/reassign/isBusy/${newUser.id}?start=${start}`)
      .pipe(
        tap((res) => {
          if (res) {
            this.messageService.add({
              sticky: true,
              severity: 'warn',
              summary: 'Evento',
              detail: `Utente ${newUser.firstName} ${newUser.lastName} ha già un appuntamento
          il ${dayjs(res.start).format('DD/MM/YYYY HH:mm')} procedendo creerai una sovrapposizione
          `,
            });
          }
        })
      );
  }
}
