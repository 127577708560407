import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IUser } from '@renovars/common';
import { ICandidate, RecruitRoles } from '@renovars/common/recruit';
import { CandidatesServices } from 'apps/recruit-ng/src/app/core/services/candidates.services';
import { UserService } from 'apps/recruit-ng/src/app/core/services/user.service';
import { Observable, tap } from 'rxjs';

@Component({
  selector: 'facile-modal-assing-user',
  template: `
    <h2 mat-dialog-title>Assegna Hr/Sm</h2>
    <div mat-dialog-content>
      <div class="row">
        <div class="col-6">
          <fullstack-input-select-box [options]="{ label: 'Assegna Hr' }" [(ngModel)]="assignedHR">
            <sb-option [key]="'id'" [value]="i" *ngFor="let i of selectableHr$ | async">
              {{ i.fullName || i.email }}
            </sb-option>
          </fullstack-input-select-box>
        </div>
        <div class="col-6">
          <fullstack-input-select-box [options]="{ label: 'Assegna SM' }" [(ngModel)]="assigned">
            <sb-option [key]="'id'" [value]="i" *ngFor="let i of selectableSM$ | async">
              {{ i.fullName || i.email }}
            </sb-option>
          </fullstack-input-select-box>
        </div>
      </div>
    </div>
    <div mat-dialog-actions align="end">
      <button mat-button (click)="undo()" class="btn btn-default rounded me-3">Annulla</button>
      <button pButton (click)="save()" class="btn btn-warning rounded" [disabled]="!assignedHR || !assigned">
        Salva
      </button>
    </div>
  `,
  styles: [
    `
      .mat-dialog-content {
        overflow: visible;
      }
    `,
  ],
})
export class ModalAssingUserComponent implements OnInit {
  assignedHR: IUser = null;
  assigned: IUser = null;
  selectableHr$: Observable<IUser[]>;
  selectableSM$: Observable<IUser[]>;
  constructor(
    public dialogRef: MatDialogRef<ModalAssingUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { assigned: string; assignedHR: string; candidateId: string },
    private usersService: UserService,
    private candidateService: CandidatesServices
  ) {}

  ngOnInit(): void {
    this.selectableHr$ = this.usersService.getUsersByRole(RecruitRoles.HR_OFFICE).pipe(
      tap((users) => {
        this.assignedHR = users.find((u) => u.id === this.data.assignedHR);
      })
    );
    this.selectableSM$ = this.usersService.getUsersByRole(RecruitRoles.STORE_MANAGER).pipe(
      tap((users) => {
        this.assigned = users.find((u) => u.id === this.data.assigned);
      })
    );
  }
  setHr(user: IUser) {
    if (!user) return;
    this.assignedHR = user;
  }
  setSm(user: IUser) {
    if (!user) return;
    this.assigned = user;
  }
  undo() {
    this.dialogRef.close();
  }
  save() {
    if (this.assigned) {
      this.candidateService
        .setCandidateStoreManager(this.data.candidateId, this.assigned)
        .subscribe(() => this.dialogRef.close());
    }
    if (this.assignedHR) {
      this.candidateService
        .setCandidateHr(this.data.candidateId, this.assignedHR)
        .subscribe(() => this.dialogRef.close());
    }
  }
}
