import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CandidateType } from '@renovars/common/recruit';
import { CandidatesServices } from 'apps/recruit-ng/src/app/core/services/candidates.services';
import { filter } from 'rxjs/operators';
import { addressOpValidator } from '../../validators/candidati-form.validator';

@Component({
  selector: 'facile-candidati-main-info',
  template: `
    <form [formGroup]="form" novalidate>
      <div class="content">
        <div
          *ngIf="
            candidateType === CandidateType.WORKER ||
            candidateType === CandidateType.ARCHITECT ||
            candidateType === CandidateType.TO_SKILL
          "
        >
          <facile-candidati-main-info-form
            formControlName="candidato"
            [candidateType]="candidateType"
            [formInfoState]="form.get('candidato').errors"
          >
          </facile-candidati-main-info-form>
        </div>
        <div *ngIf="candidateType === CandidateType.COMPANY">
          <facile-candidati-impresa-main-info-form
            formControlName="candidato"
            [candidateType]="candidateType"
            [formInfoState]="form.get('candidato').errors"
          >
          </facile-candidati-impresa-main-info-form>
        </div>

        <div class="row mt-4">
          <div class="col-sm-12 text-end">
            <button
              type="button"
              class="btn btn-primary rounded me-4"
              [routerLink]="['/candidati/' + candidateType]"
            >
              Annulla
            </button>
            <button type="button" class="btn btn-secondary rounded" (click)="save()" [disabled]="!form.valid">
              Salva
            </button>
          </div>
        </div>
      </div>
    </form>
  `,
})
export class CandidatiMainInfoComponent implements OnInit {
  form = this.fb.group({
    candidato: new UntypedFormControl(null, [addressOpValidator]),
  });

  id: string = null;
  candidateType: CandidateType;
  CandidateType = CandidateType;
  candidateTypeLabel: string;

  constructor(
    private fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private candidateService: CandidatesServices
  ) {}

  ngOnInit(): void {
    if (this.activatedRoute.parent) {
      this.activatedRoute.parent.data.subscribe((res) => {
        this.candidateType = res.candidateType as CandidateType;
        this.candidateTypeLabel = CandidateType.toLabel(this.candidateType);
      });
    }

    this.activatedRoute.paramMap.subscribe((params) => {
      this.id = params.get('id');
      if (this.id) {
        this.candidateService
          .getCandidate(this.id)
          .pipe(filter((candidate) => candidate !== null))
          .subscribe((candidate) => this.form.patchValue({ candidato: candidate }));
      }
    });
  }

  save() {
    if (this.id) {
      this.candidateService.updateCandidate(this.id, this.form.get('candidato').value).subscribe();
    } else {
      this.candidateService.saveCandidate(this.form.get('candidato').value).subscribe();
    }
  }
}
