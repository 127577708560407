<div mat-dialog-content>
  <form [formGroup]="formAppointment" (submit)="save()">
    <div class="row">
      <div class="col-12 mt-1">
        <fullstack-input-address-box [options]="{label : 'Indirizzo'}" formControlName="address">
        </fullstack-input-address-box>
      </div>

      <div class="col-6 mt-1">
        <fullstack-daterangepicker
          [options]="{ label: 'Range' }"
          (rangeChange)="range = $event; checkEventAvailability()"
        >
        </fullstack-daterangepicker>
      </div>

      <div class="col-6 mt-1">
        <fullstack-input-select-box [options]="{ label: 'Store manager' }" [formControl]="slot">
          <ng-container *ngFor="let s of slotList">
            <sb-option [value]="{account : a, slot: s}" *ngFor="let a of s.accounts">
              {{ s.start | date: 'short'}} - {{a.fullname }}
            </sb-option>
          </ng-container>
        </fullstack-input-select-box>
      </div>

      <div class="col-12 mt-1">
        <fullstack-input-textarea [options]="{ label: 'Note', rows: 2 }" formControlName="note">
        </fullstack-input-textarea>
      </div>

      <div class="col-12 mt-1" *ngIf="slot.value">
        <fullstack-input-check-box
          [options]="{ label: 'Vuoi inviare l\'email?' }"
          formControlName="sendMail"
        ></fullstack-input-check-box>
      </div>

      <div class="col-12 mt-1" *ngIf="slot.value && formAppointment.controls?.sendMail.value">
        <fullstack-input-check-box
          [options]="{ label: 'Vuoi Sostituire il corpo della mail?' }"
          formControlName="mailVisible"
        ></fullstack-input-check-box>
      </div>

      <div class="col-12 mt-1" *ngIf="slot.value && formAppointment.controls?.mailVisible.value">
        <fullstack-input-textarea
          [options]="{ label: 'Testo opzionale', rows: 4 }"
          formControlName="mailText"
        >
        </fullstack-input-textarea>
      </div>
    </div>

    <div class="text-end">
      <button type="button" class="btn btn-default rounded m-1" (click)="closeModal.emit()">Annulla</button>

      <button [disabled]="!formAppointment.valid" type="submit" class="btn btn-warning rounded m-1">
        Crea
      </button>
    </div>
  </form>
</div>
