import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import type { AvailabilityResponseBody, IEvent } from '@renovars/common';
import { isCompany } from '@renovars/common';
import { ICandidate, IRecruitEvent, RecruitEventType, RecruitRoles } from '@renovars/common/recruit';
import { AuthServices, EventService } from '@renovars/core-ng';
import { IFormBuilder, IFormGroup } from '@rxweb/types';
import * as dayjs from 'dayjs';
import * as _ from 'lodash';
import { MessageService } from 'primeng/api';
import { filter, map, switchMap } from 'rxjs/operators';
import { RecuitUsersServices } from '../../../core/services/recuit-users-services.service';

@Component({
  selector: 'facile-modal-edit-stato',
  templateUrl: './modal-edit-stato.component.html',
})
export class ModalEditStatoComponent implements OnInit {
  @Output() closeModal = new EventEmitter();
  @Input() eventFull: IRecruitEvent;
  candidato: ICandidate;
  fb: IFormBuilder = new UntypedFormBuilder();
  formAppointment: IFormGroup<Partial<IRecruitEvent>>;
  eventPlaceholder: string;
  slotList: AvailabilityResponseBody[];
  range: { start: Date; end: Date };
  slot: UntypedFormControl;
  mailText: UntypedFormControl;
  constructor(
    private eventService: EventService,
    private messageService: MessageService,
    private userMeta: RecuitUsersServices,
    private authService: AuthServices
  ) {}

  ngOnInit(): void {
    this.candidato = this.eventFull.cache.candidate as ICandidate;
    this.formAppointment = this.fb.group<Partial<IRecruitEvent>>({
      contact: new UntypedFormControl(this.candidato.contact, []),
      candidate: new UntypedFormControl(this.candidato._id, []),
      start: new UntypedFormControl('', []),
      end: new UntypedFormControl('', []),
      address: new UntypedFormControl(this.candidato.addressOp || '', []),
      user: new UntypedFormControl('', []),
      note: new UntypedFormControl('', []),
      mailVisible: new UntypedFormControl(false, []),
    });
    this.eventPlaceholder =
      new Date(this.eventFull.start).toLocaleDateString() +
      ' - ' +
      new Date(this.eventFull.end).toLocaleDateString();
    this.slot = new UntypedFormControl('', []);
    this.mailText = new UntypedFormControl('', []);
    this.formAppointment.valueChanges
      .pipe(
        filter((e) => !!e.mailVisible),
        switchMap((rEvent) => {
          const { account } = this.slot.value;
          return this.userMeta.getUserMeta(account.id).pipe(map((userM) => ({ userM, rEvent })));
        })
      )
      .subscribe((data) => {
        if (data.rEvent.mailVisible) {
          if (this.slot.value) {
            const { account, slot } = this.slot.value;
            const additionaData = {
              ...data,
              account,
              time: slot.start,
              smAddress: data?.userM?.address?.formatted_address,
            };
            const value = this.createMailText(this.candidato, additionaData);
            this.mailText.setValue(value);
          }
        }
      });
  }
  createMailText(candidate: ICandidate, eventInfo: any) {
    const candidateContact = candidate.cache.contact;
    const candidateName = isCompany(candidateContact)
      ? candidateContact.businessName
      : candidateContact.firstName + ' ' + candidateContact.lastName;
    const address = eventInfo.smAddress;
    const startDate = eventInfo?.time ? dayjs(eventInfo.time).format('DD/MM/YYYY') : null;
    const startTime = eventInfo?.time ? dayjs(eventInfo.time).format('HH:mm') : null;
    const smName = eventInfo?.account?.fullname ? eventInfo.account.fullname : null;
    const smPhone = eventInfo?.account?.phone ? eventInfo.account.phone : '<Nessun numero Trovato>';
    const TEXT =
      `Gent.mo ${candidateName},\n` +
      `come da accordi telefonici intercorsi, ` +
      `Le confermiamo l'appuntamento per il colloquio conoscitivo, ` +
      `con il nostro Store Manager ${smName}, ` +
      `presso la sede sita in ${address} il giorno ${startDate} alle ore ${startTime}.\n` +
      `Il recapito dello Store Manager è il ${smPhone}.\n` +
      `Cordialmente,\n` +
      `Facile Ristrutturare`;
    return TEXT;
  }

  checkEventAvailability() {
    const eventForm = _.cloneDeep(this.formAppointment.value);

    if (eventForm.address && this.range && this.range.start && this.range.end) {
      const request: Partial<IEvent> = {
        start: this.range.start,
        end: this.range.end,
        address: eventForm.address,
      };

      this.eventService
        .checkEventAvailability(request)
        .pipe(
          switchMap((slotList) =>
            this.authService.user().pipe(
              map((user) => ({
                currentRoles: user.roles,
                slotList: slotList,
              }))
            )
          ),
          map(({ currentRoles, slotList }) => {
            //HR and admin can reassign to different SM
            if (currentRoles.includes(RecruitRoles.HR_OFFICE) || currentRoles.includes(RecruitRoles.ADMIN)) {
              return slotList;
            } else {
              // other roles can assign only self
              const userId =
                typeof this.eventFull.user === 'string' ? this.eventFull.user : this.eventFull.user.id;
              return slotList.filter((slot) => slot?.accounts[0]?.id === userId);
            }
          })
        )
        .subscribe((res) => {
          if (!res || res.length === 0) {
            this.messageService.add({
              severity: 'warn',
              summary: 'Candidato',
              detail: 'Nessuna Disponibilità trovata',
            });
          }
          this.slotList = res;
        });
    }
  }
  save() {
    if (this.formAppointment.valid && this.slot.valid) {
      const eventForm = _.cloneDeep(this.formAppointment.value);
      const slot: { account; slot } = this.slot.value;
      eventForm.start = new Date(slot.slot.start);
      eventForm.end = new Date(slot.slot.end);
      eventForm.user = slot.account.id;
      eventForm.type = RecruitEventType.APPUNTAMENTO;
      eventForm.insertUser = this.eventFull.insertUser;
      eventForm.mailText = this.mailText.value.split('\n').join('<br/>');
      eventForm.candidate = this.candidato._id;
      const recruitEvent = this.eventFull as IRecruitEvent;
      this.eventService.replaceEvent(recruitEvent?._id, eventForm).subscribe(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Appuntamento',
          detail: 'Evento aggiornato correttamente',
        });
        this.closeModal.emit();
      });
    }
  }
}
