import { IEvent } from '@renovars/common';
import { ICandidate } from '../candidates';

export interface IRecruitEvent extends IEvent {
  candidate: string | ICandidate;
  candidates?: ICandidate[];
  mailText?: string;
  mailVisible?: boolean;
  sendMail?: boolean;
  adminMessage?: {
    to: string; //storemanage id,
    msg: string;
  };
  excludeFromStat?: boolean;
  cache?: any;
}

export enum RecruitEventType {
  APPUNTAMENTO = 1,
  AFFIANCAMENTO = 2,
  PRIMO_HS = 3,
  SECONDO_HS = 4,
  COMPANY_PRIMO_COLLOQUIO = 5,
  COMPANY_SECONDO_COLLOQUIO = 6,
}
