<div class="m-2 row">
  <div class="col-12">
    <h1 class="tx-title-2">Turnover</h1>
  </div>
  <div class="col-4"></div>
  <div class="col-4">
    <div *permissions="['is_admin', 'is_area_manager', 'is_director']">
      <fullstack-input-select-box
        [ngModel]="selectedUserId"
        (ngModelChange)="selectedUserId = $event; search()"
        [options]="{ label: 'Utente fr' }"
      >
        <sb-option [key]="'code'" [value]="i" *ngFor="let i of frUsers$ | async">{{ i.value }}</sb-option>
      </fullstack-input-select-box>
    </div>
  </div>
  <div class="col-4">
    <fullstack-daterangepicker
      [options]="{ label: 'Range', min: minDate, max: maxDate }"
      [(ngModel)]="range"
      (rangeChange)="range = $event; search()"
    ></fullstack-daterangepicker>
  </div>
</div>
<div *ngIf="turnover$ | turnover | async as total">
  <div class="row my-2 mx-2">
    <div class="col-sm justify-content-center text-center text-small">
      <p class="font-weight-bold">
        <small>N° Archs Iniziale</small>
      </p>
    </div>
    <div class="col-sm justify-content-center text-center text-small">
      <p class="font-weight-bold">
        <small>N° Archs New</small>
      </p>
    </div>
    <div class="col-sm justify-content-center text-center text-small">
      <p class="font-weight-bold">
        <small>N° Archs Gestiti</small>
      </p>
    </div>
    <div class="col-sm justify-content-center text-center text-small">
      <p class="font-weight-bold">
        <small>N° Archs Operativi</small>
      </p>
    </div>
    <div class="col-sm justify-content-center text-center text-small">
      <p class="font-weight-bold">
        <small>N° Archs Usciti</small>
      </p>
    </div>
    <div class="col-sm justify-content-center text-center text-small">
      <p class="font-weight-bold">
        <small>Turnover %</small>
      </p>
    </div>
  </div>
  <div class="row mx-2">
    <div class="col-sm justify-content-center text-center text-small">
      <h1 class="display-6 text-center">
        <small>{{ total.startTeam || 0 }}</small>
      </h1>
    </div>
    <div class="col-sm justify-content-center text-center text-small">
      <h1 class="display-6 text-center">
        <small>{{ total.newTeam || 0 }}</small>
      </h1>
    </div>
    <div class="col-sm justify-content-center text-center text-small">
      <h1 class="display-6 text-center">
        <small>{{ total.startTeam + total.newTeam || 0 }}</small>
      </h1>
    </div>
    <div class="col-sm justify-content-center text-center text-small">
      <h1 class="display-6 text-center">
        <small>{{ total.endTeam || 0 }}</small>
      </h1>
    </div>
    <div class="col-sm justify-content-center text-center text-small">
      <h1 class="display-6 text-center">
        <small>{{ total.turnover || 0 }}</small>
      </h1>
    </div>
    <div class="col-sm justify-content-center text-center text-small">
      <h1 class="display-6 text-center">
        <small>{{
          ((total.startTeam + total.newTeam - total.endTeam) / (total.startTeam + total.newTeam)) * 100
            ? (
                ((total.startTeam + total.newTeam - total.endTeam) / (total.startTeam + total.newTeam)) *
                100
              ).toFixed(2)
            : 0
        }}</small>
      </h1>
    </div>
  </div>
</div>

<fullstack-datatable
  #dt1
  [list]="turnover$ | async"
  [paginator]="false"
  [stileClass]="'p-datatable-sm'"
  [paginator]="true"
  [globalFilterFields]="['direttore', 'areaManager', 'regionalManager', 'storeManager']"
>
  <div t-header>
    <div t-column>
      <ng-template t-col-title>Direttore</ng-template>
    </div>
    <div t-column>
      <ng-template t-col-title>Area Manager</ng-template>
    </div>
    <div t-column>
      <ng-template t-col-title>Regional Manager</ng-template>
    </div>
    <div t-column>
      <ng-template t-col-title>Store Manager</ng-template>
    </div>
    <div t-column field="startTeam">
      <ng-template t-col-title>N° Archs Iniziale</ng-template>
    </div>
    <div t-column field="newteam">
      <ng-template t-col-title>N° Archs New</ng-template>
    </div>
    <div t-column>
      <ng-template t-col-title>N° Archs Gestiti</ng-template>
    </div>
    <div t-column field="endTeam">
      <ng-template t-col-title>N° Archs Operativi</ng-template>
    </div>
    <div t-column field="turnover">
      <ng-template t-col-title>Turnover</ng-template>
    </div>
    <div t-column field="turnoverPerc">
      <ng-template t-col-title>Turnover %</ng-template>
    </div>
  </div>
  <ng-template t-body let-row>
    <td>{{ row?.direttore }}</td>
    <td>{{ row?.areaManager }}</td>
    <td>{{ row?.regionalManager }}</td>
    <td>{{ row?.storeManager }}</td>
    <td>{{ row?.startTeam }}</td>
    <td>{{ row?.newTeam }}</td>
    <td>{{ row?.startTeam + row?.newTeam }}</td>
    <td>{{ row?.endTeam }}</td>
    <td>{{ row?.turnover }}</td>
    <td>{{ row?.turnoverPerc }}</td>
  </ng-template>
</fullstack-datatable>

<div *ngIf="false" class="alert alert-primary" role="alert">Nessun turnover trovato</div>
