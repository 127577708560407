import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';
import {
  CandidateType,
  CandidateTypeExperience,
  CandidateTypeSector,
  CandidateWorkerRegion,
} from '@renovars/common/recruit';
import { setFieldsProperties } from '@renovars/core-ng';

@Component({
  selector: 'facile-candidati-profile-info-form',
  template: `
    <form [formGroup]="form" novalidate>
      <div class="row mt-3">
        <div class="col-12">
          <fullstack-datepicker
            [options]="{ label: 'Data di abilitazione' }"
            formControlName="workQualificationDate"
          ></fullstack-datepicker>
        </div>
        <div class="col-12">
          <fullstack-input-box
            [options]="{ label: 'Numero partita IVA' }"
            formControlName="vatNumber"
          ></fullstack-input-box>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-8">Interessato ad aprire partita IVA</div>
            <div class="col-2">
              <p-radioButton
                [value]="true"
                formControlName="vatNumberOpenInterest"
                label="Si"
              ></p-radioButton>
            </div>
            <div class="col-2">
              <p-radioButton
                [value]="false"
                formControlName="vatNumberOpenInterest"
                label="No"
              ></p-radioButton>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-8">Auto/Moto munito</div>
            <div class="col-2">
              <p-radioButton [value]="true" formControlName="ownCar" label="Si"></p-radioButton>
            </div>
            <div class="col-2">
              <p-radioButton [value]="false" formControlName="ownCar" label="No"></p-radioButton>
            </div>
          </div>
        </div>
        <!-- <div class="col-12">
          <div class="row">
            <div class="col-8">Moto munito</div>
            <div class="col-2"><p-radioButton [value]="true" formControlName="ownMoto" label="Si"></p-radioButton></div>
            <div class="col-2"><p-radioButton [value]="false" formControlName="ownMoto" label="No"></p-radioButton></div>
          </div>
        </div> -->
        <div class="col-12">
          <fullstack-input-select-box
            [options]="{ label: 'Esperienza nella direzione lavori' }"
            formControlName="workSupervisionExperience"
          >
            <sb-option [value]="i" *ngFor="let i of ['Nessuna esperienza', '1 - 3', '3 - 5', '> 5']">
              {{ i }}
            </sb-option>
          </fullstack-input-select-box>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-8">Disposto a lavorare in esclusiva</div>
            <div class="col-2">
              <p-radioButton
                [value]="true"
                formControlName="exclusiveCollaboration"
                label="Si"
              ></p-radioButton>
            </div>
            <div class="col-2">
              <p-radioButton
                [value]="false"
                formControlName="exclusiveCollaboration"
                label="No"
              ></p-radioButton>
            </div>
          </div>
        </div>

        <div class="col-12">
          <fullstack-input-select-box [options]="{ label: 'Stato Lavorativo' }" formControlName="workStatus">
            <sb-option [value]="i" *ngFor="let i of ['Dipendente', 'Disoccupato', 'libero professionista']">
              {{ i }}
            </sb-option>
          </fullstack-input-select-box>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-8">Esperienze nel settore commerciale</div>
            <div class="col-2">
              <p-radioButton [value]="true" formControlName="commercialExperience" label="Si"></p-radioButton>
            </div>
            <div class="col-2">
              <p-radioButton
                [value]="false"
                formControlName="commercialExperience"
                label="No"
              ></p-radioButton>
            </div>
          </div>
        </div>
        <div class="col-12" *ngIf="form.controls.commercialExperience.value === true">
          <fullstack-input-textarea
            [options]="{ label: 'Esperienze commerciali', rows: 5 }"
            formControlName="commercialExperienceDescription"
          ></fullstack-input-textarea>
        </div>

        <div class="col-12">
          <fullstack-input-select-box [options]="{ label: 'Albi d\\'iscrizione' }" formControlName="register">
            <sb-option
              [value]="i"
              *ngFor="
                let i of [
                  'Albo degli architetti',
                  'Albo degli ingegneri',
                  'Albo dei periti',
                  'Collegio dei geometri'
                ]
              "
            >
              {{ i }}
            </sb-option>
          </fullstack-input-select-box>
        </div>
        <div class="col-12" *ngIf="form.controls.register.value">
          <fullstack-input-box
            [options]="{ label: 'Numero d\\' iscrizione' }"
            formControlName="registerNum"
          ></fullstack-input-box>
        </div>
        <div class="col-12" *ngIf="form.controls.register.value">
          <fullstack-datepicker
            [options]="{ label: 'Data d\\' iscrizione' }"
            formControlName="registerDate"
          ></fullstack-datepicker>
        </div>
        <div class="col-4" *ngIf="candidateType === CandidateType.WORKER">
          <div class="row">
            <div class="col-8">Esperienza</div>
            <fullstack-input-select-box [options]="{ label: 'Esperienza' }" formControlName="experience">
              <sb-option [value]="i" *ngFor="let i of experienceLabels">
                {{ i }}
              </sb-option>
            </fullstack-input-select-box>
          </div>
        </div>
        <div class="col-4" *ngIf="candidateType === CandidateType.WORKER">
          <div class="row">
            <div class="col-8">Mansione</div>
            <fullstack-input-select-box [options]="{ label: 'Mansione' }" formControlName="mansion">
              <sb-option [value]="i" *ngFor="let i of mansionLabels">
                {{ i }}
              </sb-option>
            </fullstack-input-select-box>
          </div>
        </div>
        <div class="col-4" *ngIf="candidateType === CandidateType.WORKER">
          <div class="row">
            <div class="col-8">Regione</div>
            <fullstack-input-select-box [options]="{ label: 'Regione' }" formControlName="region">
              <sb-option [value]="i" *ngFor="let i of candidateWorkerRegion">
                {{ i }}
              </sb-option>
            </fullstack-input-select-box>
          </div>
        </div>

        <div class="col-3"></div>
        <div class="col-12">
          <fullstack-input-textarea
            [options]="{ label: 'Breve Descrizione', rows: 5 }"
            formControlName="profileNote"
          ></fullstack-input-textarea>
        </div>
      </div>
    </form>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CandidatiProfileInfoFormComponent),
      multi: true,
    },
  ],
  styles: ['.col-12 { margin: 10px 0 10px 0 }'],
})
export class CandidatiProfileInfoFormComponent implements ControlValueAccessor, OnInit {
  @Input() candidateType: CandidateType = CandidateType.ARCHITECT;
  CandidateType = CandidateType;
  mansionLabels = CandidateTypeSector[CandidateType.WORKER];
  experienceLabels = CandidateTypeExperience[CandidateType.WORKER];
  candidateWorkerRegion = CandidateWorkerRegion;
  form = this.fb.group({
    married: new UntypedFormControl(null),
    childs: new UntypedFormControl(null),
    cohabitant: new UntypedFormControl(null),
    workQualificationDate: new UntypedFormControl(null),
    vatNumber: new UntypedFormControl(null),
    vatNumberOpenInterest: new UntypedFormControl(null),
    ownCar: new UntypedFormControl(null),
    // ownMoto: new FormControl(null), removed 09/02/2022
    workSupervisionExperience: new UntypedFormControl(null),
    workStatus: new UntypedFormControl(null),
    // otherWorkCollaborationsDescription: new FormControl(null),
    exclusiveCollaboration: new UntypedFormControl(null),
    commercialExperience: new UntypedFormControl(null),
    commercialExperienceDescription: new UntypedFormControl(null),
    register: new UntypedFormControl(null),
    registerNum: new UntypedFormControl(null),
    registerDate: new UntypedFormControl(null),
    profileNote: new UntypedFormControl(null),
    experience: new UntypedFormControl(null),
    mansion: new UntypedFormControl(null),
    region: new UntypedFormControl(null),
  });

  @Input() set formInfoState(formInfoState) {
    this._formInfoState = formInfoState;
    setFieldsProperties(this._formInfoState, this.form);
  }

  get formInfoState() {
    return this._formInfoState;
  }

  _formInfoState: ValidationErrors = [];

  propagateChange: any = () => {};

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.form.valueChanges.subscribe((val) => {
      this.propagateChange(val);
    });
  }

  resetFormValue() {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).setValue(null);
    });
  }

  /* Metodi per ReactiveForm */
  writeValue(value: any) {
    if (value) {
      this.form.patchValue(value);
    } else {
      this.resetFormValue();
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  setDisabledState(isDisabled: boolean) {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).disable();
    });
  }

  registerOnTouched(fn: () => void): void {}
}
