import { Pipe, PipeTransform } from '@angular/core';
import { TurnoverWithCalculations } from '@renovars/common/recruit';
import { concatAll, Observable, reduce } from 'rxjs';

@Pipe({
  name: 'turnover',
})
export class TurnoverPipe implements PipeTransform {
  transform(values: Observable<TurnoverWithCalculations[]>): unknown {
    return values.pipe(
      concatAll(),
      reduce(
        (a, b) => ({
          ...a,
          startTeam: (a?.startTeam || 0) + (b?.startTeam || 0),
          newTeam: (a?.newTeam || 0) + (b?.newTeam || 0),
          endTeam: (a?.endTeam || 0) + (b?.endTeam || 0),
          turnover: (a?.turnover || 0) + (b?.turnover || 0),
        }),
        {} as TurnoverWithCalculations
      )
    );
  }
}
