/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, Input, OnInit } from '@angular/core';
import { ICandidate } from '@renovars/common/recruit';

@Component({
  selector: 'facile-candidati-recap',
  templateUrl: './candidati-recap.component.html',
  styles: [],
})
export class CandidatiRecapComponent implements OnInit {
  @Input() candidate: ICandidate;
  constructor() {}

  ngOnInit(): void {}
}
