<facile-candidati-recap [candidate]="candidato"></facile-candidati-recap>
<div>
  <div class="row">
    <div class="col-12">
      <header class="page-header">
        <h2 class="tx-title-2">Dati Addizionali</h2>
      </header>
    </div>
    <div class="col mt-1" *ngIf="type === 'active_detail' && candidato?.crmFrData?.crmFrId">
      <fullstack-input-check-box
        [ngModel]="stateOnFr.isAvailable"
        (ngModelChange)="toogleState($event)"
        [options]="{ label: 'Disponibile' }"
      ></fullstack-input-check-box>
    </div>
    <div class="col mt-1" *ngIf="type === 'active_detail' && candidato?.crmFrData?.crmFrId">
      <fullstack-input-check-box
        [ngModel]="stateOnFr.busy"
        [options]="{ label: 'Occupato' }"
        [disabled]="disabled"
      ></fullstack-input-check-box>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col" *ngIf="type === 'active_detail'">
      <fullstack-input-box
        [options]="{ label: 'Username *' }"
        [(ngModel)]="username"
        [disabled]="disabled"
      ></fullstack-input-box>
    </div>
    <div class="col" *ngIf="type === 'active_detail'">
      <fullstack-input-box
        [options]="{ label: 'Email Aziendale *' }"
        [(ngModel)]="workingEmail"
        [disabled]="disabled"
      ></fullstack-input-box>
    </div>
    <div class="col" *ngIf="type === 'active_detail'">
      <fullstack-input-box
        [options]="{ label: 'Raggio *' }"
        [(ngModel)]="radius"
        [disabled]="disabled"
      ></fullstack-input-box>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col">
      <fullstack-input-select-box
        [options]="{ label: 'Tipologia *' }"
        [(ngModel)]="architectType"
        [disabled]="disabled"
      >
        <sb-option [value]="'Architetto'">Architetto</sb-option>
        <sb-option [value]="'Geometra'">Geometra</sb-option>
        <sb-option [value]="'Ingegnere'">Ingegnere</sb-option>
      </fullstack-input-select-box>
    </div>
    <div class="col">
      <fullstack-input-address-box
        [options]="{ label: 'Indirizzo Operativo su FR *' }"
        [(ngModel)]="frAddress"
      ></fullstack-input-address-box>
    </div>
    <div class="col">
      <fullstack-input-select-box
        [ngModel]="shop"
        (ngModelChange)="shop = $event"
        [options]="{ label: 'Shop *' }"
        [disabled]="disabled"
      >
        <sb-option [key]="'code'" [value]="i" *ngFor="let i of shops | async">{{ i.value }}</sb-option>
      </fullstack-input-select-box>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col">
      <fullstack-input-select-box
        [ngModel]="parent"
        (ngModelChange)="parent = $event; withParent()"
        [options]="{ label: 'Superiore *' }"
        [disabled]="disabled"
      >
        <sb-option [key]="'code'" [value]="i" *ngFor="let i of frStoreManagers | async">{{
          i.value
        }}</sb-option>
      </fullstack-input-select-box>
    </div>
    <div class="col">
      <fullstack-input-box
        [options]="{ label: 'AM' }"
        [(ngModel)]="areaManager"
        [disabled]="true"
      ></fullstack-input-box>
    </div>
    <div class="col">
      <fullstack-input-box
        [options]="{ label: 'DC' }"
        [(ngModel)]="dc"
        [disabled]="true"
      ></fullstack-input-box>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-12">
      <fullstack-input-textarea
        [options]="{ label: 'Note', rows: 4 }"
        [(ngModel)]="note"
        [disabled]="disabled"
      ></fullstack-input-textarea>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-md-2 offset-md-10">
      <button
        mat-button
        (click)="salva()"
        class="btn btn-secondary"
        [disabled]="candidato?.crmFrData?.crmFrId"
      >
        Salva
      </button>
    </div>
  </div>
</div>
