import { Pipe, PipeTransform } from '@angular/core';
import { IUser } from '@renovars/common';

@Pipe({ name: 'idToName' })
export class IdToNamePipe implements PipeTransform {
  transform(value: string, users: IUser[]) {
    return users && users.length > 0
      ? users
          .filter((u) => u.id === value)
          .map((u) => {
            const first = u.firstName ? u.firstName : null;
            const last = u.lastName ? u.lastName : null;
            return first && last ? `${first} ${last}` : '-';
          })
      : '-';
  }
}
