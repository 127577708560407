import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllStatusCodes, ICandidate, IRecruitEvent } from '@renovars/common/recruit';
import { ModalEditStatoComponent } from './modal-edit-stato.component';

@Component({
  selector: 'facile-modal-appointment-stato',
  templateUrl: './modal-appointment-stato.component.html',
})
export class ModalAppointmentStatoComponent implements OnInit {
  statusCode: string;
  candidato: ICandidate;
  statuses = AllStatusCodes;

  constructor(
    public dialogRef: MatDialogRef<ModalEditStatoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { statusCode: string; eventFull: IRecruitEvent }
  ) {}
  ngOnInit(): void {
    this.statusCode = this.data.statusCode;
    if (typeof this.data.eventFull.candidate !== 'string') this.candidato = this.data.eventFull.candidate;
  }

  close(event) {
    this.dialogRef.close(event);
  }
}
