import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import {
  AdminArchitectCodes,
  CandidateType,
  RecruitRoles,
  getStatusKOCodes,
  getStatusOKCodes,
  isStoreManager,
} from '@renovars/common/recruit';

@Component({
  selector: 'facile-status-header',
  template: `
    <div class="row">
      <div class="col"></div>
      <div class="col-3 my-2">
        <fullstack-input-select-box
          [options]="{ label: 'Stati OK' }"
          [(ngModel)]="status"
          (ngModelChange)="onSelect.emit($event)"
        >
          <sb-option [key]="'code'" [value]="i" *ngFor="let i of statusListOK">{{ i.label }}</sb-option>
        </fullstack-input-select-box>
      </div>
      <div class="col-3 my-2">
        <fullstack-input-select-box
          [options]="{ label: 'Stati KO' }"
          [(ngModel)]="status"
          (ngModelChange)="onSelect.emit($event)"
        >
          <sb-option [key]="'code'" [value]="i" *ngFor="let i of statusListKO">{{ i.label }}</sb-option>
        </fullstack-input-select-box>
      </div>
    </div>
  `,
  styles: [],
})
export class StatusHeaderComponent implements OnInit, OnChanges {
  @Input() candidateType: CandidateType;
  @Input() roles: RecruitRoles[];
  status: string;
  statusListOK: { code: string; label: string }[] = [];
  statusListKO: { code: string; label: string }[] = [];
  @Output() onSelect = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(): void {
    this.filterStatusByType(this.roles, this.candidateType);
  }
  private filterStatusByType(roles: RecruitRoles[], candidateType: CandidateType) {
    this.statusListOK = getStatusOKCodes(RecruitRoles.ADMIN, candidateType);
    this.statusListKO = getStatusKOCodes(RecruitRoles.ADMIN, candidateType);
    if (!roles || roles.length === 0) return;
    if (isStoreManager(roles)) {
      this.statusListOK = getStatusOKCodes(RecruitRoles.STORE_MANAGER, candidateType);
      this.statusListKO = getStatusKOCodes(RecruitRoles.STORE_MANAGER, candidateType);
    }
    if (roles.includes(RecruitRoles.HR_SPECIALIST)) {
      this.statusListOK = getStatusOKCodes(RecruitRoles.HR_SPECIALIST, candidateType);
      this.statusListKO = getStatusKOCodes(RecruitRoles.HR_SPECIALIST, candidateType);
    }
    if (roles.includes(RecruitRoles.HR_OFFICE)) {
      this.statusListOK = getStatusOKCodes(RecruitRoles.HR_OFFICE, candidateType);
      this.statusListKO = getStatusKOCodes(RecruitRoles.HR_OFFICE, candidateType);
    }
  }
}
