import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { AuthServices } from '@renovars/core-ng';
import { Observable, of } from 'rxjs';

@Injectable()
export class AppResolver implements Resolve<boolean> {
  constructor(private router: Router, private authServices: AuthServices) {}

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    return of(true);
  }
}
