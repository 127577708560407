import { Injectable } from '@angular/core';
import { CandidateType } from '@renovars/common/recruit';
import { IFilter } from '@renovars/mongoose-nest';

@Injectable()
export class CandidatiFilterService {
  private storageKey = 'CandidateFilter';
  setFilter(candidateFilter: IFilter, type: CandidateType) {
    const key = this.storageKey + type;
    sessionStorage.setItem(key, JSON.stringify(candidateFilter));
  }
  getFilter(type: CandidateType): IFilter {
    const key = this.storageKey + type;
    return JSON.parse(sessionStorage.getItem(key));
  }
}
